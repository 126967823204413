import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/chevron-right.svg'
import _imports_1 from '@/assets/calendar-icon.svg'
import _imports_2 from '@/assets/gray-user.svg'
import _imports_3 from '@/assets/pencil-gray.svg'
import _imports_4 from '@/assets/salesforce.svg'
import _imports_5 from '@/assets/filter-termin-icon.svg'
import _imports_6 from '@/assets/image-icon.svg'
import _imports_7 from '@/assets/navigation-map-icon.svg'


const _hoisted_1 = { class: "w-full h-[calc(100vh-64px)] flex flex-col md:flex-row items-start gap-3 overflow-y-auto" }
const _hoisted_2 = { class: "flex flex-col w-full sticky top-0 z-20 bg-[#efeeec]" }
const _hoisted_3 = { class: "flex flex-col md:flex-row items-start md:items-center justify-between mb-3" }
const _hoisted_4 = { class: "flex items-center gap-[2px]" }
const _hoisted_5 = { class: "relative group" }
const _hoisted_6 = { class: "text-xl leading-[26px] tracking-[0.01rem] text-[#616068] font-medium" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "flex items-center justify-between" }
const _hoisted_10 = { class: "w-4/5 text-lg leading-[20px] tracking-[0.01rem] text-primary-text-color font-semibold" }
const _hoisted_11 = { class: "flex w-1/5 items-center gap-3 text-right justify-end" }
const _hoisted_12 = { class: "flex items-center gap-4" }
const _hoisted_13 = { class: "flex items-center gap-1" }
const _hoisted_14 = { class: "text-sm text-[#898790] foont-medium" }
const _hoisted_15 = { class: "flex items-center gap-1" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "flex gap-1 items-center" }
const _hoisted_18 = { class: "text-sm text-[#898790]" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "flex items-center justify-between" }
const _hoisted_21 = { class: "w-4/5 text-lg leading-[20px] tracking-[0.01rem] text-primary-text-color font-semibold" }
const _hoisted_22 = { class: "flex items-center gap-3 w-1/5 justify-end text-right" }
const _hoisted_23 = { class: "flex items-center gap-4" }
const _hoisted_24 = { class: "flex items-center gap-1" }
const _hoisted_25 = { class: "text-sm text-[#898790] foont-medium" }
const _hoisted_26 = { class: "flex items-center gap-1" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { class: "flex flex-col gap-3" }
const _hoisted_29 = {
  key: 0,
  class: "flex flex-col gap-3"
}
const _hoisted_30 = {
  key: 1,
  class: "col-span-2"
}
const _hoisted_31 = {
  key: 2,
  class: "col-span-2 p-3 bg-[#DFDEDC] rounded-primary-radius shadow-primary-box-shadow flex flex-col gap-2"
}
const _hoisted_32 = { class: "w-full flex items-center justify-between" }
const _hoisted_33 = { class: "flex items-center gap-3" }
const _hoisted_34 = { class: "flex items-center gap-1" }
const _hoisted_35 = { class: "text-sm text-[#898790] foont-medium" }
const _hoisted_36 = { class: "flex items-center gap-1" }
const _hoisted_37 = { key: 0 }
const _hoisted_38 = ["onClick"]
const _hoisted_39 = { class: "mt-4" }
const _hoisted_40 = { class: "text-base font-normal text-" }
const _hoisted_41 = { class: "p-3 rounded-primary-radius bg-[#e5ecf6] mt-2" }
const _hoisted_42 = { class: "text-base font-normal text-" }
const _hoisted_43 = {
  key: 3,
  class: "flex gap-1 bg-[#DFDEDC] rounded-primary-radius shadow-primary-box-shadow p-3"
}
const _hoisted_44 = { class: "w-full flex items-center justify-between" }
const _hoisted_45 = { class: "flex items-center gap-3" }
const _hoisted_46 = { class: "flex items-center gap-1" }
const _hoisted_47 = { class: "text-sm text-[#898790] foont-medium" }
const _hoisted_48 = { class: "flex items-center gap-1" }
const _hoisted_49 = { key: 0 }
const _hoisted_50 = ["onClick"]
const _hoisted_51 = { class: "mt-4 flex gap-2" }
const _hoisted_52 = { class: "text-base font-normal grow" }
const _hoisted_53 = { class: "px-3 py-[1px] rounded-primary-radius bg-[#e5ecf6] mt-2 h-fit w-fit" }
const _hoisted_54 = { class: "text-base font-normal h-fit w-fit" }
const _hoisted_55 = {
  key: 4,
  class: "col-span-2 p-3 bg-[#DFDEDC] rounded-primary-radius shadow-primary-box-shadow flex flex-col gap-2"
}
const _hoisted_56 = { class: "w-full flex items-center justify-between" }
const _hoisted_57 = { class: "flex items-center gap-3" }
const _hoisted_58 = { class: "flex items-center gap-1" }
const _hoisted_59 = { class: "text-sm text-[#898790] foont-medium" }
const _hoisted_60 = { class: "flex items-center gap-1" }
const _hoisted_61 = { key: 0 }
const _hoisted_62 = ["onClick"]
const _hoisted_63 = { class: "mt-4" }
const _hoisted_64 = { class: "text-base font-normal text-" }
const _hoisted_65 = { class: "p-3 rounded-primary-radius bg-[#e5ecf6] mt-2" }
const _hoisted_66 = { class: "text-base font-normal text-" }
const _hoisted_67 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_68 = ["href"]
const _hoisted_69 = {
  key: 0,
  class: "w-full h-screen fixed top-0 right-0 bottom-0 left-0 z-[777] bg-black/25"
}
const _hoisted_70 = {
  key: 1,
  class: "h-[calc(100vh-64px)] overflow-y-auto"
}

import CustomAccordion from "@/components/Re-usable/Phase/CustomAccordion.vue";
import { ref, onMounted } from "vue";
import TechnicalDetails from "@/components/Project/TechnicalDetails.vue";
import TerminFilter from "@/components/Re-usable/TerminFilter.vue";
import ProjectMap from "@/components/Project/ProjectMap.vue";
import QCModal from "@/components/PhaseDetails/QCModal.vue";
import AddImageModal from "@/components/Re-usable/AddImageModal.vue";
import { useRoute } from "vue-router";
import IssueModal from "@/components/Project/Appointment/Modal/IssueModal.vue";
import NoteModal from "@/components/Project/Appointment/Modal/NoteModal.vue";
import InputModal from "@/components/Re-usable/InputModal.vue";
import GroupInput from "@/components/Re-usable/Phase/GroupInput.vue";
import SlideImagesBlock from "@/components/Re-usable/Phase/SlideImagesBlock.vue";
import { watch } from "vue";
import { errorToast, successToast } from "@/utils/custom-toast";
import client from "@/utils/axios";

type Props = {
  phaseDetails: any;
  phaseId: number;
  projectId: string;
};

type Emits = {
  (event: "refreshData"): void;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'QcView',
  props: {
    phaseDetails: {},
    phaseId: {},
    projectId: {}
  },
  emits: ["refreshData"],
  setup(__props: any, { emit: __emit }) {

const intersectionOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 0.1,
};

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    // TODO: ability to mark on which step we are on
    if (entry.isIntersecting) {
      // extract ID from the entry.target.id
      // const id = `scroll-${entry.target.id}`;
      // // Update the URL with the ID
      // history.replaceState(null, "", `#${id}`);
      // router.replace({ hash: `#${id}` });
    }
  });
}, intersectionOptions);

onMounted(() => {
  const elements = document.querySelectorAll(".observe");
  elements.forEach((element) => {
    observer.observe(element);
  });
});

const props = __props;

const emits = __emit;
const route = useRoute();

const isModal = ref(false);
const isTerminFilterOpen = ref(false);
const isMap = ref(false);
const selectedIssue = ref(null);
const selectedNote = ref(null);
const isIssueModalOpen = ref(false);
const isNoteModalOpen = ref(false);
const isInputModalOpen = ref(false);
const isAddImageModalOpen = ref(false);
const isLoading = ref(false);

const selectedInput = ref<any>(null);
const selectedStep = ref<any>(null);

const toggleProjectMap = () => {
  isMap.value = !isMap.value;
};

const selectedTermins = ref(
  props.phaseDetails.termins.map((item: any) => item.id)
);

const onFilterSubmit = (termins: Array<string>) => {
  selectedTermins.value = [...termins];
  isTerminFilterOpen.value = false;
};

const toggleModalHandler: any = (input: any) => {
  selectedInput.value = input;
  isInputModalOpen.value = !isInputModalOpen.value;
};
let scrollableContainer = ref<any>(null);
watch(
  () => route.hash, // Watch for the 'id' param change in the route
  () => {
    if (route.hash) {
      let hashId = route.hash.replace("#", "");
      const targetElement = document.getElementById(hashId);
      if (targetElement) {
        const topPosition = targetElement.offsetTop - 200;

        // Use scrollTo to scroll to the adjusted position
        scrollableContainer.value.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
      }
    }
  },
  { immediate: true } // Immediately run on component mount
);

const generateReport = async () => {
  isLoading.value = true;
  successToast("Your report is being generated, please wait.");
  const projectId = route.params.id;
  const phaseId = route.params.phaseId;
  try {
    const resp = await client.post(
      `/projects/${projectId}/phase/${phaseId}/generate-report`
    );

    if (resp && resp.status === 200) {
      successToast("Report successfully generated.");
    } else {
      errorToast("Something went wrong!");
    }
    isLoading.value = false;
  } catch (error) {
    errorToast("Something went wrong!");
  } finally {
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (selectedIssue.value && isIssueModalOpen.value)
      ? (_openBlock(), _createBlock(IssueModal, {
          key: 0,
          issue: selectedIssue.value,
          options: props.phaseDetails.issueTypes,
          "is-open": isIssueModalOpen.value,
          "just-view": true,
          "header-title": "Issue",
          onToggleModal: _cache[0] || (_cache[0] = ($event: any) => {
      isIssueModalOpen.value = false;
      selectedIssue.value = null;
    })
        }, null, 8, ["issue", "options", "is-open"]))
      : _createCommentVNode("", true),
    (selectedNote.value && isNoteModalOpen.value)
      ? (_openBlock(), _createBlock(NoteModal, {
          key: 1,
          note: selectedNote.value,
          "is-open": isNoteModalOpen.value,
          "just-view": true,
          "header-title": "Note",
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => {
      isNoteModalOpen.value = false;
      selectedNote.value = null;
    })
        }, null, 8, ["note", "is-open"]))
      : _createCommentVNode("", true),
    (isInputModalOpen.value && selectedInput.value)
      ? (_openBlock(), _createBlock(InputModal, {
          key: 2,
          input: selectedInput.value,
          "is-open": isInputModalOpen.value,
          project: _unref(route).params.id,
          termins: props.phaseDetails.termins,
          phase: _unref(route).params.phaseId ? Number(_unref(route).params.phaseId) : -1,
          onRefresh: _cache[2] || (_cache[2] = ($event: any) => (emits('refreshData'))),
          onToggleModal: _cache[3] || (_cache[3] = 
      () => {
        isInputModalOpen.value = false;
        selectedInput.value = null;
      }
    )
        }, null, 8, ["input", "is-open", "project", "termins", "phase"]))
      : _createCommentVNode("", true),
    (isAddImageModalOpen.value && selectedInput.value)
      ? (_openBlock(), _createBlock(AddImageModal, {
          key: 3,
          steps: _ctx.phaseDetails.steps,
          termins: _ctx.phaseDetails.termins,
          "is-open": isAddImageModalOpen.value,
          project: _unref(route).params.id,
          input: selectedInput.value,
          step: selectedStep.value,
          "header-title": selectedInput.value ? selectedInput.value.name : '',
          phase: _unref(route).params.phaseId ? Number(_unref(route).params.phaseId) : -1,
          onToggleModal: _cache[4] || (_cache[4] = ($event: any) => {
      isAddImageModalOpen.value = false;
      selectedInput.value = null;
      selectedStep.value = null;
    }),
          onRefresh: _cache[5] || (_cache[5] = ($event: any) => (emits('refreshData')))
        }, null, 8, ["steps", "termins", "is-open", "project", "input", "step", "header-title", "phase"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        ref_key: "scrollableContainer",
        ref: scrollableContainer,
        class: _normalizeClass(["h-full w-full mx-auto overflow-y-auto max-w-[930px]", `${isMap.value ? 'w-3/5' : 'w-full'}`])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_RouterLink, {
                  to: `/projects/${_ctx.projectId}`,
                  class: "relative text-primary-text-color text-xl leading-[26px] tracking-[0.01rem] font-medium after:absolute after:bottom-[2px] after:left-0 after:w-full after:h-[1px] after:bg-primary-text-color after:opacity-0 group-hover:after:opacity-100 after:transition-opacity after:duration-200"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.phaseDetails.project.name), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _cache[12] || (_cache[12] = _createElementVNode("img", {
                src: _imports_0,
                alt: "Caret right"
              }, null, -1)),
              _createElementVNode("p", _hoisted_6, _toDisplayString(props.phaseDetails?.phases.find(
                  (phase) => phase.phaseId === props.phaseId
                )?.phase), 1)
            ]),
            _createElementVNode("button", {
              disabled: isLoading.value,
              onClick: generateReport,
              class: "flex items-center gap-2 text-sm text-white font-semibold leading-[17px] tracking-[0.01rem] py-[13.5px] px-2 bg-[#393840] rounded-primary-radius outline-none hover:bg-[#202022] duration-300"
            }, [
              (_openBlock(), _createElementBlock("svg", {
                width: "16",
                height: "16",
                viewBox: "0 0 14 16",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
                class: _normalizeClass(`${isLoading.value ? 'animate-spin' : ''}`)
              }, _cache[13] || (_cache[13] = [
                _createElementVNode("path", {
                  d: "M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179",
                  stroke: "white",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                }, null, -1)
              ]), 2)),
              _cache[14] || (_cache[14] = _createTextVNode(" Generate report "))
            ], 8, _hoisted_7)
          ]),
          _createElementVNode("div", null, [
            _createVNode(TechnicalDetails, {
              project: _ctx.phaseDetails.project
            }, null, 8, ["project"])
          ])
        ]),
        _createElementVNode("div", null, [
          _createVNode(CustomAccordion, {
            "step-name": `Issues (${_ctx.phaseDetails.issues.filter((item)=> selectedTermins.value.includes(item.terminId)).length})`
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phaseDetails.issues.filter((item)=> selectedTermins.value.includes(item.terminId)), (issue) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: issue.id,
                  class: "mt-3 flex flex-col gap-3 p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow cursor-pointer",
                  onClick: ($event: any) => {
              selectedIssue.value = issue;
              isIssueModalOpen.value = true;
            }
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("h3", _hoisted_10, _toDisplayString(issue.title), 1),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("p", null, _toDisplayString(issue.terminName), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _cache[15] || (_cache[15] = _createElementVNode("img", {
                        class: "w-3 h-3 cursor-pointer",
                        src: _imports_1,
                        alt: "Calendar"
                      }, null, -1)),
                      _createElementVNode("p", _hoisted_14, _toDisplayString(issue.createdAt
                      ? new Date(issue.createdAt).toLocaleDateString("DE")
                      : ""), 1)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _cache[16] || (_cache[16] = _createElementVNode("img", {
                        class: "w-3 h-3",
                        src: _imports_2,
                        alt: "User"
                      }, null, -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(issue.workers, (worker, index) => {
                        return (_openBlock(), _createElementBlock("p", {
                          key: index,
                          class: "text-sm text-[#898790] foont-medium"
                        }, [
                          _createTextVNode(_toDisplayString(worker), 1),
                          (index < issue.workers.length - 1)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_16, ","))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _cache[17] || (_cache[17] = _createElementVNode("svg", {
                        width: "12",
                        height: "13",
                        viewBox: "0 0 12 13",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          d: "M1.64551 6.1877L5.66718 2.16603C6.86316 0.970048 8.80226 0.970048 9.99824 2.16603C11.1942 3.36201 11.1941 5.30118 9.99809 6.49716L5.35771 11.1376C4.56039 11.9349 3.26789 11.9348 2.47057 11.1374C1.67325 10.3401 1.67306 9.04754 2.47038 8.25022L7.11077 3.60984C7.50943 3.21117 8.15613 3.21117 8.55479 3.60984C8.95345 4.0085 8.95317 4.6547 8.55451 5.05336L4.53284 9.07503",
                          stroke: "#A7A5AE",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        })
                      ], -1)),
                      _createElementVNode("span", _hoisted_18, _toDisplayString(issue.attachments?.length), 1)
                    ])
                  ])
                ], 8, _hoisted_8))
              }), 128))
            ]),
            _: 1
          }, 8, ["step-name"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(CustomAccordion, {
            "step-name": `Notes (${_ctx.phaseDetails.notes.filter((item)=> selectedTermins.value.includes(item.terminId)).length})`
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phaseDetails.notes.filter((item)=> selectedTermins.value.includes(item.terminId)), (note) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: note.id,
                  class: "mt-3 flex flex-col gap-3 p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow cursor-pointer",
                  onClick: ($event: any) => {
              selectedNote.value = note;
              isNoteModalOpen.value = true;
            }
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("h3", _hoisted_21, _toDisplayString(note.title), 1),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("p", null, _toDisplayString(note.terminName), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _cache[18] || (_cache[18] = _createElementVNode("img", {
                        class: "w-3 h-3 cursor-pointer",
                        src: _imports_1,
                        alt: "Calendar"
                      }, null, -1)),
                      _createElementVNode("p", _hoisted_25, _toDisplayString(note.createdAt
                      ? new Date(note.createdAt).toLocaleDateString("DE")
                      : ""), 1)
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _cache[19] || (_cache[19] = _createElementVNode("img", {
                        class: "w-3 h-3",
                        src: _imports_2,
                        alt: "User"
                      }, null, -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(note.workers, (worker, index) => {
                        return (_openBlock(), _createElementBlock("p", {
                          key: index,
                          class: "text-sm text-[#898790] foont-medium"
                        }, [
                          _createTextVNode(_toDisplayString(worker), 1),
                          (index < note.workers.length - 1)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_27, ","))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ])
                  ])
                ], 8, _hoisted_19))
              }), 128))
            ]),
            _: 1
          }, 8, ["step-name"])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phaseDetails.steps, (step) => {
          return (_openBlock(), _createElementBlock("div", {
            key: step.id,
            class: "mt-[36px]"
          }, [
            _createVNode(CustomAccordion, {
              "step-id": step.id,
              "step-name": step?.name,
              class: "observe"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_28, [
                    (step.inputs.some((input) => input.type === 'group' && input.finishedInputs > 0))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.inputs.filter((input) => input.type === 'group' && input.finishedInputs > 0), (input) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: input.id,
                              class: "p-3 bg-[#DFDEDC] rounded-primary-radius flex flex-col gap-2"
                            }, [
                              _createVNode(GroupInput, {
                                selectedTermins: selectedTermins.value,
                                step: step,
                                input: input,
                                "child-inputs": step.inputs.filter((item)=> item.parentId === input.id),
                                project: _unref(route).params.id,
                                phaseDetails: _ctx.phaseDetails,
                                phase: 
                      _unref(route).params.phaseId ? Number(_unref(route).params.phaseId) : -1
                    ,
                                onRefresh: _cache[6] || (_cache[6] = ($event: any) => (emits('refreshData')))
                              }, null, 8, ["selectedTermins", "step", "input", "child-inputs", "project", "phaseDetails", "phase"])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (step.inputs.some((input) => input.type === 'image' && input.parentId === null && input.value?.length > 0))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.inputs.filter((input) => input.type === 'image' && input.parentId === null && input.value?.length > 0), (input) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: input.id,
                              class: "w-full p-3 bg-[#DFDEDC] rounded-primary-radius mb-3"
                            }, [
                              _createVNode(SlideImagesBlock, {
                                selectedTermins: selectedTermins.value,
                                onAddImageModalOpen: 
                      () => {
                        selectedInput.value = input;
                        selectedStep.value = step;
                        isAddImageModalOpen.value = true;
                      }
                    ,
                                input: input,
                                step: step
                              }, null, 8, ["selectedTermins", "onAddImageModalOpen", "input", "step"])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (step.inputs.some((input) => input.type === 'text' && input.value && !input.parentId && selectedTermins.value?.includes(input.terminId)))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.inputs.filter((input) => input.type === 'text' && input.value && !input.parentId), (input) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: input.id,
                              class: "p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
                            }, [
                              _createElementVNode("div", _hoisted_32, [
                                _createElementVNode("div", _hoisted_33, [
                                  _createElementVNode("div", _hoisted_34, [
                                    _cache[20] || (_cache[20] = _createElementVNode("img", {
                                      class: "w-3 h-3 cursor-pointer",
                                      src: _imports_1,
                                      alt: "Calendar"
                                    }, null, -1)),
                                    _createElementVNode("p", _hoisted_35, _toDisplayString(input.createdAt
                              ? new Date(input.createdAt).toLocaleDateString(
                                  "de"
                                )
                              : ""), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_36, [
                                    _cache[21] || (_cache[21] = _createElementVNode("img", {
                                      class: "w-3 h-3",
                                      src: _imports_2,
                                      alt: "User"
                                    }, null, -1)),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(input.workers, (worker, index) => {
                                      return (_openBlock(), _createElementBlock("p", {
                                        key: index,
                                        class: "text-sm text-[#898790] foont-medium"
                                      }, [
                                        _createTextVNode(_toDisplayString(worker), 1),
                                        (index < input.workers.length - 1)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_37, ","))
                                          : _createCommentVNode("", true)
                                      ]))
                                    }), 128))
                                  ])
                                ]),
                                _createElementVNode("img", {
                                  onClick: ($event: any) => (toggleModalHandler(input)),
                                  class: "w-4 h-4 cursor-pointer",
                                  src: _imports_3,
                                  alt: "Pencil"
                                }, null, 8, _hoisted_38)
                              ]),
                              _createElementVNode("div", _hoisted_39, [
                                _createElementVNode("p", _hoisted_40, _toDisplayString(input.name), 1),
                                _createElementVNode("div", _hoisted_41, [
                                  _createElementVNode("p", _hoisted_42, _toDisplayString(input.value), 1)
                                ])
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (step.inputs.some((input) => input.type === 'number' && input.value && !input.parentId && selectedTermins.value?.includes(input.terminId)))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.inputs.filter((input) => input.type === 'number' && input.value && !input.parentId), (input) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: input.id,
                              class: "p-3 w-1/3 bg-white rounded-primary-radius shadow-primary-box-shadow"
                            }, [
                              _createElementVNode("div", _hoisted_44, [
                                _createElementVNode("div", _hoisted_45, [
                                  _createElementVNode("div", _hoisted_46, [
                                    _cache[22] || (_cache[22] = _createElementVNode("img", {
                                      class: "w-3 h-3 cursor-pointer",
                                      src: _imports_1,
                                      alt: "Calendar"
                                    }, null, -1)),
                                    _createElementVNode("p", _hoisted_47, _toDisplayString(input.createdAt
                              ? new Date(input.createdAt).toLocaleDateString(
                                  "de"
                                )
                              : ""), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_48, [
                                    _cache[23] || (_cache[23] = _createElementVNode("img", {
                                      class: "w-3 h-3",
                                      src: _imports_2,
                                      alt: "User"
                                    }, null, -1)),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(input.workers, (worker, index) => {
                                      return (_openBlock(), _createElementBlock("p", {
                                        key: index,
                                        class: "text-sm text-[#898790] foont-medium"
                                      }, [
                                        _createTextVNode(_toDisplayString(worker), 1),
                                        (index < input.workers.length - 1)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_49, ","))
                                          : _createCommentVNode("", true)
                                      ]))
                                    }), 128))
                                  ])
                                ]),
                                _createElementVNode("img", {
                                  onClick: ($event: any) => (toggleModalHandler(input)),
                                  class: "w-4 h-4 cursor-pointer",
                                  src: _imports_3,
                                  alt: "Pencil"
                                }, null, 8, _hoisted_50)
                              ]),
                              _createElementVNode("div", _hoisted_51, [
                                _createElementVNode("p", _hoisted_52, _toDisplayString(input.name), 1),
                                _createElementVNode("div", _hoisted_53, [
                                  _createElementVNode("p", _hoisted_54, _toDisplayString(input.value), 1)
                                ])
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (step.inputs.some((input) => input.type === 'select' && input.value && !input.parentId && selectedTermins.value?.includes(input.terminId)))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.inputs.filter((input) => input.type === 'select' && input.value && !input.parentId), (input) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: input.id,
                              class: "p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
                            }, [
                              _createElementVNode("div", _hoisted_56, [
                                _createElementVNode("div", _hoisted_57, [
                                  _createElementVNode("div", _hoisted_58, [
                                    _cache[24] || (_cache[24] = _createElementVNode("img", {
                                      class: "w-3 h-3 cursor-pointer",
                                      src: _imports_1,
                                      alt: "Calendar"
                                    }, null, -1)),
                                    _createElementVNode("p", _hoisted_59, _toDisplayString(input.createdAt
                              ? new Date(input.createdAt).toLocaleDateString(
                                  "de"
                                )
                              : ""), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_60, [
                                    _cache[25] || (_cache[25] = _createElementVNode("img", {
                                      class: "w-3 h-3",
                                      src: _imports_2,
                                      alt: "User"
                                    }, null, -1)),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(input.workers, (worker, index) => {
                                      return (_openBlock(), _createElementBlock("p", {
                                        key: index,
                                        class: "text-sm text-[#898790] foont-medium"
                                      }, [
                                        _createTextVNode(_toDisplayString(worker), 1),
                                        (index < input.workers.length - 1)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_61, ","))
                                          : _createCommentVNode("", true)
                                      ]))
                                    }), 128))
                                  ])
                                ]),
                                _createElementVNode("img", {
                                  onClick: ($event: any) => (toggleModalHandler(input)),
                                  class: "w-4 h-4 cursor-pointer",
                                  src: _imports_3,
                                  alt: "Pencil"
                                }, null, 8, _hoisted_62)
                              ]),
                              _createElementVNode("div", _hoisted_63, [
                                _createElementVNode("p", _hoisted_64, _toDisplayString(input.name), 1),
                                _createElementVNode("div", _hoisted_65, [
                                  _createElementVNode("p", _hoisted_66, _toDisplayString(input.value), 1)
                                ])
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["step-id", "step-name"])
          ]))
        }), 128))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col ml-auto", `${isMap.value ? 'w-2/5' : 'w-fit'}`])
      }, [
        (!isMap.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["flex items-center xl:gap-3 2xl:gap-4 justify-end z-[500]", isMap.value ? 'flex-row' : 'flex-col xl:flex-row'])
            }, [
              (_ctx.phaseDetails.phases.find(
                  (phase) => phase.phaseId === _ctx.phaseId
                )?.salesforceId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                    _createElementVNode("a", {
                      href: `https://smalt.lightning.force.com/lightning/r/Phase__c/${_ctx.phaseDetails.phases.find(
                  (phase) => phase.phaseId === _ctx.phaseId
                )?.salesforceId}/view`,
                      target: "_blank",
                      class: "w-[36px] h-[24px]"
                    }, _cache[26] || (_cache[26] = [
                      _createElementVNode("img", {
                        class: "w-full h-full cursor-pointer",
                        src: _imports_4,
                        alt: ""
                      }, null, -1)
                    ]), 8, _hoisted_68)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["relative flex items-center justify-center w-[36px] h-[36px] rounded-full hover:bg-white", isTerminFilterOpen.value === true ? 'bg-white' : 'bg-transparent'])
              }, [
                _createElementVNode("img", {
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (isTerminFilterOpen.value = !isTerminFilterOpen.value)),
                  class: "w-[24px] h-[24px] object-contain cursor-pointer",
                  src: _imports_5,
                  alt: ""
                }),
                _createVNode(TerminFilter, {
                  "is-open": isTerminFilterOpen.value,
                  "is-baumappe-open": props.phaseDetails.project.baummape === null,
                  project: _ctx.phaseDetails,
                  onCloseTerminFilter: _cache[8] || (_cache[8] = ($event: any) => (isTerminFilterOpen.value = false)),
                  onOnFilterSubmit: onFilterSubmit
                }, null, 8, ["is-open", "is-baumappe-open", "project"])
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["relative flex items-center justify-center w-[36px] h-[36px] rounded-full hover:bg-white", 
            isAddImageModalOpen.value === true && !selectedInput.value
              ? 'bg-white'
              : 'bg-transparent'
          ])
              }, [
                _createElementVNode("img", {
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (isAddImageModalOpen.value = true)),
                  class: "w-[24px] h-[24px] object-contain cursor-pointer",
                  src: _imports_6,
                  alt: ""
                }),
                (isAddImageModalOpen.value === true && !selectedInput.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["absolute top-[102px] xl:top-[65px] z-[777]", 
                props.phaseDetails.project.baummape === null
                  ? 'right-[24px]'
                  : 'right-[24px] xl:right-[70px]'
              ])
                      }, [
                        (isAddImageModalOpen.value && !selectedInput.value)
                          ? (_openBlock(), _createBlock(AddImageModal, {
                              key: 0,
                              steps: _ctx.phaseDetails.steps,
                              termins: _ctx.phaseDetails.termins,
                              "is-open": isAddImageModalOpen.value,
                              project: _unref(route).params.id,
                              "header-title": "Add picture",
                              phase: 
                  _unref(route).params.phaseId ? Number(_unref(route).params.phaseId) : -1
                ,
                              onToggleModal: _cache[10] || (_cache[10] = ($event: any) => {
                  isAddImageModalOpen.value = false;
                  selectedInput.value = null;
                  selectedStep.value = null;
                }),
                              onRefresh: _cache[11] || (_cache[11] = ($event: any) => (emits('refreshData')))
                            }, null, 8, ["steps", "termins", "is-open", "project", "phase"]))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]))
                  : _createCommentVNode("", true)
              ], 2),
              (props.phaseDetails.project.baummape !== null)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    onClick: toggleProjectMap,
                    class: _normalizeClass(["col-start-2 col-span-1 xl:col-start-auto xl:col-span-1 relative flex items-center justify-center w-[36px] h-[36px] rounded-full hover:bg-white", isMap.value ? 'bg-white' : 'bg-transparent'])
                  }, [
                    _createElementVNode("img", {
                      class: _normalizeClass(["w-[24px] h-[24px] object-contain cursor-pointer", 
              props.phaseDetails.project.baummape === null ? 'hidden' : 'block'
            ]),
                      src: _imports_7,
                      alt: ""
                    }, null, 2)
                  ], 2))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true),
        (isMap.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
              _createVNode(ProjectMap, {
                "phase-details": props.phaseDetails,
                onToggleProjectMap: toggleProjectMap
              }, null, 8, ["phase-details"])
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ])
  ], 64))
}
}

})