import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/red-x-mark.svg'
import _imports_1 from '@/assets/accordion-check-icon.svg'


const _hoisted_1 = { class: "w-full list-none flex flex-col items-start gap-3" }
const _hoisted_2 = { class: "w-full flex items-center gap-2 justify-between border-b border-gray-100 pb-1" }

import { computed } from "vue";

type Props = {
  checklist: any;
  status?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckListBlock',
  props: {
    checklist: {},
    status: {}
  },
  setup(__props: any) {

const props = __props;
const checkboxList = computed(() => {
  if (props.status === "abnahme") {
    return props.checklist.filter((item: any) => !item.value);
  }
  return props.checklist;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold" }, " Checklist ", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(checkboxList.value, (input) => {
      return (_openBlock(), _createElementBlock("div", {
        key: input.id,
        class: "my-3"
      }, [
        _createElementVNode("ul", _hoisted_1, [
          _createElementVNode("li", _hoisted_2, [
            _createElementVNode("p", {
              class: _normalizeClass(["relative text-sm sm:text-base text-primary-text-color font-normal leading-5 tracking-[0.01rem] cursor-pointer", input.value ? 'line-through' : ''])
            }, _toDisplayString(input.name), 3),
            _createElementVNode("img", {
              class: _normalizeClass([input.value ? 'hidden' : 'block', "cursor-pointer"]),
              src: _imports_0,
              alt: ""
            }, null, 2),
            _createElementVNode("img", {
              class: _normalizeClass(input.value ? 'block' : 'hidden'),
              src: _imports_1,
              alt: "Check icon"
            }, null, 2)
          ])
        ])
      ]))
    }), 128))
  ]))
}
}

})