<script setup lang="ts">
import { Project } from "@/types";
import { textTransform } from "@/utils/parse-document";
import { getProjectStatusColor } from "@/utils/status-color";
import { defineEmits, ref, defineProps } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const projectId = route.params.id;

const isDisabled = ref(true);

type Props = {
  projectDetails: Project | null;
};

type Emits = {
  (event: "showTimeline"): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const phaseFilter = ref("");
const onFilterHandler = (filter: any) => {
  phaseFilter.value = phaseFilter.value === filter ? null : filter;
};
</script>

<template>
  <div class="p-3 h-full rounded-primary-radius bg-[#E5E4E2] font-sans">
    <div class="h-[332px] overflow-hidden">
      <div class="flex items-start justify-between">
        <h2
          class="text-xl pb-2 text-primary-text-color tracking-[0.01rem] font-semibold"
        >
          Appointments
        </h2>
        <div class="flex items-start gap-2">
          <button
            @click="emits('showTimeline')"
            class="w-6 h-6 flex items-center justify-center cursor-pointer rounded-sm duration-200"
          >
            <img src="@/assets/arrow-left.svg" alt="Arrow left" />
          </button>
          <button
            class="w-6 h-6 flex items-center justify-center rounded-sm duration-200"
          >
            <img
              v-show="isDisabled"
              src="@/assets/arrow-right-gray.svg"
              alt="Arrow right"
            />
            <img
              v-show="!isDisabled"
              src="@/assets/arrow-right.svg"
              alt="Arrow right"
            />
          </button>
        </div>
      </div>
      <!-- Filter -->
      <div class="flex flex-col items-start gap-[6px]">
        <p class="text-sm text-primary-text-color pb-[6px] font-medium">
          Filter by:
        </p>
        <div class="flex items-center gap-[6px]">
          <button
            v-for="phase in props.projectDetails?.phases"
            :key="phase.phaseId"
            @click="onFilterHandler(phase.phase.toLowerCase())"
            class="py-[2px] px-3 rounded-primary-radius text-sm tracking-[0.01rem] text-[#898790] font-medium cursor-pointer border border-[#898790]"
            :class="
              phaseFilter === phase.phase.toLowerCase()
                ? 'bg-white border-transparent text-primary-text-color'
                : ''
            "
          >
            {{ phase?.phase }}
          </button>
        </div>
      </div>
      <!-- Termins -->
      <div
        class="mt-3 w-full flex flex-col items-start gap-[10px] h-[220px] overflow-y-auto"
      >
        <div
          class="w-full py-[6px] px-[8px] bg-white rounded-primary-radius shadow-primary-box-shadow"
          v-for="termin in props.projectDetails?.termins.filter((item:any)=> {
            if(!phaseFilter){
              return true;
            }else{
              return item.phase.toLowerCase() === phaseFilter.toLocaleLowerCase()
            }
          })"
          :key="termin.id"
        >
          <RouterLink :to="`/projects/${projectId}/termin/${termin.id}`">
            <!-- Header -->
            <div class="flex items-center justify-between mb-[18px]">
              <h4
                class="text-sm tracking-[0.01rem] text-primary-text-color font-medium"
              >
                {{ termin.name ? termin.name : "-" }}
              </h4>
              <span
                class="text-xs ml-2 tracking-[0.01rem] text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius"
                :style="`background: ${getProjectStatusColor(
                  termin?.status?.name
                )}`"
                >{{
                  termin.status?.name ? textTransform(termin.status?.name) : "-"
                }}</span
              >
            </div>
            <!-- Body -->
            <div>
              <!-- Date -->
              <div class="flex items-center gap-1">
                <img
                  class="w-[16px] h-[16px]"
                  src="@/assets/calendar-icon.svg"
                  alt="Calendar icon"
                />
                <div class="flex items-center gap-[1px] text-xs">
                  <p class="text-sm text-primary-text-color font-normal">
                    {{
                      termin.start
                        ? new Date(termin.start).toLocaleDateString("DE")
                        : " "
                    }}
                  </p>
                  -
                  <p class="text-sm text-primary-text-color font-normal">
                    {{
                      termin.end
                        ? new Date(termin.end).toLocaleDateString("DE")
                        : " "
                    }}
                  </p>
                </div>
              </div>
              <!-- Workers -->
              <div
                v-if="termin && termin.workers"
                class="flex items-start gap-1 mt-[6px]"
              >
                <img
                  class="w-[16px] h-[16px]"
                  src="@/assets/users-icon.svg"
                  alt="Users icon"
                />
                <div class="flex flex-col items-start gap-1">
                  <p
                    class="text-sm"
                    v-for="(worker, index) in termin.workers"
                    :key="index"
                  >
                    {{ worker ? worker : "-"
                    }}<span v-if="index < termin.workers?.length - 1">, </span>
                  </p>
                </div>
              </div>
            </div>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>
