import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/arrow-left-gray.svg'
import _imports_1 from '@/assets/arrow-left.svg'
import _imports_2 from '@/assets/arrow-right-gray.svg'
import _imports_3 from '@/assets/arrow-right.svg'
import _imports_4 from '@/assets/download-icon.svg'
import _imports_5 from '@/assets/arrow-up-right.svg'
import _imports_6 from '@/assets/no-documents-image.svg'


const _hoisted_1 = { class: "h-[356px] w-full p-3 bg-white rounded-primary-radius" }
const _hoisted_2 = { class: "h-full overflow-hidden" }
const _hoisted_3 = { class: "flex items-center justify-between mb-2" }
const _hoisted_4 = {
  key: 0,
  class: "flex items-center gap-6"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  alt: ""
}
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 0,
  src: _imports_2,
  alt: "Arrow right"
}
const _hoisted_10 = {
  src: _imports_3,
  alt: "Arrow right"
}
const _hoisted_11 = {
  key: 1,
  class: "w-full h-[290px] overflow-y-auto flex flex-col items-start gap-2"
}
const _hoisted_12 = { class: "text-base leading-[20px] text-[#898790] font-semibold tracking-[0.01rem] py-1" }
const _hoisted_13 = { class: "list-none flex flex-col gap-[2px]" }
const _hoisted_14 = { class: "flex-[80%] text-base text-primary-text-color font-normal tracking-[0.01rem] py-[6px]" }
const _hoisted_15 = { class: "flex-[20%] flex items-center justify-end" }
const _hoisted_16 = ["href"]
const _hoisted_17 = ["href"]
const _hoisted_18 = {
  key: 0,
  class: "w-full mt-[70px] flex items-center justify-center"
}

import { Project } from "@/types";
import { computed, ref } from "vue";
import CardLoader from "@/components/Loaders/CardLoader.vue";

type Props = {
  isLoading: boolean;
  projectDetails: Project | null;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectDocuments',
  props: {
    isLoading: { type: Boolean },
    projectDetails: {}
  },
  setup(__props: any) {

const props = __props;

const selectedPage = ref<number>(1);

let documentPages = computed(() => {
  if (props.projectDetails?.documents) {
    let documentSections = [
      {
        id: 1,
        title: "Baumappe",
        typeIds: [4],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type?.id === 4
        ),
      },
      {
        id: 2,
        title: "Phase reports",
        typeIds: [5, 6, 7],
        documents: props.projectDetails?.documents.filter(
          (item: any) =>
            item.project_document_type?.id === 5 ||
            item.project_document_type?.id === 6 ||
            item.project_document_type?.id === 7
        ),
      },
      {
        id: 3,
        title: "Abnahme protokol",
        typeIds: [2],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type?.id === 2
        ),
      },
      {
        id: 4,
        title: "Prüfbericht",
        typeIds: [3],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type?.id === 3
        ),
      },
      {
        id: 5,
        title: "Other",
        typeIds: [null],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type === null
        ),
      },
    ];

    const filteredSections = documentSections.filter(
      (section: any) => section.documents && section.documents.length > 0
    );

    const pages = [];
    let pageNumber = 1;
    for (let i = 0; i < filteredSections.length; i += 2) {
      pages.push({
        id: pageNumber,
        sections: filteredSections.slice(i, i + 2),
      });
      pageNumber++;
    }

    return pages;
  }

  return [];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-xl text-primary-text-color tracking-[0.01rem] font-semibold text-prim" }, " Documents ", -1)),
        (_unref(documentPages).length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("button", {
                disabled: selectedPage.value === 1,
                class: "cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (selectedPage.value = selectedPage.value - 1))
              }, [
                (selectedPage.value === 1)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                  : _createCommentVNode("", true),
                (selectedPage.value !== 1)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_5),
              _createElementVNode("button", {
                disabled: selectedPage.value === _unref(documentPages).length,
                class: "cursor-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (selectedPage.value = selectedPage.value + 1))
              }, [
                (selectedPage.value === _unref(documentPages).length)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                  : _createCommentVNode("", true),
                _withDirectives(_createElementVNode("img", _hoisted_10, null, 512), [
                  [_vShow, selectedPage.value !== _unref(documentPages).length]
                ])
              ], 8, _hoisted_8)
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(CardLoader, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(documentPages), (page) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: page.id,
                class: "w-full flex flex-col gap-2"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(page?.sections, (section) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: section.id,
                    class: "p-2 rounded-primary-radius border border-gray-300"
                  }, [
                    _createElementVNode("h5", _hoisted_12, _toDisplayString(section.title), 1),
                    _createElementVNode("ul", _hoisted_13, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section?.documents, (document, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: document.id,
                          class: _normalizeClass([{ 'border-t border-[#E3E1EA]': index !== 0 }, "flex items-center justify-between"])
                        }, [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(document.name), 1),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("a", {
                              class: "bg-transparent p-[2px] rounded-primary-radius cursor-pointer hover:bg-[#e5e4e2] duration-300",
                              href: `${document.url}?download`,
                              target: "_blank"
                            }, _cache[3] || (_cache[3] = [
                              _createElementVNode("img", {
                                class: "min-w-[16px] w-[16px] min-h-[16px]",
                                src: _imports_4,
                                alt: ""
                              }, null, -1)
                            ]), 8, _hoisted_16),
                            _createElementVNode("a", {
                              class: "bg-transparent p-[2px] rounded-primary-radius cursor-pointer hover:bg-[#e5e4e2] duration-300",
                              href: document.url + '?' + new Date().toISOString(),
                              target: "_blank"
                            }, _cache[4] || (_cache[4] = [
                              _createElementVNode("img", {
                                class: "min-w-[16px] w-[16px] min-h-[16px]",
                                src: _imports_5,
                                alt: ""
                              }, null, -1)
                            ]), 8, _hoisted_17)
                          ])
                        ], 2))
                      }), 128))
                    ])
                  ]))
                }), 128))
              ])), [
                [_vShow, page.id === selectedPage.value]
              ])
            }), 128)),
            (_unref(documentPages).length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[5] || (_cache[5] = [
                  _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      src: _imports_6,
                      alt: "No documents"
                    }),
                    _createElementVNode("p", { class: "text-sm mt-2 tracking-[0.01rem] font-medium text-[#898790]" }, " No documents ")
                  ], -1)
                ])))
              : _createCommentVNode("", true)
          ]))
    ])
  ]))
}
}

})