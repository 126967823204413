<script setup lang="ts">
import { computed, defineProps } from "vue";

type Props = {
  checklist: any;
  status?: string;
};

const props = defineProps<Props>();
const checkboxList = computed(() => {
  if (props.status === "abnahme") {
    return props.checklist.filter((item: any) => !item.value);
  }
  return props.checklist;
});
</script>

<template>
  <div>
    <h3
      class="text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold"
    >
      Checklist
    </h3>
    <div v-for="input in checkboxList" :key="input.id" class="my-3">
      <ul class="w-full list-none flex flex-col items-start gap-3">
        <li
          class="w-full flex items-center gap-2 justify-between border-b border-gray-100 pb-1"
        >
          <p
            class="relative text-sm sm:text-base text-primary-text-color font-normal leading-5 tracking-[0.01rem] cursor-pointer"
            :class="input.value ? 'line-through' : ''"
          >
            {{ input.name }}
          </p>
          <img
            :class="input.value ? 'hidden' : 'block'"
            class="cursor-pointer"
            src="@/assets/red-x-mark.svg"
            alt=""
          />
          <img
            :class="input.value ? 'block' : 'hidden'"
            src="@/assets/accordion-check-icon.svg"
            alt="Check icon"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
