import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "vue3-toastify/dist/index.css";
import Vue3Toastify, { toast, type ToastContainerOptions } from "vue3-toastify";
import GoogleSignInPlugin from "vue3-google-signin";

const app = createApp(App);
const pinia = createPinia();
import "./utils/axios";
app.use(pinia);
pinia.use(piniaPluginPersistedstate);
app.use(ElementPlus);
app.use(Vue3Toastify, {
  autoClose: 3000,
  position: toast.POSITION.TOP_RIGHT,
  newestOnTop: true,
  theme: "colored",
  closeButton: false,
  hideProgressBar: true,
} as ToastContainerOptions);
app.use(GoogleSignInPlugin, {
  clientId: process.env.VUE_APP_GOOGLE_SIGN_IN_CLIENT_ID,
});
app.use(router).mount("#app");
