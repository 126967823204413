import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/select-menu-arrow.svg'


const _hoisted_1 = { class: "text-base text-primary-text-color font-normal tracking-[0.01rem]" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 1 }

import { ref, watch } from "vue";

type Props = {
  options?: any;
  modelValue?: any | null;
  disabled?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectMenu',
  props: {
    options: {},
    modelValue: {},
    disabled: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const optionsVisible = ref(false);
const selectedOption = ref<any | null>(props.modelValue);

const toggleOptionsHandler = () => {
  optionsVisible.value = !optionsVisible.value;
};

const selectedOptionHandler = (option: any) => {
  selectedOption.value = option;
  emit("update:modelValue", option);
  optionsVisible.value = false;
};

watch(
  () => props.modelValue,
  (newValue) => {
    selectedOption.value = newValue;
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["mt-[6px] bg-[#F9F8F6] border border-[#E3E1EA] rounded-[5px] p-1", `${_ctx.disabled ? '' : 'cursor-pointer'}`])
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.disabled ? toggleOptionsHandler() : () => {})),
      class: "flex items-center justify-between p-3 h-[50px]"
    }, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(selectedOption.value ? selectedOption.value.name : ""), 1),
      _createElementVNode("img", {
        class: _normalizeClass(["w-4 h-[10px] duration-200", optionsVisible.value ? '-rotate-180' : 'rotate-0']),
        src: _imports_0,
        alt: "Chevron"
      }, null, 2)
    ]),
    (_ctx.options.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (optionsVisible.value === true)
            ? (_openBlock(), _createElementBlock("ul", {
                key: 0,
                class: _normalizeClass(["list-none flex flex-col items-start", props.options.length !== 0 ? 'p-3 pt-0' : 'p-0'])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (option) => {
                  return (_openBlock(), _createElementBlock("li", {
                    onClick: ($event: any) => (selectedOptionHandler(option)),
                    key: option.id,
                    class: _normalizeClass(["w-full rounded-primary-radius text-base text-primary-text-color font-normal hover:bg-[#e5e4e2] cursor-pointer hover:pl-[8px] duration-200", option ? 'pl-0 pr-[5px] py-[10px]' : 'p-0'])
                  }, _toDisplayString(option.name), 11, _hoisted_3))
                }), 128))
              ], 2))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, "No options found."))
  ], 2))
}
}

})