<script setup lang="ts">
import InputChecklist from "@/components/Re-usable/InputChecklist.vue";
import InputTable from "@/components/Re-usable/InputTable.vue";
import { defineEmits, reactive, ref, defineProps, onMounted } from "vue";
import HorizontalDynamicTable from "../HorizontalDynamicTable.vue";
import VerticalDynamicTable from "../VerticalDynamicTable.vue";
import SelectMenu from "@/components/Re-usable/SelectMenu.vue";
import { getStatusByName } from "@/utils/report-status";
import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";

type Props = {
  data: any;
  termin: any;
};

type Emits = {
  (event: "close"): void;
};

const props = defineProps<Props>();

const state = ref(props.data);

const emits = defineEmits<Emits>();
const onChangeInput = (name: any, value: any, propertyName?: string) => {
  if (propertyName) {
    let inputIndex = state.value[propertyName].findIndex(
      (item: any) => item.id === Number(name)
    );

    let tempList = [...state.value[propertyName]];
    if (inputIndex != -1) {
      tempList[inputIndex].value = value;
      state.value[propertyName] = tempList;
    }
  } else {
    state.value[name] = value;
  }
};

let pvModuleTable = [
  {
    label: "Hersteller",
    name: "pv_module_hersteller",
    value: state.value?.pv_module_hersteller,
  },
  {
    label: "Modultyp",
    name: "pv_module_modultyp",
    value: state.value?.pv_module_modultyp,
  },
  {
    label: "Modulanzahl",
    name: "pv_module_modulanzahl",
    value: state.value?.pv_module_modulanzahl,
  },
  {
    label: "PV-Modulleistung",
    name: "pv_module_modulleistung",
    value: state.value?.pv_module_modulleistung,
  },
  {
    label: "Kurzschlussstrom Isc (A)",
    name: "pv_module_kurzschlussstrom",
    value: state.value?.pv_module_kurzschlussstrom,
  },
  {
    label: "Leerlaufspannung Uoc (V)",
    name: "pv_module_Leerlaufspannung",
    value: state.value?.pv_module_Leerlaufspannung,
  },
  {
    label: "MPP-Strom (A)",
    name: "pv_module_mpp_strom",
    value: state.value?.pv_module_mpp_strom,
  },
  {
    label: "MPP-Spannung (V)",
    name: "pv_module_mpp_spannung",
    value: state.value?.pv_module_mpp_spannung,
  },
];
let pvWechselrichterTable = [
  {
    label: "Hersteller",
    name: "pv_wechselrichter_hersteller",
    value: state.value?.pv_wechselrichter_hersteller,
  },
  {
    label: "Wechselrichtertyp 1",
    name: "pv_wechselrichter_hersteller_typ_1",
    value: state.value?.pv_wechselrichter_hersteller_typ_1,
  },
  {
    label: "Wechselrichteranzahl",
    name: "pv_wechselrichter_wechselrichteranzahl",
    value: state.value?.pv_wechselrichter_wechselrichteranzahl,
  },
];
let pvBatteriespeicherTable = [
  {
    label: "Hersteller",
    name: "pv_batteriespeicher_hersteller",
    value: state.value?.pv_batteriespeicher_hersteller,
  },
  {
    label: "Batteriespeichertyp",
    name: "pv_batteriespeicher_typ",
    value: state.value?.pv_batteriespeicher_typ,
  },
];

const addColumnHorizontalDynamicTable = (tableName: string) => {
  let tempTable = [...state.value[tableName]];
  tempTable = tempTable.map((item: any) => {
    item.value.push("");
    return item;
  });

  state.value[tableName] = tempTable;
};

const horizontalDynamicTableOnChange = (
  tableName: string,
  valueArrayIndex: number,
  columnIndex: number,
  value: any
) => {
  let inputIndex = state.value[tableName].findIndex(
    (item: any) => item.id === Number(columnIndex)
  );
  let tempTable = state.value[tableName];
  tempTable[inputIndex].value[valueArrayIndex] = value;

  state.value[tableName] = tempTable;
};

const verticalDynamicTableInputOnChange = (
  tableName: string,
  rowId: any,
  inputId: any,
  value: any
) => {
  let tempList = [...state.value[tableName]];

  let inputIndex = tempList[rowId].findIndex(
    (item: any) => item.id === Number(inputId)
  );
  if (inputIndex != -1) {
    tempList[rowId][inputIndex].value = value;
    state.value[tableName] = tempList;
  }
};

const verticalDynamicTableColumnInputOnChange = (
  tableName: string,
  columnId: number,
  value: any
) => {
  let tempList = [...state.value[tableName]];

  tempList = tempList.map((item: any) => {
    let inputIndex = item.findIndex(
      (item: any) => item.id === Number(columnId)
    );
    if (inputIndex != -1) {
      item[inputIndex].text = value;
    }
    return item;
  });
  state.value[tableName] = tempList;
};

const addRowVerticalDynamicTable = (tableName: string) => {
  let table = state.value[tableName];
  let rowCount = table.length;

  let tempRow: any = [];
  table[0].forEach((item: any) => {
    let tempColumn = { ...item };
    if (tempColumn && tempColumn.id === 1) {
      tempColumn.value = rowCount + 1;
    } else {
      tempColumn.value = "";
    }
    tempRow.push(tempColumn);
  });
  table.push(tempRow);
  state.value[tableName] = table;
};

let profungNachSelect = ref({
  id: state.value.prufung_nach,
  name: state.value.prufung_nach,
});

let netzformSelect = ref({
  id: state.value.netzform,
  name: state.value.netzform,
});

let prufergebnisSelect = ref({
  id: state.value.prufergebnis_select,
  name: state.value.prufergebnis_select,
});

let stringPlanSelect = ref({
  id: state.value.string_plan,
  name: state.value.string_plan,
});
const isLoading = ref(false);
const isReportLoading = ref(false);

const onSubmit = async () => {
  isLoading.value = true;

  try {
    state.value.prufung_nach = profungNachSelect.value
      ? profungNachSelect.value.id
      : null;

    state.value.netzform = netzformSelect.value
      ? netzformSelect.value.id
      : null;

    state.value.prufergebnis_select = prufergebnisSelect.value
      ? prufergebnisSelect.value.id
      : null;

    state.value.string_plan = stringPlanSelect.value
      ? stringPlanSelect.value.id
      : null;

    let resp = await client.post(
      `/termin/${props.termin.id}/save-prufbericht-report`,
      {
        data: state.value,
      }
    );

    state.value = resp.data.report;
    isLoading.value = false;
    successToast("Successfully saved report.");
  } catch (error: any) {
    isLoading.value = false;
    errorToast(error);
  }
};

const generateReport = async () => {
  successToast("Your report is being generated, please wait.");
  isReportLoading.value = true;
  try {
    state.value.prufung_nach = profungNachSelect.value
      ? profungNachSelect.value.id
      : null;

    state.value.netzform = netzformSelect.value
      ? netzformSelect.value.id
      : null;

    state.value.prufergebnis_select = prufergebnisSelect.value
      ? prufergebnisSelect.value.id
      : null;

    state.value.string_plan = stringPlanSelect.value
      ? stringPlanSelect.value.id
      : null;
    await client.post(
      `/termin/${props.termin.id}/generate-prufbericht-report`,
      {
        data: state.value,
      }
    );
    isReportLoading.value = false;
    successToast("Successfully generated report.");
  } catch (error: any) {
    isReportLoading.value = false;
    errorToast(error);
  }
};
</script>

<template>
  <div
    class="fixed inset-0 bg-black bg-opacity-80 justify-center items-center z-50 transition-opacity duration-300 ease-in-out p-4"
  >
    <div
      class="w-full h-[calc(100vh-32px)] overflow-y-auto rounded-primary-radius p-5 bg-[#FFFFFF] relative"
    >
      <!-- header -->
      <div
        class="w-full flex h-16 bg-white absolute top-0 left-0 items-center justify-between p-5 border-b border-[#E3E1EA]"
      >
        <!-- left side -->
        <div class="flex items-center justify-start gap-2">
          <img src="@/assets/pencil-icon.svg" alt="Icon" />
          <h3
            class="text-primary-text-color text-xl leading-[32px] font-semibold tracking-[0.01rem]"
          >
            Prüfbericht
          </h3>
          <p
            class="text-[14px] tracking-[0.01rem] text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius bg-[#A7A5AE]"
          >
            {{ getStatusByName(state?.status) }}
          </p>
        </div>
        <!-- close button -->
        <img
          @click="
            emits('close');
            state = null;
          "
          class="cursor-pointer"
          src="@/assets/close-icon-2.svg"
          alt="Close icon"
        />
      </div>
      <!-- form -->
      <div class="h-full overflow-y-auto py-16">
        <!-- input field -->
        <div class="mt-[6px]">
          <input
            v-model="state.reason"
            class="w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]"
            type="text"
            placeholder="Grund der Prüfung:"
          />
        </div>
        <!-- Input tables -->
        <div class="flex items-start gap-8 my-10">
          <div>
            <InputTable
              title="PV-Module"
              :data="pvModuleTable"
              @on-change-input="onChangeInput"
            />
          </div>
          <div>
            <InputTable
              title="PV-Wechselrichter"
              :data="pvWechselrichterTable"
              @on-change-input="onChangeInput"
            />
          </div>
          <div>
            <InputTable
              title="PV-Batteriespeicher"
              :data="pvBatteriespeicherTable"
              @on-change-input="onChangeInput"
            />
          </div>
        </div>
        <div class="mb-[40px]">
          <h3
            class="text-primary-text-color text-xl leading-[32px] pb-2 font-semibold tracking-[0.01rem]"
          >
            Konstruktion, Aufbau, Besichtigung sowie Prüfung
          </h3>
          <p
            class="text-primary-text-color text-lg leading-[24px] tracking-[0.01rem] font-normal"
          >
            Ich/Wir, die verantwortliche(n) Person(en) für die Konstruktion,
            Aufbau, Besichtigung sowie Prüfung der elektrischen Anlage (wie
            nachfolgend durch die Unterschrift(en) angegeben), deren
            Einzelheiten oben beschrieben sind, haben mit angemessener
            Fachkenntnis und Sorgfalt die Besichtigung sowie Prüfung der
            Konstruktion und des Aufbaus vorgenommen und bestätigen hiermit,
            dass die genannten Arbeiten, für die ich/wir verantwortlich bin
            (sind), nach besten Kenntnissen und Wissen ausgeführt wurden.
          </p>
        </div>
        <!-- Input checklist -->
        <div>
          <InputChecklist
            title="Prüfergebnis"
            checklist-name="prüfergebnis"
            :data="state?.prüfergebnis"
            @on-change-input="onChangeInput"
          />
        </div>
        <div>
          <InputChecklist
            title="Konstruktion und Installation des PV-Generators"
            checklist-name="konstruktion_installationPv_generators"
            :data="state?.konstruktion_installationPv_generators"
            @on-change-input="onChangeInput"
          />
        </div>
        <div>
          <InputChecklist
            title="PV-System/ Schutz gegen Überspannung/ elektrischen Schlag"
            checklist-name="pv_system"
            :data="state?.pv_system"
            @on-change-input="onChangeInput"
          />
        </div>
        <div>
          <InputChecklist
            title="Besondere Faktoren PV-System - Wechselstromkreis"
            checklist-name="besondere_faktoren_pv_system"
            :data="state?.besondere_faktoren_pv_system"
            @on-change-input="onChangeInput"
          />
        </div>
        <div>
          <InputChecklist
            title="Aufschriften und Kennzeichnung des PV-Systems"
            checklist-name="aufschriften_kennzeichnung_pv_systems"
            :data="state?.aufschriften_kennzeichnung_pv_systems"
            @on-change-input="onChangeInput"
          />
        </div>
        <div>
          <InputChecklist
            title="Allgemeine (mechanische) Installation des PV-Systems"
            checklist-name="allgemeine_installation"
            :data="state?.allgemeine_installation"
            @on-change-input="onChangeInput"
          />
        </div>
        <div class="flex flex-col gap-2">
          <h2 class="text-[#c55d57] text-xl font-semibold">
            Prüfbericht der elektrischen Prüfung des PV-Generators gemäß VDE
            0126-23 (DIN EN 62446)
          </h2>
          <HorizontalDynamicTable
            v-if="state?.strang_table"
            title="Strang"
            :data="state?.strang_table"
            table-name="strang_table"
            @on-change-input="horizontalDynamicTableOnChange"
            @add-column="addColumnHorizontalDynamicTable"
          />
        </div>
        <div class="flex flex-col pt-10 gap-2">
          <h2 class="text-[#c55d57] text-xl font-semibold">
            Prüfbericht der elektrischen Prüfung der AC-Seite der PV-Anlage
            gemäß ZVEH-Vorlage
          </h2>
          <div>
            <label>Prüfung nach</label>
            <SelectMenu
              :options="state.prufung_nach_options.map((item:any)=>{ return { id:item,
            name:item } })"
              v-model="profungNachSelect"
            />
          </div>
          <div
            v-if="profungNachSelect?.id === 'DIN VDE 0100-600'"
            class="mt-[6px]"
          >
            <input
              v-model="state.prufung_nach_description"
              class="w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]"
              type="text"
              placeholder="Netz..../.....V"
            />
          </div>
        </div>
        <div class="flex flex-col pt-10 gap-2">
          <h2 class="text-[#393840] text-xl font-semibold">Netzform</h2>
          <div>
            <SelectMenu
              v-model="netzformSelect"
              :options="state.netzform_options.map((item:any)=>{ return { id:item,
            name:item } })"
            />
          </div>
          <div class="mt-[6px]">
            <input
              v-model="state.netzbetreiber"
              class="w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]"
              type="text"
              placeholder="Netzbetreiber: …….."
            />
          </div>
        </div>
        <div class="pt-10">
          <InputChecklist
            title="Besichtigen"
            checklist-name="besichtigen"
            :data="state?.besichtigen"
            @on-change-input="onChangeInput"
          />
        </div>
        <div class="flex flex-col gap-2">
          <h2 class="text-[#393840] text-xl font-semibold">Stromkreis</h2>
          <p class="text-base text-[#898790]">
            Nur veränderte und umgebaute Stromkreise
          </p>
          <VerticalDynamicTable
            :data="state?.stromkreis"
            table-name="stromkreis"
            @on-change-input="verticalDynamicTableInputOnChange"
            @on-change-column="verticalDynamicTableColumnInputOnChange"
            @add-row="addRowVerticalDynamicTable"
          />
        </div>
        <div class="flex flex-col my-4 gap-2">
          <input
            v-model="state.durchgangigkeit_schutzleiters"
            class="w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]"
            type="text"
            placeholder="Durchgängigkeit des Schutzleiters: …….. <1Ω"
          />
          <input
            v-model="state.durchgangigkeit_potentialausgleich"
            class="w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]"
            type="text"
            placeholder="Durchgängigkeit Potentialausgleich: …… (< 1 Ω nachgewiesen)"
          />
          <input
            v-model="state.erdungswiderstand"
            class="w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]"
            type="text"
            placeholder="Erdungswiderstand: RE ........ Ω"
          />
        </div>
        <div>
          <VerticalDynamicTable
            :data="state?.fehlerstrom_schutzeinrichtung"
            table-name="fehlerstrom_schutzeinrichtung"
            @on-change-input="verticalDynamicTableInputOnChange"
            @add-row="addRowVerticalDynamicTable"
          />
        </div>
        <div class="pt-10">
          <InputChecklist
            title=""
            checklist-name="stromkreis_checklist"
            :data="state?.stromkreis_checklist"
            @on-change-input="onChangeInput"
          />
        </div>
        <div class="flex flex-col gap-2">
          <h2 class="text-[#393840] text-xl font-semibold">Prüfergebnis</h2>
          <div>
            <SelectMenu
              :options="state.prufergebnis_options.map((item:any)=>{ return { id:item,
            name:item } })"
              v-model="prufergebnisSelect"
            />
          </div>
        </div>
        <div class="flex flex-col pt-5 gap-2">
          <h2 class="text-[#393840] text-xl font-semibold">String plan</h2>
          <div>
            <SelectMenu
              :options="state.string_plan_options.map((item:any)=>{ return { id:item,
            name:item } })"
              v-model="stringPlanSelect"
            />
          </div>
        </div>
      </div>
      <!-- footer -->
      <div
        class="bg-white absolute bottom-0 left-0 flex justify-end gap-4 border-t border-[#e3e1ea] w-full p-5 py-3"
      >
        <button
          :disabled="isReportLoading"
          @click="generateReport"
          class="flex items-center gap-1 py-3 px-[16px] text-base text-primary-text-color bg-transparent tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer duration-200"
        >
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :class="`${isReportLoading ? 'animate-spin' : ''}`"
          >
            <path
              d="M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179"
              stroke="#393840"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Generate report
        </button>
        <button
          :disabled="isLoading"
          @click="onSubmit"
          class="w-[150px] flex items-center justify-center py-3 px-[56px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#28272c] duration-200"
        >
          <div
            v-if="isLoading"
            class="w-6 h-6 border-4 border-white border-t-transparent rounded-full animate-spin"
          ></div>
          <span v-else>Save</span>
        </button>
      </div>
    </div>
  </div>
</template>
