import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/termin-workers-icon.svg'
import _imports_1 from '@/assets/termin-calendar-icon.svg'


const _hoisted_1 = { class: "my-3 grid grid-cols-1 md:grid-cols-2 gap-[12.5px]" }
const _hoisted_2 = { class: "flex flex-wrap items-start gap-2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex items-center gap-2 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]" }
const _hoisted_5 = { class: "text-lg text-primary-text-color tracking-[0.01rem] font-medium" }



type Props = {
  termin: any;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'HeadBlocks',
  props: {
    termin: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex gap-2 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]", _ctx.termin.workers?.length >= 8 ? 'items-start' : 'items-center'])
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "min-w-[44px] h-[44px] flex items-center justify-center" }, [
        _createElementVNode("img", {
          class: "w-full h-full object-cover",
          src: _imports_0,
          alt: "Worker"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.termin.workers, (worker, index) => {
          return (_openBlock(), _createElementBlock("h3", {
            key: index,
            class: "text-lg text-primary-text-color tracking-[0.01rem] font-medium"
          }, [
            _createTextVNode(_toDisplayString(worker), 1),
            (index < _ctx.termin.workers.length - 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, ","))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex items-center justify-center" }, [
        _createElementVNode("img", {
          src: _imports_1,
          alt: "Calendar"
        })
      ], -1)),
      _createElementVNode("h3", _hoisted_5, _toDisplayString(new Date(_ctx.termin.start).toLocaleDateString("DE")) + " - " + _toDisplayString(new Date(_ctx.termin.end).toLocaleDateString("DE")), 1)
    ])
  ]))
}
}

})