<script setup lang="ts">
import router from "@/router";
import { useUserStore } from "@/stores/user";
import { textTransform } from "@/utils/parse-data";
import { getProjectStatusColor } from "@/utils/status-color";
import { defineProps, defineEmits, computed, ref } from "vue";
import { useRoute, RouterLink } from "vue-router";
import InlineLoader from "../Loaders/InlineLoader.vue";

const route = useRoute();
const projectId = route.params.id;
const userStore = useUserStore();

type Props = {
  phaseProgress?: any;
  phaseSteps?: any;
  selectedPhase?: number | null;
  isLoading?: boolean;
  isLink?: boolean;
};

const isLoading = computed(() => props.isLoading);
const isLink = computed(() => props.isLink);

const props = defineProps<Props>();

type Emit = {
  (event: "onPhaseSelect", phaseId: number): void;
};

const emit = defineEmits<Emit>();

const selectPhase = (phaseId: number) => {
  emit("onPhaseSelect", phaseId);
};

const logout = () => {
  const userStore = useUserStore();
  userStore.logout();
  router.push("/login");
};

const isPhase = ref(true);

const goBack = () => {
  if (route.path.includes("phase")) {
    const parts = route.path.split("/phase/");
    const updatedUrl = parts[0];
    router.push(updatedUrl);
  } else if (route.path.includes("termin")) {
    const parts = route.path.split("/termin/");
    const updatedUrl = parts[0];
    router.push(updatedUrl);
  } else {
    router.go(-1);
  }
};
</script>

<template>
  <div
    class="hidden md:flex flex-col relative w-[160px] h-[calc(100vh-55px)] my-auto mr-6 sm:mr-0 sm:pl-0"
    :class="isLink ? 'w-[160px] sm:w-[220px]' : ''"
  >
    <!-- Top -->
    <div class="flex items-center gap-2">
      <!-- Smalt icon -->
      <RouterLink
        to="/"
        class="w-[26px] h-[26px] flex items-center justify-center cursor-pointer"
      >
        <img src="@/assets/smalt-home-logo.svg" alt="Smalt icon" />
      </RouterLink>
      <!-- Left - right button controls -->
      <div v-if="route.path !== '/'" class="flex items-center gap">
        <button
          @click="goBack"
          class="w-6 h-6 flex items-center justify-center cursor-pointer rounded-sm hover:bg-gray-200 duration-200"
        >
          <img src="@/assets/arrow-left.svg" alt="" />
        </button>
        <button
          @click="$router.go(+1)"
          class="w-6 h-6 flex items-center justify-center cursor-pointer rounded-sm hover:bg-gray-200 duration-300"
          :class="selectedPhase ? 'bg-gray-200' : 'bg-transparent'"
        >
          <img src="@/assets/arrow-right.svg" alt="" />
        </button>
      </div>
    </div>
    <!-- Sidebar links -->
    <div v-show="isLink">
      <div
        v-if="isLoading"
        class="flex flex-col justify-center mt-6 gap-2 w-full items-start"
      >
        <InlineLoader v-for="i in 5" :key="i" />
      </div>
      <div v-else>
        <div class="flex flex-col items-start mt-6 gap-2">
          <div v-for="phase in props.phaseProgress" :key="phase.phaseId">
            <RouterLink
              @click="selectPhase(phase.phaseId)"
              :to="`/projects/${projectId}/phase/${phase.phaseId}`"
              class="w-max flex flex-col items-start sm:flex-row sm:items-center gap-2 p-[6px] rounded-primary-radius bg-transparent cursor-pointer group hover:bg-white hover:pr-[22px] transition-all duration-200"
              :class="
                phase.phaseId === selectedPhase
                  ? 'bg-white sm:pr-[22px]'
                  : 'bg-transparent pr-0'
              "
            >
              <img
                class="hidden sm:block absolute opacity-0 group-hover:opacity-100 transition-opacity duration-200 group-hover:mr-3"
                :class="
                  phase.phaseId === selectedPhase ? 'opacity-100' : 'opacity-0'
                "
                src="@/assets/angle-arrow.svg"
                alt="Arrow"
              />
              <h5
                class="text-sm leading-[17px] tracking-[0.01rem] text-primary-text-color uppercase font-semibold transition-all duration-200 group-hover:sm:translate-x-4"
                :class="
                  phase.phaseId === selectedPhase
                    ? 'sm:translate-x-4'
                    : 'translate-x-0'
                "
              >
                {{ phase.phase }}
              </h5>
              <span
                class="text-sm text-primary-text-color font-medium tracking-[0.01rem] rounded-primary-radius py-[2px] px-[6px] transition-all duration-200 group-hover:sm:translate-x-4"
                :class="
                  phase.phaseId === selectedPhase
                    ? 'sm:translate-x-4'
                    : 'translate-x-0'
                "
                :style="{
                  backgroundColor: `${getProjectStatusColor(
                    phase.phaseStatus?.name
                  )}`,
                }"
                >{{ textTransform(phase.phaseStatus?.name) }}</span
              >
            </RouterLink>

            <div v-if="isPhase">
              <ul
                v-if="phase.phaseId === selectedPhase"
                class="max-w-[200px] w-full ml-2 2xl:ml-4 pl-2 my-3 flex flex-col items-start gap-1 border-l-2 border-[#E5E4E2]"
              >
                <li
                  v-for="step in props.phaseSteps"
                  :key="step.id"
                  class="text-sm tracking-[0.01rem] text-primary-text-color font-medium py-1 px-2 rounded-primary-radius cursor-pointer hover:bg-white duration-500"
                  :class="`${
                    route.hash &&
                    (route.hash.replace('#step-', '') == step.id ||
                      route.hash.replace('#scroll-step-', '') == step.id)
                      ? 'bg-white'
                      : 'bg-transparent'
                  }`"
                >
                  <RouterLink
                    :to="`/projects/${projectId}/phase/${phase.phaseId}/#step-${step.id}`"
                    >{{ step.name }}</RouterLink
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Bottom -->
    <div class="mt-auto flex flex-col items-start gap-2">
      <!-- User -->
      <RouterLink
        to="/"
        class="hidden sm:flex h-6 items-center gap-1 p-2 cursor-pointer"
      >
        <img src="@/assets/user-icon.svg" alt="User icon" />
        <p class="text-sm font-normal text-primary-text-color">
          {{ userStore.getUser.fullName }}
        </p>
      </RouterLink>
      <!-- Logout -->
      <button
        @click="logout"
        class="flex items-center gap-1 p-2 cursor-pointer hover:bg-gray-200 duration-300"
      >
        <img src="@/assets/logout-icon.svg" alt="Logout icon" />
        <p class="text-sm font-normal text-primary-text-color">Logout</p>
      </button>
    </div>
  </div>
</template>
