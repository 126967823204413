import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/salesforce.svg'


const _hoisted_1 = { class: "w-full h-screen relative flex items-start justify-center gap-5 py-[33px] px-6 bg-[#EFEEEC]" }
const _hoisted_2 = {
  key: 0,
  class: "absolute left-6"
}
const _hoisted_3 = { class: "w-full custom-lg:w-[734px] xl:w-[834px] 2xl:w-[934px] ml-0 lg:ml-[160px] xl:ml-0" }
const _hoisted_4 = {
  key: 1,
  class: "flex items-center justify-center mt-20"
}
const _hoisted_5 = {
  key: 1,
  class: "w-fit absolute right-0 pr-10"
}
const _hoisted_6 = ["href"]

import TerminSideMenu from "@/components/Sidebar/TerminSideMenu.vue";
import TerminVIew from "./TerminVIew.vue";
import { useRoute } from "vue-router";
import client from "@/utils/axios";
import { onMounted, ref, watch } from "vue";
import SpinnerLoader from "@/components/Loaders/SpinnerLoader.vue";
import axios from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'TerminPage',
  setup(__props) {

const route = useRoute();
const termin = ref<any>([]);
const project = ref();
const selectedTermin = ref();
const selectedPhase = ref();
const isLoading = ref(false);

const onTerminSelect = (terminId: any) => {
  selectedPhase.value = terminId;
};

const getTerminData = async () => {
  isLoading.value = true;
  try {
    const response = await client.get(
      `/projects/${route.params.id}/termin/${route.params.terminId}`
    );
    termin.value = response.data;
    console.log(termin.value);
    let newSelectedPhase = "";

    termin.value.phases.forEach((phase: any) => {
      phase.termins.forEach((termin: any) => {
        if (termin.id === route.params.terminId) {
          newSelectedPhase = phase.phase.id;
          if (selectedPhase.value != newSelectedPhase) {
            selectedPhase.value = newSelectedPhase;
          }
        }
      });
    });
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

watch(
  () => route.params.terminId, // Watch for the 'id' param change in the route
  (newId, oldId) => {
    if (newId !== oldId) {
      getTerminData();
      selectedTermin.value = newId;
    }
  },
  { immediate: true } // Immediately run on component mount
);

const getProjectData = async () => {
  try {
    const response = await client.get(`/projects/${route.params.id}`);
    project.value = response.data;
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  getProjectData();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (termin.value.phases)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createBlock(TerminSideMenu, {
            onOnTerminSelect: onTerminSelect,
            "selected-termin": selectedTermin.value,
            "selected-phase": selectedPhase.value,
            phases: termin.value.phases,
            "is-loading": isLoading.value,
            key: termin.value
          }, null, 8, ["selected-termin", "selected-phase", "phases", "is-loading"]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (!isLoading.value)
        ? (_openBlock(), _createBlock(TerminVIew, {
            onRefresh: getTerminData,
            termin: termin.value,
            key: termin.value,
            "project-name": project.value?.name
          }, null, 8, ["termin", "project-name"]))
        : _createCommentVNode("", true),
      (isLoading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(SpinnerLoader)
          ]))
        : _createCommentVNode("", true)
    ]),
    (termin.value?.notion_id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("a", {
            href: `https://smalt.lightning.force.com/lightning/r/Appointment__c/${termin.value.notion_id}/view`,
            target: "_blank",
            class: "w-9 h-9"
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              class: "w-9 h-9 cursor-pointer",
              src: _imports_0,
              alt: ""
            }, null, -1)
          ]), 8, _hoisted_6)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})