import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/project-details-three-card-image-1.svg'
import _imports_1 from '@/assets/code-brackets.svg'
import _imports_2 from '@/assets/edit-copy.svg'
import _imports_3 from '@/assets/calendar-frame.svg'


const _hoisted_1 = { class: "my-3 grid grid-cols-1 md:grid-cols-3 gap-[12.5px] h-[103px]" }
const _hoisted_2 = { class: "flex flex-col gap-3 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]" }
const _hoisted_3 = { class: "text-lg leading-[22.2px] tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "flex flex-col gap-3 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]" }
const _hoisted_6 = {
  key: 1,
  class: "flex items-center justify-between"
}
const _hoisted_7 = {
  key: 0,
  class: "text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem] overflow-clip"
}
const _hoisted_8 = {
  key: 1,
  class: "text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem]"
}
const _hoisted_9 = { class: "flex flex-col gap-3 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]" }
const _hoisted_10 = { class: "flex items-center gap-2" }
const _hoisted_11 = {
  key: 0,
  class: "text-lg text-primary-text-color font-semibold"
}
const _hoisted_12 = {
  key: 1,
  class: "text-lg text-primary-text-color font-semibold"
}
const _hoisted_13 = {
  key: 2,
  class: "text-lg text-primary-text-color font-semibold"
}
const _hoisted_14 = {
  key: 1,
  class: "flex items-center justify-between gap-2"
}
const _hoisted_15 = {
  key: 0,
  class: "w-4/12 text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem] overflow-clip"
}
const _hoisted_16 = {
  key: 1,
  class: "w-8/12 text-right text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem] overflow-hidden text-nowrap text-ellipsis"
}
const _hoisted_17 = {
  key: 2,
  class: "w-4/12 text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem] overflow-hidden text-nowrap"
}
const _hoisted_18 = {
  key: 3,
  class: "w-8/12 text-right text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem] overflow-hidden text-nowrap text-ellipsis"
}
const _hoisted_19 = {
  key: 4,
  class: "text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem]"
}

import { ref } from "vue";
import InlineLoader from "@/components/Loaders/InlineLoader.vue";
type Props = {
  isLoading: boolean;
  customerName: string;
  meister: string;
  costCarrierCode: string;
  nextTermin: any;
  lastTermin: any;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ThreeBlockRow',
  props: {
    isLoading: { type: Boolean },
    customerName: {},
    meister: {},
    costCarrierCode: {},
    nextTermin: {},
    lastTermin: {}
  },
  setup(__props: any) {

const props = __props;

const copyText = () => {
  const text = props.costCarrierCode;
  navigator.clipboard.writeText(text);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex items-center gap-2" }, [
        _createElementVNode("div", { class: "flex items-center justify-center" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          })
        ]),
        _createElementVNode("h3", { class: "text-lg text-primary-text-color font-semibold" }, " Responsible Meister ")
      ], -1)),
      _createElementVNode("h4", _hoisted_3, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(InlineLoader, { key: 0 }))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(props.meister ? props.meister : ""), 1))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex items-center gap-2" }, [
        _createElementVNode("div", { class: "flex items-center justify-center" }, [
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          })
        ]),
        _createElementVNode("h3", { class: "text-lg text-primary-text-color font-semibold" }, " Cost Carrier Code ")
      ], -1)),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(InlineLoader, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.costCarrierCode)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.costCarrierCode), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_8, "-")),
            (_ctx.costCarrierCode)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  onClick: copyText,
                  class: "flex items-center justify-center hover:bg-gray-200 duration-300 p-1 rounded-2xl"
                }, _cache[1] || (_cache[1] = [
                  _createElementVNode("img", {
                    class: "cursor-pointer",
                    src: _imports_2,
                    alt: ""
                  }, null, -1)
                ])))
              : _createCommentVNode("", true)
          ]))
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex items-center justify-center" }, [
          _createElementVNode("img", {
            src: _imports_3,
            alt: ""
          })
        ], -1)),
        (!props.lastTermin && !props.nextTermin)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_11, " Appointments "))
          : _createCommentVNode("", true),
        (props.nextTermin)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_12, " Next appointment "))
          : _createCommentVNode("", true),
        (props.lastTermin)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_13, " Last appointment "))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(InlineLoader, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_14, [
            (props.nextTermin)
              ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(props.nextTermin
              ? new Date(props.nextTermin?.date).toLocaleDateString("DE")
              : "-"), 1))
              : _createCommentVNode("", true),
            (props.nextTermin)
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(props.nextTermin ? props.nextTermin?.name : "-"), 1))
              : _createCommentVNode("", true),
            (props.lastTermin && !props.nextTermin)
              ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(props.lastTermin
              ? new Date(props.lastTermin?.date).toLocaleDateString("DE")
              : "-"), 1))
              : _createCommentVNode("", true),
            (props.lastTermin && !props.nextTermin)
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(props.lastTermin ? props.lastTermin?.name : "-"), 1))
              : _createCommentVNode("", true),
            (!props.lastTermin && !props.nextTermin)
              ? (_openBlock(), _createElementBlock("span", _hoisted_19, "No confirmed appointments"))
              : _createCommentVNode("", true)
          ]))
    ])
  ]))
}
}

})