import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/pencil-icon.svg'


const _hoisted_1 = { class: "w-full p-3 bg-white rounded-primary-radius shadow-primary-box-shadow" }
const _hoisted_2 = { class: "flex flex-col items-start gap-2 -ml-[3px]" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "text-base leading-5 font-normal tracking-[0.01rem] text-primary-text-color" }
const _hoisted_5 = {
  key: 0,
  class: "text-sm font-sans font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]"
}



type Props = {
  step: any;
};

type Emits = {
  (event: "setSelectedInput", input: any): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MissingImageBlock',
  props: {
    step: {}
  },
  emits: ["setSelectedInput"],
  setup(__props: any) {

const props = __props;



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color pb-4 font-semibold" }, " Images ", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.step.inputs.filter((item)=> item.type === 'image' && item.value.length === 0), (input) => {
        return (_openBlock(), _createElementBlock("div", {
          key: input.id,
          onClick: ($event: any) => (_ctx.$emit('setSelectedInput', input)),
          class: "group border py-[3px] border-transparent hover:cursor-pointer hover:bg-[#F9F8F6] hover:border-[#E3E1EA] hover:rounded-[5px] w-full flex items-center gap-1"
        }, [
          _createElementVNode("h4", _hoisted_4, [
            (input.parentId !== null)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.step.inputs.find((i) => i.id === input.parentId).name) + " / ", 1))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(input.name), 1)
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            class: "w-3 h-3 mr-[5px] invisible group-hover:visible ml-auto",
            src: _imports_0,
            alt: "Icon"
          }, null, -1))
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}
}

})