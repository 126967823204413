import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = {
  key: 0,
  class: "flex"
}
const _hoisted_4 = {
  key: 1,
  class: "flex"
}
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["name", "onChange", "value"]
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = {
  key: 0,
  class: "py-5 px-2 border border-[#e3e1ea] flex items-center w-[50px] h-full"
}
const _hoisted_10 = ["name", "value", "onChange"]

import { computed } from "vue";

type Props = {
  title?: string;
  data?: any;
  tableName: string;
};
type Emits = {
  (
    event: "onChangeInput",
    tableName: string,
    rowId: any,
    inputId: any,
    value: any
  ): void;
  (
    event: "onChangeColumn",
    tableName: string,
    columnId: number,
    value: any
  ): void;
  (event: "addRow", tableName: string): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'VerticalDynamicTable',
  props: {
    title: {},
    data: {},
    tableName: {}
  },
  emits: ["onChangeInput", "onChangeColumn", "addRow"],
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.tableName !== 'stromkreis')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[200px] min-h-[44px] text-base font-semibold text-[#393840]" }, " Stromkreis ", -1),
            _createElementVNode("div", { class: "w-[900px] bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center min-h-[44px] text-base font-semibold text-[#393840]" }, " Fehlerstrom-Schutzeinrichtung (RCD) ", -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.tableName === 'stromkreis')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[200px] min-h-[44px] text-base font-semibold text-[#393840]" }, " Stromkreis ", -1),
            _createElementVNode("div", { class: "bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[300px] min-h-[44px] text-base font-semibold text-[#393840]" }, " Leitung/Kabel ", -1),
            _createElementVNode("div", { class: "w-[450px] bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center min-h-[44px] text-base font-semibold text-[#393840]" }, " Überstrom-Schutzeinrichtung ", -1),
            _createElementVNode("div", { class: "w-[150px] bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center min-h-[44px] text-base font-semibold text-[#393840]" }, " Riso (MΩ) ", -1)
          ])))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data[0], (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item,
            class: "flex"
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex items-center", `${index === 0 ? 'w-[50px]' : 'w-[150px]'} ${
              index !== 6 && index !== 7
                ? 'py-5 px-2 border border-[#e3e1ea]'
                : ''
            }`])
            }, [
              (index !== 6 && index !== 7)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.text), 1))
                : _createCommentVNode("", true),
              (index === 6 || index === 7)
                ? (_openBlock(), _createElementBlock("textarea", {
                    key: 1,
                    name: item.id,
                    onChange: (event)=> _ctx.$emit('onChangeColumn',_ctx.tableName,item.id, event.target.value),
                    value: item.text,
                    class: "resize-none py-5 px-2 border border-[#e3e1ea] flex items-center w-[150px] h-full"
                  }, null, 40, _hoisted_7))
                : _createCommentVNode("", true)
            ], 2)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (row, rowIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: row,
            class: "flex h-full"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (item, columnIndex) => {
              return (_openBlock(), _createElementBlock("div", { key: item }, [
                (columnIndex === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(rowIndex + 1), 1))
                  : _createCommentVNode("", true),
                (columnIndex !== 0)
                  ? (_openBlock(), _createElementBlock("input", {
                      key: 1,
                      name: item.id,
                      value: item.value,
                      onChange: (event)=> _ctx.$emit('onChangeInput',_ctx.tableName,rowIndex,item.id,event.target.value),
                      class: "py-5 px-2 border border-[#e3e1ea] flex items-center w-[150px] h-full"
                    }, null, 40, _hoisted_10))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", {
      class: "hover:cursor-pointer mt-4 w-[1100px] flex justify-center",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addRow', _ctx.tableName)))
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("svg", {
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", {
          d: "M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10Z",
          fill: "white"
        }),
        _createElementVNode("path", {
          d: "M6 10H10M10 10H14M10 10V14M10 10V6M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19Z",
          stroke: "#393840",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        })
      ], -1)
    ]))
  ]))
}
}

})