import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/arrow-left.svg'
import _imports_1 from '@/assets/arrow-right-gray.svg'
import _imports_2 from '@/assets/arrow-right.svg'
import _imports_3 from '@/assets/calendar-icon.svg'
import _imports_4 from '@/assets/users-icon.svg'


const _hoisted_1 = { class: "p-3 h-full rounded-primary-radius bg-[#E5E4E2] font-sans" }
const _hoisted_2 = { class: "h-[332px] overflow-hidden" }
const _hoisted_3 = { class: "flex items-start justify-between" }
const _hoisted_4 = { class: "flex items-start gap-2" }
const _hoisted_5 = { class: "w-6 h-6 flex items-center justify-center rounded-sm duration-200" }
const _hoisted_6 = {
  src: _imports_1,
  alt: "Arrow right"
}
const _hoisted_7 = {
  src: _imports_2,
  alt: "Arrow right"
}
const _hoisted_8 = { class: "flex flex-col items-start gap-[6px]" }
const _hoisted_9 = { class: "flex items-center gap-[6px]" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "mt-3 w-full flex flex-col items-start gap-[10px] h-[220px] overflow-y-auto" }
const _hoisted_12 = { class: "flex items-center justify-between mb-[18px]" }
const _hoisted_13 = { class: "text-sm tracking-[0.01rem] text-primary-text-color font-medium" }
const _hoisted_14 = { class: "flex items-center gap-1" }
const _hoisted_15 = { class: "flex items-center gap-[1px] text-xs" }
const _hoisted_16 = { class: "text-sm text-primary-text-color font-normal" }
const _hoisted_17 = { class: "text-sm text-primary-text-color font-normal" }
const _hoisted_18 = {
  key: 0,
  class: "flex items-start gap-1 mt-[6px]"
}
const _hoisted_19 = { class: "flex flex-col items-start gap-1" }
const _hoisted_20 = { key: 0 }

import { Project } from "@/types";
import { textTransform } from "@/utils/parse-document";
import { getProjectStatusColor } from "@/utils/status-color";
import { ref } from "vue";
import { useRoute } from "vue-router";

type Props = {
  projectDetails: Project | null;
};

type Emits = {
  (event: "showTimeline"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectAppointments',
  props: {
    projectDetails: {}
  },
  emits: ["showTimeline"],
  setup(__props: any, { emit: __emit }) {

const route = useRoute();
const projectId = route.params.id;

const isDisabled = ref(true);

const props = __props;
const emits = __emit;

const phaseFilter = ref("");
const onFilterHandler = (filter: any) => {
  phaseFilter.value = phaseFilter.value === filter ? null : filter;
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-xl pb-2 text-primary-text-color tracking-[0.01rem] font-semibold" }, " Appointments ", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('showTimeline'))),
            class: "w-6 h-6 flex items-center justify-center cursor-pointer rounded-sm duration-200"
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Arrow left"
            }, null, -1)
          ])),
          _createElementVNode("button", _hoisted_5, [
            _withDirectives(_createElementVNode("img", _hoisted_6, null, 512), [
              [_vShow, isDisabled.value]
            ]),
            _withDirectives(_createElementVNode("img", _hoisted_7, null, 512), [
              [_vShow, !isDisabled.value]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-sm text-primary-text-color pb-[6px] font-medium" }, " Filter by: ", -1)),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.projectDetails?.phases, (phase) => {
            return (_openBlock(), _createElementBlock("button", {
              key: phase.phaseId,
              onClick: ($event: any) => (onFilterHandler(phase.phase.toLowerCase())),
              class: _normalizeClass(["py-[2px] px-3 rounded-primary-radius text-sm tracking-[0.01rem] text-[#898790] font-medium cursor-pointer border border-[#898790]", 
              phaseFilter.value === phase.phase.toLowerCase()
                ? 'bg-white border-transparent text-primary-text-color'
                : ''
            ])
            }, _toDisplayString(phase?.phase), 11, _hoisted_10))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.projectDetails?.termins.filter((item)=> {
            if(!phaseFilter.value){
              return true;
            }else{
              return item.phase.toLowerCase() === phaseFilter.value.toLocaleLowerCase()
            }
          }), (termin) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "w-full py-[6px] px-[8px] bg-white rounded-primary-radius shadow-primary-box-shadow",
            key: termin.id
          }, [
            _createVNode(_component_RouterLink, {
              to: `/projects/${_unref(projectId)}/termin/${termin.id}`
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("h4", _hoisted_13, _toDisplayString(termin.name ? termin.name : "-"), 1),
                  _createElementVNode("span", {
                    class: "text-xs ml-2 tracking-[0.01rem] text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius",
                    style: _normalizeStyle(`background: ${_unref(getProjectStatusColor)(
                  termin?.status?.name
                )}`)
                  }, _toDisplayString(termin.status?.name ? _unref(textTransform)(termin.status?.name) : "-"), 5)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_14, [
                    _cache[5] || (_cache[5] = _createElementVNode("img", {
                      class: "w-[16px] h-[16px]",
                      src: _imports_3,
                      alt: "Calendar icon"
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("p", _hoisted_16, _toDisplayString(termin.start
                        ? new Date(termin.start).toLocaleDateString("DE")
                        : " "), 1),
                      _cache[4] || (_cache[4] = _createTextVNode(" - ")),
                      _createElementVNode("p", _hoisted_17, _toDisplayString(termin.end
                        ? new Date(termin.end).toLocaleDateString("DE")
                        : " "), 1)
                    ])
                  ]),
                  (termin && termin.workers)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _cache[6] || (_cache[6] = _createElementVNode("img", {
                          class: "w-[16px] h-[16px]",
                          src: _imports_4,
                          alt: "Users icon"
                        }, null, -1)),
                        _createElementVNode("div", _hoisted_19, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(termin.workers, (worker, index) => {
                            return (_openBlock(), _createElementBlock("p", {
                              class: "text-sm",
                              key: index
                            }, [
                              _createTextVNode(_toDisplayString(worker ? worker : "-"), 1),
                              (index < termin.workers?.length - 1)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_20, ", "))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})