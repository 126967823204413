import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, vModelRadio as _vModelRadio, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/close-icon-2.svg'
import _imports_1 from '@/assets/rounded-selected-checkbox.svg'


const _hoisted_1 = { class: "absolute overflow-y-auto top-5 w-[630px] p-6 mx-3 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 rounded-lg shadow-lg max-w-md" }
const _hoisted_2 = { class: "flex items-center justify-between border-b border-gray-300 pb-3" }
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = { class: "pointer-events-none w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] placeholder:text-primary-text-color" }
const _hoisted_5 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_6 = {
  key: 1,
  class: "mt-3"
}
const _hoisted_7 = {
  key: 2,
  class: "mt-3 flex flex-col gap-3"
}
const _hoisted_8 = { class: "flex w-5" }
const _hoisted_9 = ["id", "value"]
const _hoisted_10 = {
  class: "cursor-pointer",
  src: _imports_1,
  alt: ""
}
const _hoisted_11 = { key: 3 }

import client from "@/utils/axios";
import { reactive } from "vue";
import SelectMenu from "./SelectMenu.vue";
import { ref } from "vue";
import { errorToast } from "@/utils/custom-toast";

type Props = {
  input: any;
  isOpen: boolean;
  project: string | string[];
  phase: number;
  termins?: Array<any>;
};

type Emits = {
  (event: "toggleModal"): void;
  (event: "refresh"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InputModal',
  props: {
    input: {},
    isOpen: { type: Boolean },
    project: {},
    phase: {},
    termins: {}
  },
  emits: ["toggleModal", "refresh"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;
const props = __props;

const state = reactive({
  value:
    props.input.type === "select" && props.input.options && props.input.value
      ? props.input.options.find(
          (item: any) => item.option === props.input.value
        )?.id
      : props.input.value,
});

const options = props.termins?.map((item: any) => {
  return {
    name: item.name,
    id: item.id,
  };
});

const termin = ref(
  options?.find((item: any) => item.id === props.input.terminId)
);

const onSubmit = async () => {
  if (props.project && props.phase && props.input) {
    let payload = {
      terminId: props.input.terminId ? props.input.terminId : termin.value?.id,
      stepId: props.input.stepId,
      inputId: props.input.id,
      value:
        props.input.type === "select"
          ? props.input.options.find((item: any) => item.id === state.value)
              ?.option
          : state.value,
    };
    try {
      await client.post(
        `/projects/${props.project}/phase/${props.phase}/update-input`,
        payload
      );

      emits("refresh");
      emits("toggleModal");
    } catch (error: any) {
      errorToast(error);
    }
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fixed overflow-y-auto inset-0 bg-black bg-opacity-80 justify-center items-center z-50 transition-opacity duration-300 ease-in-out", `${_ctx.isOpen ? 'flex' : 'hidden'}`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold" }, " Input ", -1)),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('toggleModal')))
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Close icon"
          }, null, -1)
        ]))
      ]),
      _createElementVNode("form", {
        onSubmit: _withModifiers(onSubmit, ["prevent"])
      }, [
        _createElementVNode("div", null, [
          _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4" }, " Question ", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.input.name), 1)
          ])
        ]),
        _createElementVNode("div", null, [
          _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4" }, " Answer ", -1)),
          (_ctx.input.type === 'text')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.value) = $event)),
                  rows: "4",
                  class: "w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] resize-none placeholder:text-primary-text-color"
                }, null, 512), [
                  [_vModelText, state.value]
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.input.type === 'number')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((state.value) = $event)),
                  class: "w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] placeholder:text-primary-text-color",
                  type: "number"
                }, null, 512), [
                  [_vModelText, state.value]
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.input.type === 'select')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.input.options, (option) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: option.id,
                    class: "flex gap-2"
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((state.value) = $event)),
                        id: option.id,
                        value: option.id,
                        type: "radio",
                        class: "rounded-full ml-[3px] scale-150"
                      }, null, 8, _hoisted_9), [
                        [_vShow, state.value !== option.id],
                        [_vModelRadio, state.value]
                      ]),
                      _withDirectives(_createElementVNode("img", _hoisted_10, null, 512), [
                        [_vShow, state.value === option.id]
                      ])
                    ]),
                    _createElementVNode("label", null, _toDisplayString(option.option), 1)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (!_ctx.input.terminId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4" }, " On which appointment has the picture been taken? ", -1)),
                _createVNode(SelectMenu, {
                  options: _unref(options),
                  modelValue: termin.value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((termin).value = $event))
                }, null, 8, ["options", "modelValue"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex justify-end" }, [
          _createElementVNode("input", {
            type: "submit",
            class: "mt-5 flex items-center justify-center py-3 px-[48px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#28272c] duration-200",
            value: "Save"
          })
        ], -1))
      ], 32)
    ])
  ], 2))
}
}

})