<script setup lang="ts">
import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import client from "@/utils/axios";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import AbnahmeView from "./AbnahmeView.vue";
import QcView from "./QcView.vue";
import RegularView from "./RegularView.vue";

const route = useRoute();

const projectData = ref();
const phaseStatusName = ref("");
const isLoading = ref(false);

const selectedPhase = ref();

const onPhaseSelect = (phaseId: number) => {
  selectedPhase.value = phaseId;
};

// Fetch data
const getProjectDetails = async () => {
  const projectId = route.params.id;
  const phaseId = route.params.phaseId;
  isLoading.value = true;
  try {
    const response = await client.get(
      `/projects/${projectId}/phase/${phaseId}`
    );
    projectData.value = response.data;

    let tempPhase = response.data.phases.find(
      (item: any) => Number(item.phaseId) === Number(phaseId)
    );

    phaseStatusName.value = tempPhase ? tempPhase.phaseStatus.name : "";
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

watch(
  () => route.params.phaseId, // Watch for the 'id' param change in the route
  (newId, oldId) => {
    if (newId !== oldId) {
      getProjectDetails();
      selectedPhase.value = newId; // Trigger the API call when the route param changes
    }
  },
  { immediate: true } // Immediately run on component mount
);

//
</script>

<template>
  <MainLayout>
    <div class="flex">
      <div class="">
        <MainSidebar
          :is-loading="isLoading"
          :phase-progress="projectData?.phases"
          :phase-steps="projectData?.steps"
          :selected-phase="Number(selectedPhase)"
          @on-phase-select="onPhaseSelect"
          :is-link="true"
        />
      </div>
      <div class="w-[calc(100%-220px)]">
        <div class="flex w-full" v-if="projectData">
          <div class="flex w-full" v-if="phaseStatusName === 'abnahme'">
            <AbnahmeView
              :phase-id="Number(route.params.phaseId)"
              :project-id="route.params.id + ''"
              :phase-details="projectData"
              @refresh-data="getProjectDetails"
            />
          </div>
          <div class="flex w-full" v-if="phaseStatusName === 'qc'">
            <QcView
              :phase-id="Number(route.params.phaseId)"
              :project-id="route.params.id + ''"
              :phase-details="projectData"
              @refresh-data="getProjectDetails"
            />
          </div>

          <div
            class="flex w-full"
            v-if="phaseStatusName !== 'abnahme' && phaseStatusName !== 'qc'"
          >
            <RegularView
              :phase-id="Number(route.params.phaseId)"
              :phase-details="projectData"
              @refresh="getProjectDetails"
            />
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>
