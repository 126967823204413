<script setup lang="ts">
import client from "@/utils/axios";
import { defineEmits, defineProps, reactive } from "vue";
import SelectMenu from "./SelectMenu.vue";
import { ref } from "vue";
import { errorToast } from "@/utils/custom-toast";

type Props = {
  input: any;
  isOpen: boolean;
  project: string | string[];
  phase: number;
  termins?: Array<any>;
};

type Emits = {
  (event: "toggleModal"): void;
  (event: "refresh"): void;
};

const emits = defineEmits<Emits>();
const props = defineProps<Props>();

const state = reactive({
  value:
    props.input.type === "select" && props.input.options && props.input.value
      ? props.input.options.find(
          (item: any) => item.option === props.input.value
        )?.id
      : props.input.value,
});

const options = props.termins?.map((item: any) => {
  return {
    name: item.name,
    id: item.id,
  };
});

const termin = ref(
  options?.find((item: any) => item.id === props.input.terminId)
);

const onSubmit = async () => {
  if (props.project && props.phase && props.input) {
    let payload = {
      terminId: props.input.terminId ? props.input.terminId : termin.value?.id,
      stepId: props.input.stepId,
      inputId: props.input.id,
      value:
        props.input.type === "select"
          ? props.input.options.find((item: any) => item.id === state.value)
              ?.option
          : state.value,
    };
    try {
      await client.post(
        `/projects/${props.project}/phase/${props.phase}/update-input`,
        payload
      );

      emits("refresh");
      emits("toggleModal");
    } catch (error: any) {
      errorToast(error);
    }
  }
};
</script>

<template>
  <div
    class="fixed overflow-y-auto inset-0 bg-black bg-opacity-80 justify-center items-center z-50 transition-opacity duration-300 ease-in-out"
    :class="`${isOpen ? 'flex' : 'hidden'}`"
  >
    <div
      class="absolute overflow-y-auto top-5 w-[630px] p-6 mx-3 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 rounded-lg shadow-lg max-w-md"
    >
      <div
        class="flex items-center justify-between border-b border-gray-300 pb-3"
      >
        <h4
          class="text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold"
        >
          Input
        </h4>

        <button @click="emits('toggleModal')">
          <img src="@/assets/close-icon-2.svg" alt="Close icon" />
        </button>
      </div>
      <!-- Form -->
      <form @submit.prevent="onSubmit">
        <div>
          <h4
            class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4"
          >
            Question
          </h4>
          <div class="mt-3">
            <div
              class="pointer-events-none w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] placeholder:text-primary-text-color"
            >
              {{ input.name }}
            </div>
          </div>
        </div>
        <div>
          <h4
            class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4"
          >
            Answer
          </h4>
          <div v-if="input.type === 'text'" class="mt-3">
            <textarea
              v-model="state.value"
              rows="4"
              class="w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] resize-none placeholder:text-primary-text-color"
            ></textarea>
          </div>
          <div v-if="input.type === 'number'" class="mt-3">
            <input
              v-model="state.value"
              class="w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] placeholder:text-primary-text-color"
              type="number"
            />
          </div>
          <div v-if="input.type === 'select'" class="mt-3 flex flex-col gap-3">
            <div
              v-for="option in input.options"
              :key="option.id"
              class="flex gap-2"
            >
              <div class="flex w-5">
                <input
                  v-show="state.value !== option.id"
                  v-model="state.value"
                  :id="option.id"
                  :value="option.id"
                  type="radio"
                  class="rounded-full ml-[3px] scale-150"
                />
                <img
                  v-show="state.value === option.id"
                  class="cursor-pointer"
                  src="@/assets/rounded-selected-checkbox.svg"
                  alt=""
                />
              </div>
              <label>{{ option.option }}</label>
            </div>
          </div>
          <div v-if="!input.terminId">
            <h4
              class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4"
            >
              On which appointment has the picture been taken?
            </h4>
            <SelectMenu :options="options" v-model="termin" />
          </div>
        </div>
        <!-- Submit button -->
        <div class="flex justify-end">
          <input
            type="submit"
            class="mt-5 flex items-center justify-center py-3 px-[48px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#28272c] duration-200"
            value="Save"
          />
        </div>
      </form>
    </div>
  </div>
</template>
