<script setup lang="ts">
import { defineProps, ref } from "vue";
import InlineLoader from "@/components/Loaders/InlineLoader.vue";
type Props = {
  isLoading: boolean;
  customerName: string;
  meister: string;
  costCarrierCode: string;
  nextTermin: any;
  lastTermin: any;
};

const props = defineProps<Props>();

const copyText = () => {
  const text = props.costCarrierCode;
  navigator.clipboard.writeText(text);
};
</script>

<template>
  <div class="my-3 grid grid-cols-1 md:grid-cols-3 gap-[12.5px] h-[103px]">
    <div
      class="flex flex-col gap-3 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]"
    >
      <div class="flex items-center gap-2">
        <div class="flex items-center justify-center">
          <img src="@/assets/project-details-three-card-image-1.svg" alt="" />
        </div>
        <h3 class="text-lg text-primary-text-color font-semibold">
          Responsible Meister
        </h3>
      </div>
      <h4
        class="text-lg leading-[22.2px] tracking-[0.01rem] text-primary-text-color font-normal"
      >
        <InlineLoader v-if="isLoading" />
        <span v-else>
          {{ props.meister ? props.meister : "" }}
        </span>
      </h4>
    </div>
    <div
      class="flex flex-col gap-3 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]"
    >
      <div class="flex items-center gap-2">
        <div class="flex items-center justify-center">
          <img src="@/assets/code-brackets.svg" alt="" />
        </div>
        <h3 class="text-lg text-primary-text-color font-semibold">
          Cost Carrier Code
        </h3>
      </div>
      <InlineLoader v-if="isLoading" />
      <div class="flex items-center justify-between" v-else>
        <span
          v-if="costCarrierCode"
          class="text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem] overflow-clip"
          >{{ costCarrierCode }}</span
        >
        <span
          v-else
          class="text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem]"
          >-</span
        >
        <div
          v-if="costCarrierCode"
          @click="copyText"
          class="flex items-center justify-center hover:bg-gray-200 duration-300 p-1 rounded-2xl"
        >
          <img class="cursor-pointer" src="@/assets/edit-copy.svg" alt="" />
        </div>
      </div>
    </div>
    <div
      class="flex flex-col gap-3 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]"
    >
      <div class="flex items-center gap-2">
        <div class="flex items-center justify-center">
          <img src="@/assets/calendar-frame.svg" alt="" />
        </div>
        <h3
          v-if="!props.lastTermin && !props.nextTermin"
          class="text-lg text-primary-text-color font-semibold"
        >
          Appointments
        </h3>
        <h3
          v-if="props.nextTermin"
          class="text-lg text-primary-text-color font-semibold"
        >
          Next appointment
        </h3>
        <h3
          v-if="props.lastTermin"
          class="text-lg text-primary-text-color font-semibold"
        >
          Last appointment
        </h3>
      </div>
      <!-- Date -->
      <InlineLoader v-if="isLoading" />
      <div v-else class="flex items-center justify-between gap-2">
        <span
          v-if="props.nextTermin"
          class="w-4/12 text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem] overflow-clip"
          >{{
            props.nextTermin
              ? new Date(props.nextTermin?.date).toLocaleDateString("DE")
              : "-"
          }}</span
        >
        <span
          class="w-8/12 text-right text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem] overflow-hidden text-nowrap text-ellipsis"
          v-if="props.nextTermin"
          >{{ props.nextTermin ? props.nextTermin?.name : "-" }}</span
        >
        <span
          v-if="props.lastTermin && !props.nextTermin"
          class="w-4/12 text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem] overflow-hidden text-nowrap"
          >{{
            props.lastTermin
              ? new Date(props.lastTermin?.date).toLocaleDateString("DE")
              : "-"
          }}</span
        >
        <span
          class="w-8/12 text-right text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem] overflow-hidden text-nowrap text-ellipsis"
          v-if="props.lastTermin && !props.nextTermin"
          >{{ props.lastTermin ? props.lastTermin?.name : "-" }}</span
        >
        <span
          v-if="!props.lastTermin && !props.nextTermin"
          class="text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem]"
          >No confirmed appointments</span
        >
      </div>
    </div>
  </div>
</template>
