import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/close-icon-2.svg'


const _hoisted_1 = { class: "absolute overflow-y-auto top-5 w-[630px] p-6 mx-3 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 rounded-lg shadow-lg max-w-md" }
const _hoisted_2 = { class: "flex items-center justify-between border-b border-gray-300 pb-3" }
const _hoisted_3 = { class: "w-11/12 text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "mt-3"
}
const _hoisted_7 = {
  key: 3,
  class: "flex w-full overflow-x-auto gap-1 mt-6 whitespace-nowrap scrollable-container"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "flex items-center gap-2 mt-[36px]" }
const _hoisted_10 = { class: "flex justify-end" }
const _hoisted_11 = {
  key: 0,
  class: "w-8 h-8 border-4 border-white border-t-transparent rounded-full animate-spin"
}
const _hoisted_12 = { key: 1 }

import client from "@/utils/axios";
import SelectMenu from "./SelectMenu.vue";
import { ref, computed, watch } from "vue";
import { errorToast, successToast } from "@/utils/custom-toast";

type Props = {
  steps: Array<any>;
  termins?: Array<any>;
  isOpen?: boolean;
  project: string | string[];
  phase: number;
  step?: any;
  input?: any;
  headerTitle: string;
};

type Emits = {
  (event: "toggleModal"): void;
  (event: "refresh"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'AddImageModal',
  props: {
    steps: {},
    termins: {},
    isOpen: { type: Boolean },
    project: {},
    phase: {},
    step: {},
    input: {},
    headerTitle: {}
  },
  emits: ["toggleModal", "refresh"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const selectedStep = ref<any>(
  props.step
    ? {
        id: props.step.id,
        name: props.step.name,
      }
    : null
);
const selectedInput = ref<any>(
  props.input
    ? {
        id: props.input.id,
        name: props.input.name,
      }
    : null
);
const selectedTermin = ref<any>(null);
const description = ref<any>("");
const files = ref([]);
const isLoading = ref(false);

const handleFileChange = (event: any) => {
  files.value = Array.from(event.target.files);
  imagePreviews.value = files.value.map((file: any) =>
    URL.createObjectURL(file)
  );
};

const imagePreviews = ref<string[]>([]);

const steps = computed(() => {
  let stepsWithImage = props.steps.filter((item: any) =>
    item.inputs.some((input: any) => input.type === "image" && !item.parentId)
  );
  return [...stepsWithImage, { id: -1, name: "Other" }];
});

const inputs = computed(() => {
  let tempSelectedStep = props.steps.find(
    (item: any) => item.id === selectedStep.value?.id
  );

  return selectedStep.value && selectedStep.value?.id !== -1
    ? tempSelectedStep.inputs
        .filter((item: any) => item.type === "image" && !item.parentId)
        .map((item: any) => {
          return {
            id: item.id,
            name: item.name,
          };
        })
    : [];
});

const onSubmit = async () => {
  if (!files.value) {
    errorToast("Please upload image!");
    return;
  }

  if (!selectedStep.value) {
    errorToast("Please select step!");
    return;
  }

  if (!selectedTermin.value) {
    errorToast("Please select step!");
    return;
  }
  isLoading.value = true;

  const formData = new FormData();
  formData.append("isNote", selectedStep.value.id === -1 ? "true" : "false");
  formData.append("terminId", selectedTermin.value.id);

  if (selectedStep.value.id === -1) {
    formData.append("description", description.value);
  } else {
    formData.append("stepId", selectedStep.value.id);
    formData.append("inputId", selectedInput.value.id);
  }

  files.value.forEach((file, index) => {
    formData.append(`files[${index}]`, file);
  });
  try {
    await client.post(
      `/projects/${props.project}/phase/${props.phase}/add-picture`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    isLoading.value = false;
    successToast("Successfully added images.");
    emits("refresh");
    emits("toggleModal");
  } catch (error: any) {
    errorToast(error);
  }
};

watch(
  () => selectedStep.value,
  () => {
    if (!props.input) {
      selectedInput.value = null;
    }
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fixed overflow-y-auto inset-0 bg-black bg-opacity-80 justify-center items-center z-50 transition-opacity duration-300 ease-in-out", `${_ctx.isOpen ? 'flex' : 'hidden'}`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.headerTitle), 1),
        _createElementVNode("button", {
          class: "w-1/12 flex justify-center",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('toggleModal')))
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Close icon"
          }, null, -1)
        ]))
      ]),
      _createElementVNode("form", {
        onSubmit: _withModifiers(onSubmit, ["prevent"])
      }, [
        (!_ctx.input && !_ctx.step)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4" }, " Which installation step? ", -1)),
              _createElementVNode("div", null, [
                _createVNode(SelectMenu, {
                  modelValue: selectedStep.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedStep).value = $event)),
                  options: steps.value.map((item)=>{
              return{
                id:item.id,
                name:item.name
              }
            })
                }, null, 8, ["modelValue", "options"])
              ])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.input && !_ctx.step && inputs.value && inputs.value.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4" }, " Which description? ", -1)),
              _createElementVNode("div", null, [
                _createVNode(SelectMenu, {
                  modelValue: selectedInput.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedInput).value = $event)),
                  options: inputs.value
                }, null, 8, ["modelValue", "options"])
              ])
            ]))
          : _createCommentVNode("", true),
        (selectedStep.value && selectedStep.value.id === -1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4" }, " Which description? ", -1)),
              _withDirectives(_createElementVNode("textarea", {
                rows: "4",
                class: "w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] resize-none placeholder:text-primary-text-color",
                placeholder: "Description",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((description).value = $event))
              }, null, 512), [
                [_vModelText, description.value]
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4" }, " On which appointment has the picture been taken? ", -1)),
          _createElementVNode("div", null, [
            _createVNode(SelectMenu, {
              modelValue: selectedTermin.value,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedTermin).value = $event)),
              options: _ctx.termins?.map((item)=>{
                return {
                  id:item.id,
                  name:item.name
                }
              })
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        (imagePreviews.value.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(imagePreviews.value, (src, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "shrink-0 w-[123px] h-[123px]"
                }, [
                  _createElementVNode("img", {
                    src: src,
                    alt: "Uploaded image",
                    class: "shrink-0 object-cover w-[123px] h-[123px] rounded-md"
                  }, null, 8, _hoisted_8)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _cache[10] || (_cache[10] = _createElementVNode("label", {
            for: "file",
            class: "px-4 py-2 text-[#393840] font-medium rounded-md cursor-pointer transition flex items-center border-2 border-[#393840] w-fit hover:bg-[#F9F8F6]"
          }, [
            _createElementVNode("svg", {
              width: "15",
              height: "16",
              viewBox: "0 0 15 16",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M1.40186 7.59852L6.57257 2.4278C8.11026 0.890114 10.6034 0.890114 12.1411 2.4278C13.6788 3.96549 13.6786 6.45872 12.1409 7.99641L6.17468 13.9626C5.14956 14.9877 3.48778 14.9876 2.46265 13.9625C1.43753 12.9373 1.43728 11.2755 2.4624 10.2503L8.42862 4.28413C8.94118 3.77156 9.77265 3.77156 10.2852 4.28413C10.7978 4.79669 10.7974 5.62752 10.2849 6.14008L5.11414 11.3108",
                stroke: "#393840",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              })
            ]),
            _createTextVNode(" Attach Files ")
          ], -1)),
          _createElementVNode("input", {
            multiple: "",
            class: "hidden",
            type: "file",
            id: "file",
            onChange: handleFileChange
          }, null, 32)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            onClick: onSubmit,
            class: "mt-5 flex items-center justify-center py-3 px-[56px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#28272c] duration-200"
          }, [
            (isLoading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11))
              : (_openBlock(), _createElementBlock("span", _hoisted_12, "Save"))
          ])
        ])
      ], 32)
    ])
  ], 2))
}
}

})