<script setup lang="ts"></script>

<template>
  <div
    class="w-full h-screen flex bg-[#EFEEEC] py-[33px] px-6 font-sans overflow-y-hidden"
  >
    <div class="w-full h-full">
      <slot />
    </div>
  </div>
</template>
