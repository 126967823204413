<script lang="ts" setup>
import "/custom.css";
import MainLayout from "@/layouts/MainLayout.vue";
import MainContent from "@/components/MainPage/MainContent.vue";
import { computed, ref, onMounted } from "vue";
import client from "@/utils/axios";
import { textTransform } from "@/utils/parse-data";
import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import { Status } from "@/types";
import SpinnerLoader from "@/components/Loaders/SpinnerLoader.vue";
import { useUserStore } from "@/stores/user";

const isSelectedMenu = ref(false);
const searchQuery = ref("");
const isLoading = ref(false);
const userStore = useUserStore();

const isSelectMenuHandler = () => {
  isSelectedMenu.value = !isSelectedMenu.value;
};

// Fetch data
const allStatuses = ref<Status[]>([]);
const aLLProjects = ref<any>([]);
const activeFilter = ref<any>();
const selectedStatuses = ref<Status[]>([]);

const getAllStatuses = async () => {
  try {
    const response = await client.get("/project-statuses");
    if (response.data) {
      let statuses = response.data.map((item: any) => {
        return {
          ...item,
          isSelected: true,
          isOpen: true,
        };
      });

      const userFilters = userStore.filters;
      if (userFilters.length) {
        statuses = statuses.map((status: any) => {
          if (userFilters.includes(status.name)) {
            status.isSelected = true;
          } else {
            status.isSelected = false;
          }

          return status;
        });
      }

      allStatuses.value = statuses;
      selectedStatuses.value = statuses.filter(
        (status: any) => status.isSelected
      );
    }
  } catch (error) {
    console.log(error);
  }
};

const getAllProjects = async () => {
  isLoading.value = true;

  try {
    const response = await client.get("/projects");
    if (response.data) {
      aLLProjects.value = response.data;
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

const toggleStatusOpen = (statusId: number) => {
  const status = allStatuses.value.find(
    (status: any) => status.id === statusId
  );

  if (status) {
    status.isOpen = !status.isOpen;
  }
};

onMounted(() => {
  getAllStatuses();
  getAllProjects();
});

const filteredProjects = computed(() => {
  let results = aLLProjects.value;
  switch (activeFilter.value) {
    case "today":
      {
        results = results.filter((project: any) => {
          const currentDateFull = new Date();
          const currentDate = new Date(
            currentDateFull.getFullYear(),
            currentDateFull.getMonth(),
            currentDateFull.getDate()
          );
          let match = false;

          project.termins?.forEach((termin: any) => {
            let start = null;
            let end = null;

            if (termin.start) {
              start = new Date(termin.start);
              start?.setHours(0, 0, 0, 0);
            }
            if (termin.end) {
              end = new Date(termin.end);
              end?.setHours(0, 0, 0, 0);
            }

            const projectStartToday = start
              ? start >= currentDate && start <= currentDate
              : false;

            const projectEndToday = end
              ? end >= currentDate && end <= currentDate
              : false;

            const projectInProgress =
              start && end ? start < currentDate && end > currentDate : false;

            if (projectStartToday || projectEndToday || projectInProgress) {
              match = true;
            }
          });

          return match;
        });
      }
      break;
    case "week":
      results = results.filter((project: any) => {
        const currentDateFull = new Date();
        const currentDate = new Date(
          currentDateFull.getFullYear(),
          currentDateFull.getMonth(),
          currentDateFull.getDate()
        );
        let match = false;

        project.termins?.forEach((termin: any) => {
          let start = null;
          let end = null;

          if (termin.start) {
            start = new Date(termin.start);
            start?.setHours(0, 0, 0, 0);
          }
          if (termin.end) {
            end = new Date(termin.end);
            end?.setHours(0, 0, 0, 0);
          }

          const day = currentDate.getDay();
          const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
          const monday = new Date(currentDate.setDate(diff));
          const sunday = new Date(currentDate.setDate(diff + 6));

          const projectStartThisWeek = start
            ? start >= monday && start <= sunday
            : false;

          const projectEndThisWeek = end
            ? end >= monday && end <= sunday
            : false;

          const projectInProgress =
            start && end ? start < currentDate && end > currentDate : false;

          if (projectStartThisWeek || projectEndThisWeek || projectInProgress) {
            match = true;
          }
        });

        return match;
      });
      break;
    case "abnahme":
      results = results.filter((project: any) => {
        return (
          project.phases?.filter((item: any) => item.status.name === "abnahme")
            .length > 0
        );
      });
      break;
    case "qc":
      results = results.filter((project: any) => {
        return (
          project.phases?.filter((item: any) => item.status.name === "qc")
            .length > 0
        );
      });
      break;
  }

  if (searchQuery.value) {
    results = results.filter((query: any) => {
      return query.name.toLowerCase().includes(searchQuery.value.toLowerCase());
    });
  }

  return results;
});

const setActiveFilter = (filter: string) => {
  if (activeFilter.value === filter) {
    activeFilter.value = null;
  } else {
    activeFilter.value = filter;
  }
};

const onSaveSelectedStatuses = () => {
  selectedStatuses.value = allStatuses.value.filter(
    (status: any) => status.isSelected
  );
  // make API request to save selected statuses

  const activeFilterNames = selectedStatuses.value.map(
    (status: any) => status.name
  );

  client.post(`/user/save-filters`, {
    filters: activeFilterNames,
  });

  userStore.setFilters(activeFilterNames);

  isSelectMenuHandler();
};

const onResetSelectedStatuses = () => {
  allStatuses.value.forEach((status: any) => {
    status.isSelected = true;
  });

  selectedStatuses.value = [...allStatuses.value];
};
</script>
<template>
  <MainLayout>
    <div class="flex items-start">
      <div class="w-[146px]">
        <MainSidebar />
      </div>
      <div class="w-full flex flex-col items-center">
        <!-- Header -->
        <div
          class="w-full flex flex-col gap-5 mb-6 lg:flex-row items-center justify-center lg:items-start lg:justify-between"
        >
          <div class="hidden sm:block"></div>
          <div>
            <div class="flex flex-wrap items-center justify-center gap-3">
              <!-- Header menu items -->
              <button
                @click="setActiveFilter('today')"
                id="today"
                class="flex gap-1 items-center p-2 cursor-pointer rounded-md hover:bg-white duration-300"
                :class="
                  activeFilter === 'today' ? 'bg-white' : 'bg-transparent'
                "
              >
                <img src="@/assets/sun-icon.svg" alt="Sun icon" />
                <p class="text-sm font-normal text-primary-text-color">Today</p>
              </button>
              <button
                @click="setActiveFilter('week')"
                id="week"
                class="flex gap-1 items-center p-2 cursor-pointer rounded-md hover:bg-white duration-300"
                :class="activeFilter === 'week' ? 'bg-white' : 'bg-transparent'"
              >
                <img src="@/assets/this-week-icon.svg" alt="Sun icon" />
                <p class="text-sm font-normal text-primary-text-color">
                  This week
                </p>
              </button>
              <button
                @click="setActiveFilter('abnahme')"
                id="abnahme"
                class="flex gap-1 items-center p-2 cursor-pointer rounded-md hover:bg-white duration-300"
                :class="
                  activeFilter === 'abnahme' ? 'bg-white' : 'bg-transparent'
                "
              >
                <img src="@/assets/file-icon.svg" alt="Sun icon" />
                <p class="text-sm font-normal text-primary-text-color">
                  Abnahme ready
                </p>
              </button>
              <button
                @click="setActiveFilter('qc')"
                id="qc"
                class="flex gap-1 items-center p-2 cursor-pointer rounded-md hover:bg-white duration-300"
                :class="activeFilter === 'qc' ? 'bg-white' : 'bg-transparent'"
              >
                <img src="@/assets/double-play.svg" alt="Sun icon" />
                <p class="text-sm font-normal text-primary-text-color">
                  QC ready
                </p>
              </button>
              <!-- Search -->
              <form
                class="w-[122px] flex items-center gap-2 px-2 bg-white rounded-lg"
              >
                <img src="@/assets/search-icon.svg" alt="" />
                <input
                  v-model="searchQuery"
                  class="w-full h-9 text-sm text-normal bg-transparent focus:outline-none"
                  type="text"
                  placeholder="Search"
                />
              </form>
            </div>
          </div>
          <!-- Filter -->
          <div class="flex items-center gap-[30px] relative">
            <div class="custom-select">
              <div @click="isSelectMenuHandler" class="selected">
                <p class="text-sm text-primary-text-color font-normal">
                  Filter status
                </p>
                <div>
                  <img
                    class="duration-200"
                    :class="isSelectedMenu ? '-rotate-180' : 'rotate-0'"
                    src="@/assets/chevron-down.svg"
                    alt=""
                  />
                </div>
              </div>
              <div
                v-if="isSelectedMenu"
                class="w-full h-fit relative top-0 right-0 bottom-0 left-0 z-[777] bg-black/25 max-h-[100vh]"
              >
                <div
                  class="w-[307px] max-h-[calc(100vh-100px)] absolute flex flex-col gap-3 top-0 left-[-90px] z-50 min-w-[170px] bg-white p-4 rounded-lg shadow-sm"
                >
                  <div class="flex items-start justify-between mb-1">
                    <h4
                      class="text-lg leading-[20px] text-primary-text-color tracking-[0.01rem] font-semibold"
                    >
                      Filter status
                    </h4>
                    <img
                      @click="isSelectMenuHandler"
                      src="@/assets/close-icon-2.svg"
                      alt="Close"
                    />
                  </div>
                  <div class="flex flex-col gap-1 overflow-y-auto">
                    <div
                      v-for="column in allStatuses"
                      :key="column.id"
                      class="select-items flex flex-col"
                    >
                      <label>
                        <p
                          class="w-full flex items-center gap-2 p-2 rounded-primary-radius text-sm text-primary-text-color font-medium cursor-pointer"
                          :class="
                            column.isSelected
                              ? ' bg-[#DFDEDC]'
                              : 'bg-[#F9F8F6] '
                          "
                        >
                          <input
                            type="checkbox"
                            v-model="column.isSelected"
                            :class="column.isSelected ? 'hidden' : 'block'"
                          />
                          <img
                            v-if="column.isSelected"
                            src="@/assets/checkbox-checked.svg"
                            alt=""
                          />
                          {{ textTransform(column.name) }}
                        </p>
                      </label>
                    </div>
                  </div>

                  <!-- Controls -->
                  <div
                    class="flex items-center justify-between pt-3 border-t border-gray-200"
                  >
                    <button
                      @click="onResetSelectedStatuses"
                      class="text-base leading-5 tracking-[0.01rem] text-primary-text-color font-semibold p-2 rounded-primary-radius border-2 border-[#393840]"
                    >
                      Reset
                    </button>
                    <button
                      @click="onSaveSelectedStatuses"
                      class="text-base leading-5 tracking-[0.01rem] text-white font-semibold p-2 rounded-primary-radius border-2 border-[#393840] bg-[#393840]"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Refresh projects -->
            <div>
              <button
                @click="getAllProjects"
                class="flex items-center gap-2 bg-transparent outline-none p-2 cursor-pointer rounded-md hover:bg-white duration-300"
              >
                <img
                  src="@/assets/refresh-icon.svg"
                  alt=""
                  :class="isLoading ? 'animate-spin' : 'animate-none'"
                />
                <p class="text-sm text-primary-text-color font-normal">
                  Refresh
                </p>
              </button>
            </div>
          </div>
        </div>
        <div v-if="isLoading" class="mt-28">
          <SpinnerLoader />
        </div>
        <div class="h-[calc(100vh-125px)] overflow-y-auto self-center" v-else>
          <MainContent
            :status-list="selectedStatuses"
            :project-list="filteredProjects"
            @toggle-status-open="toggleStatusOpen"
          />
        </div>
      </div>
    </div>
  </MainLayout>
</template>
