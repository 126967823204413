import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/pencil-icon.svg'
import _imports_1 from '@/assets/close-icon-2.svg'


const _hoisted_1 = { class: "fixed inset-0 bg-black bg-opacity-80 justify-center items-center z-50 transition-opacity duration-300 ease-in-out p-4" }
const _hoisted_2 = { class: "max-w-[683px] h-[calc(100vh-32px)] overflow-hidden p-6 mx-auto rounded-primary-radius bg-[#FFFFFF]" }
const _hoisted_3 = { class: "flex items-center justify-between pb-[16px] border-b border-[#E3E1EA]" }
const _hoisted_4 = { class: "flex items-center justify-start gap-2" }
const _hoisted_5 = { class: "text-[14px] tracking-[0.01rem] text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius bg-[#A7A5AE]" }
const _hoisted_6 = { class: "h-[calc(100vh-202px)] overflow-y-auto flex flex-col justify-between" }
const _hoisted_7 = { class: "mt-2" }
const _hoisted_8 = { class: "flex flex-col gap-2" }
const _hoisted_9 = ["name", "value", "onChange", "placeholder"]
const _hoisted_10 = { class: "mt-[40px]" }
const _hoisted_11 = { class: "flex flex-col gap-2" }
const _hoisted_12 = {
  key: 0,
  class: "flex flex-col gap-2"
}
const _hoisted_13 = { class: "w-[150px] bg-[#f9f8f6] border border-[#e3e1ea] rounded-l p-3 flex items-center" }
const _hoisted_14 = ["name", "value", "onChange"]
const _hoisted_15 = { class: "flex flex-col gap-2" }
const _hoisted_16 = { class: "w-[150px] bg-[#f9f8f6] border border-[#e3e1ea] rounded-l p-3 flex items-center" }
const _hoisted_17 = ["name", "value", "onChange"]
const _hoisted_18 = { class: "w-full flex items-center justify-end mt-3 pt-3 border-t border-[#E3E1EA]" }
const _hoisted_19 = { class: "flex items-center gap-4" }
const _hoisted_20 = ["disabled"]
const _hoisted_21 = ["disabled"]
const _hoisted_22 = {
  key: 0,
  class: "w-6 h-6 border-4 border-white border-t-transparent rounded-full animate-spin"
}
const _hoisted_23 = { key: 1 }

import InputChecklist from "@/components/Re-usable/InputChecklist.vue";
import SelectMenu from "@/components/Re-usable/SelectMenu.vue";
import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";
import { getStatusByName } from "@/utils/report-status";
import { ref } from "vue";

type Props = {
  termin: any;
  data: any;
};

type Emits = {
  (event: "close"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'AbnameProtocolModal',
  props: {
    termin: {},
    data: {}
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;
const state = ref(props.data);
const isLoading = ref(false);
const isReportLoading = ref(false);

const selectedVorbehalt = ref({
  id: state.value.vorbehalt,
  name: state.value.vorbehalt,
});

const onChangeInput = (name: any, value: any, propertyName?: string) => {
  if (propertyName) {
    let inputIndex = state.value[propertyName].findIndex(
      (item: any) => item.id === Number(name)
    );

    let tempList = [...state.value[propertyName]];
    if (inputIndex != -1) {
      tempList[inputIndex].value = value;
      state.value[propertyName] = tempList;
    }
  } else {
    state.value[name] = value;
  }
};

const addNewInputString = (propertyName: string) => {
  let lastInput =
    state.value[propertyName][state.value[propertyName].length - 1];
  state.value[propertyName].push({
    id: lastInput.id + 1,
    value: "",
  });
};

const onSubmit = async () => {
  isLoading.value = true;
  try {
    state.value.vorbehalt = selectedVorbehalt.value
      ? selectedVorbehalt.value.id
      : null;
    let resp = await client.post(
      `/termin/${props.termin.id}/save-abnahmeprotokoll-report`,
      {
        data: state.value,
      }
    );

    state.value = resp.data.report;
    isLoading.value = false;
    successToast("Successfully saved report.");
  } catch (error: any) {
    isLoading.value = false;
    errorToast(error);
  }
};

const generateReport = async () => {
  isReportLoading.value = true;
  successToast("Your report is being generated, please wait.");
  try {
    await client.post(
      `/termin/${props.termin.id}/generate-abnahmeprotokoll-report`,
      {
        data: state.value,
      }
    );
    isReportLoading.value = false;
    successToast("Successfully generated report.");
  } catch (error: any) {
    isReportLoading.value = false;
    errorToast(error);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("img", {
            src: _imports_0,
            alt: "Icon"
          }, null, -1)),
          _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "text-primary-text-color text-xl leading-[32px] font-semibold tracking-[0.01rem]" }, " Abnahmeprotokoll ", -1)),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(getStatusByName)(state.value?.status)), 1)
        ]),
        _createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('close'))),
          class: "cursor-pointer",
          src: _imports_1,
          alt: "Close icon"
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(InputChecklist, {
              title: "Folgende Leistungen sind Vertragsbestandteil:",
              "checklist-name": "folgende_leistungen",
              data: state.value?.folgende_leistungen,
              onOnChangeInput: onChangeInput
            }, null, 8, ["data"])
          ]),
          _createElementVNode("div", null, [
            _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "text-primary-text-color text-xl leading-[32px] pb-2 font-semibold tracking-[0.01rem]" }, " Stringmessung wurde durchgeführt ", -1)),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.value.stringmessung, (string, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: string.id
                }, [
                  _createElementVNode("input", {
                    name: string.id,
                    class: "w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]",
                    type: "text",
                    value: string.value,
                    onChange: 
                    (event) =>
                      onChangeInput(
                        string.id,
                        event.target.value,
                        'stringmessung'
                      )
                  ,
                    placeholder: `String ${index + 1} VDC:`
                  }, null, 40, _hoisted_9)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", {
              class: "hover:cursor-pointer mx-auto mt-3 flex justify-center",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (addNewInputString('stringmessung')))
            }, _cache[7] || (_cache[7] = [
              _createElementVNode("svg", {
                width: "20",
                height: "20",
                viewBox: "0 0 20 20",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }, [
                _createElementVNode("path", {
                  d: "M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10Z",
                  fill: "white"
                }),
                _createElementVNode("path", {
                  d: "M6 10H10M10 10H14M10 10V14M10 10V6M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19Z",
                  stroke: "#393840",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                })
              ], -1)
            ]))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[12] || (_cache[12] = _createElementVNode("h4", { class: "text-primary-text-color text-xl leading-[32px] pb-2 font-semibold tracking-[0.01rem]" }, " Für die Installation einer Photovoltaikanlage mit den oben genannten ergänzenden Leistungen erfolgt hiermit die Abnahme ", -1)),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(SelectMenu, {
                modelValue: selectedVorbehalt.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedVorbehalt).value = $event)),
                options: _ctx.data.vorbehalt_options.map((item)=>{
                return {
                  id:item,
                  name:item
                }
              })
              }, null, 8, ["modelValue", "options"]),
              (selectedVorbehalt.value?.id === 'mit Vorbehalt')
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-base text-[#898790]" }, " Sofern vorhanden, Nennung der Vorbehalte: ", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.value.mit_vorbehalt, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id,
                        class: "flex"
                      }, [
                        _createElementVNode("div", _hoisted_13, _toDisplayString(item.id) + ". ", 1),
                        _createElementVNode("input", {
                          name: item.id,
                          class: "w-full bg-white text-base font-medium text-[#898790] outline-none p-3 border border-[#E3E1EA] rounded-r",
                          type: "text",
                          value: item.value,
                          onChange: 
                    (event) =>
                      onChangeInput(
                        item.id,
                        event.target.value,
                        'mit_vorbehalt'
                      )
                  
                        }, null, 40, _hoisted_14)
                      ]))
                    }), 128)),
                    _createElementVNode("div", {
                      class: "hover:cursor-pointer mx-auto mt-3 flex justify-center",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (addNewInputString('mit_vorbehalt')))
                    }, _cache[9] || (_cache[9] = [
                      _createElementVNode("svg", {
                        width: "20",
                        height: "20",
                        viewBox: "0 0 20 20",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          d: "M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10Z",
                          fill: "white"
                        }),
                        _createElementVNode("path", {
                          d: "M6 10H10M10 10H14M10 10V14M10 10V6M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19Z",
                          stroke: "#393840",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        })
                      ], -1)
                    ]))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _cache[13] || (_cache[13] = _createElementVNode("p", { class: "text-base text-[#898790] mt-3 mb-2" }, " Folgende Arbeiten wurden über den Leistungsumfang hinaus vereinbart und nachträglich durchgeführt oder vor Ort nach Rücksprache mit dem Kunden vereinbart: Die Kosten für diese Arbeiten sind nicht im Angebot aufgeführt und werden separat in Rechnung gestellt. ", -1)),
            _createElementVNode("div", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.value.table_2, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "flex"
                }, [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(item.id) + ". ", 1),
                  _createElementVNode("input", {
                    name: item.id,
                    class: "w-full bg-white text-base font-medium text-[#898790] outline-none p-3 border border-[#E3E1EA] rounded-r",
                    type: "text",
                    value: item.value,
                    onChange: 
                    (event) =>
                      onChangeInput(
                        item.id,
                        event.target.value,
                        'table_2'
                      )
                  
                  }, null, 40, _hoisted_17)
                ]))
              }), 128)),
              _createElementVNode("div", {
                class: "hover:cursor-pointer mx-auto mt-3 flex justify-center",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (addNewInputString('table_2')))
              }, _cache[11] || (_cache[11] = [
                _createElementVNode("svg", {
                  width: "20",
                  height: "20",
                  viewBox: "0 0 20 20",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10Z",
                    fill: "white"
                  }),
                  _createElementVNode("path", {
                    d: "M6 10H10M10 10H14M10 10V14M10 10V6M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19Z",
                    stroke: "#393840",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  })
                ], -1)
              ]))
            ]),
            _cache[14] || (_cache[14] = _createElementVNode("p", { class: "text-base text-[#898790] mt-2" }, " Hiermit wird bestätigt, dass die Photovoltaikanlage erst in Betrieb gehen darf, nachdem der Wechsel des alten Zählers durch einen Zweirichtungszähler durchgeführt wurde bzw. nach Zustimmung des Netzbetreibers. ", -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("button", {
            disabled: isReportLoading.value,
            onClick: generateReport,
            class: "flex items-center gap-1 py-3 px-[16px] text-base text-primary-text-color bg-transparent tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer duration-200"
          }, [
            (_openBlock(), _createElementBlock("svg", {
              width: "14",
              height: "16",
              viewBox: "0 0 14 16",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
              class: _normalizeClass(`${isReportLoading.value ? 'animate-spin' : ''}`)
            }, _cache[15] || (_cache[15] = [
              _createElementVNode("path", {
                d: "M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179",
                stroke: "#393840",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1)
            ]), 2)),
            _cache[16] || (_cache[16] = _createTextVNode(" Generate report "))
          ], 8, _hoisted_20),
          _createElementVNode("button", {
            disabled: isLoading.value,
            onClick: onSubmit,
            class: "w-[150px] flex items-center justify-center py-3 px-[56px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#28272c] duration-200"
          }, [
            (isLoading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22))
              : (_openBlock(), _createElementBlock("span", _hoisted_23, "Save"))
          ], 8, _hoisted_21)
        ])
      ])
    ])
  ]))
}
}

})