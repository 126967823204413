export const getStatusByName = (status: string) => {
  if (status) {
    switch (status) {
      case "not_started":
        return "Not started";
      case "generated":
        return "Generated";
      case "in_progress":
        return "In progress";
    }
  }
  return "";
};
