<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

type Props = {
  title: string;
  data?: any;
};

type Emits = {
  (event: "onChangeInput", name: string, value: string): void;
};

defineEmits<Emits>();
const props = defineProps<Props>();
</script>

<template>
  <div>
    <h3
      class="text-primary-text-color text-xl tracking-[0.01rem] font-semibold pb-2"
    >
      {{ title }}
    </h3>
    <!-- input fields -->
    <div class="max-w-[320px] rounded-primary-radius border border-[#E3E1EA]">
      <!-- field -->
      <div v-for="input in props.data" :key="input.name" class="w-full flex">
        <div class="w-1/2 p-3 bg-[#F9F8F6] border-b border-b-[#E3E1EA]">
          <label
            class="text-primary-text-color break-words text-base tracking-[0.01rem] font-semibold"
            for="hersteller"
            >{{ input.label }}</label
          >
        </div>
        <div
          class="w-1/2 flex items-center justify-center p-3 border-l border-l-[#E3E1EA] border-b border-b-[#E3E1EA]"
        >
          <input
            class="w-full outline-none h-full focus:bg-transparent bg-transparent text-base font-medium text-[#898790]"
            id="hersteller"
            type="text"
            :value="input.value"
            :name="input.name"
            @change="(event:any)=>$emit('onChangeInput',input.name,event.target.value)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
