import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/checkbox-checked.svg'


const _hoisted_1 = { class: "mb-[40px]" }
const _hoisted_2 = { class: "text-primary-text-color text-xl leading-[32px] pb-1 font-semibold tracking-[0.01rem]" }
const _hoisted_3 = { class: "w-full flex items-center gap-2 p-2 pl-1 rounded-primary-radius text-base text-primary-text-color font-normal tracking-[0.01rem] cursor-pointer" }
const _hoisted_4 = ["name", "checked", "onChange"]
const _hoisted_5 = {
  src: _imports_0,
  alt: ""
}



type Props = {
  title: string;
  data?: any;
  checklistName?: string;
};
type Emits = {
  (event: "onChangeInput", name: any, value: any, propertyName?: string): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InputChecklist',
  props: {
    title: {},
    data: {},
    checklistName: {}
  },
  emits: ["onChangeInput"],
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        class: "flex items-center gap-2"
      }, [
        _createElementVNode("label", null, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("input", {
                type: "checkbox",
                name: item.id,
                checked: item.value,
                class: _normalizeClass(item.value ? 'hidden' : 'block'),
                onChange: (event)=> _ctx.$emit('onChangeInput',item.id,event.target.checked, _ctx.checklistName)
              }, null, 42, _hoisted_4),
              _withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [
                [_vShow, item.value]
              ])
            ]),
            _createElementVNode("span", null, _toDisplayString(item.text), 1)
          ])
        ])
      ]))
    }), 128))
  ]))
}
}

})