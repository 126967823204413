<script setup lang="ts">
import MeisterNoteModal from "./Modal/MeisterNoteModal.vue";
import DeletePopup from "./Modal/DeletePopup.vue";
import { defineProps, defineEmits, ref } from "vue";

type Props = {
  meisterNote: any;
  termin: string;
};

type Emits = {
  (event: "refresh"): void;
};

const props = defineProps<Props>();

defineEmits<Emits>();

const isModal = ref(false);
const isNotePopup = ref(false);
const selectedNote = ref<string | null>(null);
const deleteButton = ref<HTMLElement | null>();

// Meister Note modal
const toggleMeisterNoteModal = (meisterNote?: any) => {
  selectedNote.value = meisterNote ? meisterNote : null;
  isModal.value = !isModal.value;
};
const openDeletePopup = (event: Event) => {
  event.stopPropagation();
  isNotePopup.value = true;
  selectedNote.value = props.meisterNote.value ? props.meisterNote.value : null;
};

const togglePopup = () => {
  isNotePopup.value = !isNotePopup.value;
};
</script>

<template>
  <div class="w-full bg-[#E5E4E2] p-3 rounded-primary-radius">
    <div
      class="flex items-center justify-between"
      :class="meisterNote ? 'pb-3' : 'pb-0'"
    >
      <h2
        class="text-lg sm:text-[20px] leading-[30px] tracking-[0.01rem] text-primary-text-color font-semibold"
      >
        Meister note
      </h2>
      <img
        @click="toggleMeisterNoteModal(null)"
        class="cursor-pointer"
        src="@/assets/edit-plus-icon.svg"
        alt="Edit"
      />
      <div v-if="isModal === true">
        <MeisterNoteModal
          :is-open="isModal"
          :termin-id="termin"
          @toggle-modal="toggleMeisterNoteModal"
          @refresh="$emit('refresh')"
        />
      </div>
    </div>
    <div class="flex flex-col gap-3">
      <!-- Card -->
      <div
        v-if="meisterNote"
        class="p-3 bg-white rounded-primary-radius shadow-primary-box-shadow"
      >
        <!-- Header -->
        <div class="flex items-center justify-between">
          <h3
            class="w-full text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold pb-3"
          >
            {{ meisterNote ? meisterNote.title : "" }}
          </h3>
          <div class="flex items-center gap-3">
            <img
              ref="deleteButton"
              @click="openDeletePopup"
              class="cursor-pointer"
              src="@/assets/trash-icoon.svg"
              alt="Icon"
            />
            <div
              v-show="isNotePopup === true"
              class="w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/80 flex items-center justify-center"
            >
              <DeletePopup
                :termin-id="termin"
                :id="meisterNote ? meisterNote.id : null"
                :is-meister-note="true"
                @refresh="$emit('refresh')"
                title="meister note"
                @toggle-popup="togglePopup"
              />
            </div>
          </div>
        </div>
        <!-- Content -->
        <div class="w-full">
          <p>
            {{ meisterNote ? meisterNote.description : "" }}
          </p>
          <!-- Images -->
          <div
            v-if="
              meisterNote &&
              meisterNote.attachments &&
              meisterNote.attachments?.length > 0
            "
            class="flex items-center gap-3 mt-5"
          >
            <div class="flex gap-2 overflow-x-auto">
              <div
                v-for="(attachemnt, index) in meisterNote.attachments"
                :key="index"
                class="flex-shrink-0"
              >
                <img
                  class="w-[200px] h-[230px] object-cover rounded-primary-radius"
                  :src="attachemnt"
                  alt="Termin image"
                />
              </div>
            </div>
          </div>
          <div
            v-else
            class="h-[230px] flex flex-col items-center justify-center"
          >
            <img src="@/assets/no-image.svg" alt="" />
            <p class="text-sm text-[#898790] font-medium pt-2">No images</p>
          </div>
          <!-- Date -->
          <div class="mt-3 flex items-center gap-4">
            <div class="flex items-center gap-1">
              <img
                class="w-3 h-3 cursor-pointer"
                src="@/assets/calendar-icon.svg"
                alt="Calendar"
              />
              <p class="text-sm text-[#898790] font-medium">
                {{
                  meisterNote
                    ? new Date(meisterNote.createdAt).toLocaleDateString("DE")
                    : ""
                }}
              </p>
            </div>
            <div v-if="meisterNote" class="flex items-center gap-1">
              <img class="w-3 h-3" src="@/assets/gray-user.svg" alt="User" />
              <p
                v-for="(worker, index) in meisterNote.meister"
                :key="index"
                class="text-sm text-[#898790] font-medium"
              >
                {{ worker
                }}<span v-if="index < meisterNote.meister.length - 1">,</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
