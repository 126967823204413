import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/edit-plus-icon.svg'
import _imports_1 from '@/assets/pencil-icon.svg'
import _imports_2 from '@/assets/trash-icoon.svg'
import _imports_3 from '@/assets/no-image.svg'
import _imports_4 from '@/assets/calendar-icon.svg'
import _imports_5 from '@/assets/image-user-hover.svg'


const _hoisted_1 = { class: "w-full bg-[#E5E4E2] p-3 rounded-primary-radius overflow-x-hidden" }
const _hoisted_2 = { class: "text-lg sm:text-[20px] leading-[30px] tracking-[0.01rem] text-primary-text-color font-semibold" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "flex gap-3 overflow-x-auto scrollable-container"
}
const _hoisted_5 = { class: "w-[450px] flex flex-col items-start justify-start p-3 bg-white rounded-primary-radius shadow-primary-box-shadow" }
const _hoisted_6 = { class: "w-full flex items-center justify-between mb-4" }
const _hoisted_7 = { class: "text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold" }
const _hoisted_8 = { class: "flex items-center gap-3" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/60 flex items-center justify-center"
}
const _hoisted_12 = { class: "w-full flex flex-col gap-3" }
const _hoisted_13 = {
  key: 0,
  class: "flex items-center gap-3"
}
const _hoisted_14 = { class: "flex gap-2 overflow-x-auto scrollable-container" }
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 1,
  class: "h-[230px] flex flex-col items-center justify-center"
}
const _hoisted_17 = { class: "mt-3 flex gap-4" }
const _hoisted_18 = { class: "flex items-center gap-1" }
const _hoisted_19 = { class: "text-sm text-[#898790] foont-medium" }
const _hoisted_20 = { class: "flex items-center gap-1" }

import DeletePopup from "./Modal/DeletePopup.vue";
import { ref } from "vue";
import NoteModal from "./Modal/NoteModal.vue";


type Props = {
  notes: any;
  termin: string;
};

type Emits = {
  (event: "refresh"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteBlock',
  props: {
    notes: {},
    termin: {}
  },
  emits: ["refresh"],
  setup(__props: any) {

const props = __props;



const isModal = ref(false);
const isPopup = ref(false);
const selectedNote = ref<any | null>(null);
const notes = ref(props.notes);

// Note modal
const toggleNoteModal = (note?: any) => {
  selectedNote.value = note ? note : null;
  isModal.value = !isModal.value;
};

const togglePopup = (note: any) => {
  isPopup.value = !isPopup.value;
  selectedNote.value = note ? note : null;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center justify-between", notes.value.length > 0 ? 'pb-3' : 'pb-0'])
    }, [
      _createElementVNode("h2", _hoisted_2, " Notes (" + _toDisplayString(notes.value ? notes.value.length : "0") + ") ", 1),
      _createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleNoteModal(null))),
        class: "cursor-pointer",
        src: _imports_0,
        alt: "Edit"
      })
    ], 2),
    (isModal.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_Transition, null, {
            default: _withCtx(() => [
              (isModal.value)
                ? (_openBlock(), _createBlock(NoteModal, {
                    key: 0,
                    "is-open": isModal.value,
                    "just-view": false,
                    "header-title": selectedNote.value ? 'Edit Note' : 'New Note',
                    "termin-id": _ctx.termin,
                    onToggleModal: _cache[1] || (_cache[1] = ($event: any) => {
            isModal.value = false;
            selectedNote.value = null;
          }),
                    onRefresh: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('refresh'))),
                    note: selectedNote.value
                  }, null, 8, ["is-open", "header-title", "termin-id", "note"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (notes.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notes.value, (note) => {
            return (_openBlock(), _createElementBlock("div", {
              key: note.id
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h3", _hoisted_7, _toDisplayString(note.title), 1),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("img", {
                      onClick: ($event: any) => (toggleNoteModal(note)),
                      class: "cursor-pointer",
                      src: _imports_1,
                      alt: "Pencil"
                    }, null, 8, _hoisted_9),
                    _createElementVNode("img", {
                      onClick: ($event: any) => (togglePopup(note)),
                      class: "cursor-pointer",
                      src: _imports_2,
                      alt: "Icon"
                    }, null, 8, _hoisted_10),
                    (isPopup.value === true)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(DeletePopup, {
                            "termin-id": _ctx.termin,
                            id: selectedNote.value ? selectedNote.value.id : null,
                            title: "note",
                            "is-note": true,
                            onTogglePopup: _cache[3] || (_cache[3] = ($event: any) => (isPopup.value = false)),
                            onRefresh: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('refresh')))
                          }, null, 8, ["termin-id", "id"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("p", null, _toDisplayString(note.description), 1),
                  (note.attachments && note.attachments?.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(note.attachments, (attachemnt, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: index,
                              class: "flex-shrink-0"
                            }, [
                              _createElementVNode("img", {
                                class: "w-[200px] h-[230px] object-cover rounded-primary-radius",
                                src: attachemnt,
                                alt: "Termin image"
                              }, null, 8, _hoisted_15)
                            ]))
                          }), 128))
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[5] || (_cache[5] = [
                        _createElementVNode("img", {
                          src: _imports_3,
                          alt: ""
                        }, null, -1),
                        _createElementVNode("p", { class: "text-sm text-[#898790] font-medium pt-2" }, "No images", -1)
                      ]))),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _cache[6] || (_cache[6] = _createElementVNode("img", {
                        class: "w-3 h-3 cursor-pointer",
                        src: _imports_4,
                        alt: "Calendar"
                      }, null, -1)),
                      _createElementVNode("p", _hoisted_19, _toDisplayString(new Date(note.createdAt).toLocaleDateString("DE")), 1)
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _cache[7] || (_cache[7] = _createElementVNode("img", {
                        class: "w-3 h-3",
                        src: _imports_5,
                        alt: "User"
                      }, null, -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(note.workers, (worker, index) => {
                        return (_openBlock(), _createElementBlock("p", {
                          key: index,
                          class: "text-sm text-[#898790] font-medium"
                        }, _toDisplayString(worker), 1))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})