<script setup lang="ts">
import { defineProps, defineEmits, ref } from "vue";
import InputModal from "../InputModal.vue";
import SlideImagesBlock from "./SlideImagesBlock.vue";
import CheckListBlock from "./CheckListBlock.vue";
import AddImageModal from "../AddImageModal.vue";

type Props = {
  input: any;
  childInputs: any;
  project: string | string[];
  phase: number;
  phaseDetails: any;
  step: any;
  selectedTermins: any;
};
type Emits = {
  (event: "refresh"): void;
};

const emits = defineEmits<Emits>();

const props = defineProps<Props>();

const isInputModalOpen = ref(false);
const isAddImageModalOpen = ref(false);
const selectedInput = ref<any>(null);
const selectedStep = ref<any>(null);

const toggleModalHandler: any = (input: any) => {
  selectedInput.value = input;
  isInputModalOpen.value = !isInputModalOpen.value;
};
</script>

<template>
  <InputModal
    v-if="isInputModalOpen && selectedInput"
    :input="selectedInput"
    :is-open="isInputModalOpen"
    :project="project"
    :phase="phase"
    :termins="phaseDetails.termins"
    @refresh="emits('refresh')"
    @toggle-modal="
      () => {
        isInputModalOpen = false;
        selectedInput = null;
      }
    "
  />
  <AddImageModal
    v-if="isAddImageModalOpen && selectedInput"
    :steps="phaseDetails.steps"
    :termins="phaseDetails.termins"
    :is-open="isAddImageModalOpen"
    :project="project"
    :input="selectedInput"
    :step="selectedStep"
    :header-title="selectedInput ? selectedInput.name : ''"
    :phase="phase"
    @toggle-modal="isAddImageModalOpen = false"
    @refresh="emits('refresh')"
  />
  <div>
    <h2
      class="text-xl pb-3 leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold"
    >
      {{ input.name }}
    </h2>
    <div class="flex flex-col gap-3">
      <!-- CHECKLIST -->
      <div
        v-if="childInputs.some((input: any) => input.type === 'checklist' && input.value?.length > 0)"
        class="p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
      >
        <CheckListBlock
          :checklist="childInputs.filter((input: any) => input.type === 'checkbox')"
        />
      </div>
      <!-- IMAGE -->
      <div
        v-if="childInputs.some((input: any) => input.type === 'image' && input.value?.length > 0)"
      >
        <div
          v-for="item in childInputs.filter((input: any) => input.type === 'image' && input.value?.length > 0)"
          :key="item.id"
          class="flex flex-col gap-1"
        >
          <SlideImagesBlock
            :selected-termins="selectedTermins"
            @add-image-modal-open="
              () => {
                selectedInput = item;
                selectedStep = step;
                isAddImageModalOpen = true;
              }
            "
            :input="item"
            :step="step"
          />
        </div>
      </div>

      <!-- TEXT -->
      <div
        v-if="childInputs.some((input: any) => input.type === 'text' && input.value )"
        class="flex flex-col gap-3"
      >
        <div
          v-for="item in childInputs.filter((input:any) => input.type === 'text' && input.value && selectedTermins?.includes(input.terminId))"
          :key="item.id"
          class="p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow flex flex-col gap-2"
        >
          <!-- Top side -->
          <div class="w-full flex items-center justify-between">
            <div class="flex items-center gap-3">
              <div class="flex items-center gap-1">
                <img
                  class="w-3 h-3 cursor-pointer"
                  src="@/assets/calendar-icon.svg"
                  alt="Calendar"
                />

                <p class="text-sm text-[#898790] foont-medium">
                  {{
                    item.createdAt
                      ? new Date(item.createdAt).toLocaleDateString("de")
                      : ""
                  }}
                </p>
              </div>
              <div class="flex items-center gap-1">
                <img class="w-3 h-3" src="@/assets/gray-user.svg" alt="User" />
                <p
                  v-for="(worker, index) in item.workers"
                  :key="index"
                  class="text-sm text-[#898790] foont-medium"
                >
                  {{ worker
                  }}<span v-if="index < item.workers.length - 1">,</span>
                </p>
              </div>
            </div>
            <img
              @click="toggleModalHandler(item)"
              class="w-4 h-4 cursor-pointer"
              src="@/assets/pencil-gray.svg"
              alt="Pencil"
            />
          </div>
          <!-- Content -->
          <div class="mt-4">
            <p class="text-base font-normal text-">
              {{ item.name }}
            </p>
            <div class="p-3 rounded-primary-radius bg-[#e5ecf6] mt-2">
              <p class="text-base font-normal text-">
                {{ item.value }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- NUMBER -->
      <div
        v-if="childInputs.some((input: any) => input.type === 'number' && input.value)"
        class="flex gap-1"
      >
        <div
          v-for="item in childInputs.filter((input:any) => input.type === 'number' && input.value && selectedTermins?.includes(input.terminId))"
          :key="item.id"
          class="p-3 w-1/3 bg-white rounded-primary-radius shadow-primary-box-shadow"
        >
          <!-- Top side -->
          <div class="w-full flex items-center justify-between">
            <div class="flex items-center gap-3">
              <div class="flex items-center gap-1">
                <img
                  class="w-3 h-3 cursor-pointer"
                  src="@/assets/calendar-icon.svg"
                  alt="Calendar"
                />

                <p class="text-sm text-[#898790] foont-medium">
                  {{
                    item.createdAt
                      ? new Date(item.createdAt).toLocaleDateString("de")
                      : ""
                  }}
                </p>
              </div>
              <div class="flex items-center gap-1">
                <img class="w-3 h-3" src="@/assets/gray-user.svg" alt="User" />
                <p
                  v-for="(worker, index) in item.workers"
                  :key="index"
                  class="text-sm text-[#898790] foont-medium"
                >
                  {{ worker
                  }}<span v-if="index < item.workers.length - 1">,</span>
                </p>
              </div>
            </div>
            <img
              @click="toggleModalHandler(item)"
              class="w-4 h-4 cursor-pointer"
              src="@/assets/pencil-gray.svg"
              alt="Pencil"
            />
          </div>
          <!-- Content -->
          <div class="mt-4 flex gap-2">
            <p class="text-base font-normal grow">
              {{ item.name }}
            </p>
            <div
              class="px-3 py-[1px] rounded-primary-radius bg-[#e5ecf6] mt-2 h-fit w-fit"
            >
              <p class="text-base font-normal h-fit w-fit">
                {{ item.value }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- SELECT -->
      <div
        v-if="childInputs.some((input: any) => input.type === 'select' && input.value && selectedTermins?.includes(input.terminId))"
        class="flex flex-col gap-3"
      >
        <div
          v-for="item in childInputs.filter((input:any) => input.type === 'select' && input.value)"
          :key="item.id"
          class="p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow flex flex-col gap-2"
        >
          <!-- Top side -->
          <div class="w-full flex items-center justify-between">
            <div class="flex items-center gap-3">
              <div class="flex items-center gap-1">
                <img
                  class="w-3 h-3 cursor-pointer"
                  src="@/assets/calendar-icon.svg"
                  alt="Calendar"
                />

                <p class="text-sm text-[#898790] foont-medium">
                  {{
                    item.createdAt
                      ? new Date(item.createdAt).toLocaleDateString("de")
                      : ""
                  }}
                </p>
              </div>
              <div class="flex items-center gap-1">
                <img class="w-3 h-3" src="@/assets/gray-user.svg" alt="User" />
                <p
                  v-for="(worker, index) in item.workers"
                  :key="index"
                  class="text-sm text-[#898790] foont-medium"
                >
                  {{ worker
                  }}<span v-if="index < item.workers.length - 1">,</span>
                </p>
              </div>
            </div>
            <img
              @click="toggleModalHandler(item)"
              class="w-4 h-4 cursor-pointer"
              src="@/assets/pencil-gray.svg"
              alt="Pencil"
            />
          </div>
          <!-- Content -->
          <div class="mt-4">
            <p class="text-base font-normal text-">
              {{ item.name }}
            </p>
            <div class="p-3 rounded-primary-radius bg-[#e5ecf6] mt-2">
              <p class="text-base font-normal text-">
                {{ item.value }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
