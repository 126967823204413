<script setup lang="ts">
import SelectMenu from "@/components/Re-usable/SelectMenu.vue";
import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";
import { defineProps, defineEmits, reactive, ref } from "vue";

type Props = {
  issue: any;
  options: Array<{ id: number; name: string }>;
  isOpen: boolean;
  justView: boolean;
  headerTitle: string;
  terminId?: string;
};

type Emits = {
  (event: "toggleModal"): void;
  (event: "refresh"): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const selectedOption = ref<any | null>(
  props.issue
    ? props.options.find((item: any) => item.id === props.issue.typeId)
    : null
);
const files = ref([]);
const isLoading = ref(false);

const state = reactive({
  title: props.issue && props.issue.title ? props.issue?.title : "",
  description:
    props.issue && props.issue.description ? props.issue?.description : "",
});

const handleFileChange = (event: any) => {
  files.value = Array.from(event.target.files);
  imagePreviews.value = [
    ...imagePreviews.value,
    ...files.value.map((file: any) => URL.createObjectURL(file)),
  ];
};

const imagePreviews = ref<string[]>(props.issue ? props.issue.attachments : []);

const onSubmit = async () => {
  if (!selectedOption.value) {
    errorToast("Please select issue type!");
    return;
  }

  isLoading.value = true;

  const formData = new FormData();
  formData.append("title", state.title);
  formData.append("description", state.description);
  formData.append("typeId", selectedOption.value.id);

  if (files.value) {
    files.value.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
  }
  try {
    await client.post(`/termin/${props.terminId}/issue`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    isLoading.value = false;
    successToast("Successfully added issue.");
    emits("refresh");
    emits("toggleModal");
  } catch (error: any) {
    errorToast(error);
  }
};

const onEdit = async () => {
  if (!selectedOption.value) {
    errorToast("Please select issue type!");
    return;
  }

  isLoading.value = true;

  const formData = new FormData();
  formData.append("title", state.title);
  formData.append("description", state.description);
  formData.append("typeId", selectedOption.value.id);

  if (files.value) {
    files.value.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
  }

  await client.put(
    `/termin/${props.terminId}/issue/${props.issue.id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  isLoading.value = false;
  successToast("Successfully updated issue.");
  emits("refresh");
  emits("toggleModal");
};
</script>

<template>
  <div
    class="fixed overflow-y-auto inset-0 bg-black bg-opacity-80 justify-center items-center z-50 transition-opacity duration-300 ease-in-out"
    :class="`${isOpen ? 'flex' : 'hidden'}`"
  >
    <div
      class="absolute overflow-y-auto top-5 w-[630px] p-6 mx-3 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 rounded-lg shadow-lg max-w-md"
    >
      <div
        class="flex items-center justify-between border-b border-gray-300 pb-3"
      >
        <div class="flex items-center gap-[10px]">
          <div v-if="!justView">
            <img
              :class="state.title ? 'hidden' : 'block'"
              src="@/assets/edit-plus-icon.svg"
              alt="Icon"
            />
            <img
              :class="state.title ? 'block' : 'hidden'"
              src="@/assets/pencil-icon.svg"
              alt="Icon"
            />
          </div>

          <h4
            class="text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold"
          >
            {{ headerTitle }}
          </h4>
        </div>
        <div class="flex gap-4">
          <span class="text">
            {{ issue ? issue.terminName : "" }}
          </span>
          <button @click="emits('toggleModal')">
            <img src="@/assets/close-icon-2.svg" alt="Close icon" />
          </button>
        </div>
      </div>
      <!-- Form -->
      <form @submit.prevent="state.title ? onSubmit() : onEdit()">
        <h4
          class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4"
        >
          Choose an issue type
        </h4>
        <div>
          <div>
            <SelectMenu
              :disabled="justView"
              :options="options"
              v-model="selectedOption"
            />
          </div>
          <div class="mt-6 flex flex-col">
            <h4
              class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4"
            >
              Title
            </h4>
            <input
              :disabled="justView"
              v-model="state.title"
              class="w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] placeholder:text-primary-text-color"
              type="text"
              placeholder="Title"
            />
          </div>
          <div class="mt-6 flex flex-col">
            <h4
              class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4"
            >
              Description
            </h4>
            <textarea
              :disabled="justView"
              v-model="state.description"
              rows="7"
              class="w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] resize-none placeholder:text-primary-text-color"
              placeholder="Description"
            ></textarea>
          </div>
        </div>
        <!-- Existing attachments -->
        <div
          v-if="imagePreviews.length"
          class="flex w-full overflow-x-auto gap-2 mt-6 whitespace-nowrap scrollable-container"
        >
          <div
            v-for="(src, index) in imagePreviews"
            :key="index"
            class="shrink-0 w-[123px] h-[123px]"
          >
            <img
              :src="src"
              alt="Uploaded image"
              class="shrink-0 object-cover w-[123px] h-[123px] rounded-md"
            />
          </div>
        </div>
        <div class="flex items-center gap-2 mt-[36px]">
          <label
            for="file"
            class="px-4 py-2 text-[#393840] font-medium rounded-md cursor-pointer transition flex items-center border-2 border-[#393840] w-fit hover:bg-[#F9F8F6]"
          >
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.40186 7.59852L6.57257 2.4278C8.11026 0.890114 10.6034 0.890114 12.1411 2.4278C13.6788 3.96549 13.6786 6.45872 12.1409 7.99641L6.17468 13.9626C5.14956 14.9877 3.48778 14.9876 2.46265 13.9625C1.43753 12.9373 1.43728 11.2755 2.4624 10.2503L8.42862 4.28413C8.94118 3.77156 9.77265 3.77156 10.2852 4.28413C10.7978 4.79669 10.7974 5.62752 10.2849 6.14008L5.11414 11.3108"
                stroke="#393840"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Attach Files
          </label>
          <input
            multiple
            class="hidden"
            type="file"
            id="file"
            @change="handleFileChange"
          />
        </div>
        <!-- Submit button -->
        <div class="flex justify-end">
          <div
            @click="issue ? onEdit() : onSubmit()"
            class="mt-5 w-[150px] flex items-center justify-center py-3 px-[56px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#28272c] duration-200"
          >
            <div
              v-if="isLoading"
              class="w-6 h-6 border-4 border-white border-t-transparent rounded-full animate-spin"
            ></div>
            <span v-else>Save</span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
