import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-3 overflow-x-auto scrollable-container" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[150px] min-h-[44px] text-base" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[150px] min-h-[44px] text-base break-all" }
const _hoisted_8 = { class: "bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[150px] min-h-[44px] text-base break-all" }
const _hoisted_9 = ["value", "name", "onChange"]

import { computed } from "vue";

type Props = {
  title?: string;
  data?: any;
  tableName: string;
};
type Emits = {
  (
    event: "onChangeInput",
    tableName: string,
    valueArrayIndex: number,
    columnIndex: number,
    value: any
  ): void;
  (event: "addColumn", tableName: string): void;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'HorizontalDynamicTable',
  props: {
    title: {},
    data: {},
    tableName: {}
  },
  emits: ["onChangeInput", "addColumn"],
  setup(__props: any) {


const props = __props;
let report = computed(() => {
  return props.data;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[150px] min-h-[44px] text-base" }, null, -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(report)[0].value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[150px] min-h-[44px] text-base",
            key: item
          }, _toDisplayString(index + 1), 1))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(report), (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "flex items-stretch"
            }, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(item.title), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(item.column), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value, (value, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "h-full",
                  key: value
                }, [
                  _createElementVNode("input", {
                    value: value,
                    name: index + '',
                    onChange: (event)=>_ctx.$emit('onChangeInput',_ctx.tableName,index,item.id,event.target.value),
                    class: "bg-white border h-full border-[#e3e1ea] p-3 flex items-center w-[150px] min-h-[44px] text-base"
                  }, null, 40, _hoisted_9)
                ]))
              }), 128))
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: "hover:cursor-pointer mt-4",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addColumn', _ctx.tableName)))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("svg", {
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", {
          d: "M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10Z",
          fill: "white"
        }),
        _createElementVNode("path", {
          d: "M6 10H10M10 10H14M10 10V14M10 10V6M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19Z",
          stroke: "#393840",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        })
      ], -1)
    ]))
  ]))
}
}

})