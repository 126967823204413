<script setup lang="ts">
import { defineProps, defineEmits, computed } from "vue";

type Props = {
  title?: string;
  data?: any;
  tableName: string;
};
type Emits = {
  (
    event: "onChangeInput",
    tableName: string,
    valueArrayIndex: number,
    columnIndex: number,
    value: any
  ): void;
  (event: "addColumn", tableName: string): void;
};
defineEmits<Emits>();
const props = defineProps<Props>();
let report = computed(() => {
  return props.data;
});
</script>
<template>
  <div class="flex gap-3 overflow-x-auto scrollable-container">
    <div class="flex flex-col">
      <!-- Header -->
      <div class="flex">
        <div
          class="bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[150px] min-h-[44px] text-base"
        >
          {{ title }}
        </div>
        <div
          class="bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[150px] min-h-[44px] text-base"
        ></div>
        <div
          class="bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[150px] min-h-[44px] text-base"
          v-for="(item, index) in report[0].value"
          :key="item"
        >
          {{ index + 1 }}
        </div>
      </div>
      <!-- Content -->
      <div class="flex">
        <div class="flex flex-col">
          <div v-for="item in report" :key="item.id" class="flex items-stretch">
            <div
              class="bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[150px] min-h-[44px] text-base break-all"
            >
              {{ item.title }}
            </div>
            <div
              class="bg-[#f9f8f6] border border-[#e3e1ea] p-3 flex items-center w-[150px] min-h-[44px] text-base break-all"
            >
              {{ item.column }}
            </div>
            <div
              class="h-full"
              v-for="(value, index) in item.value"
              :key="value"
            >
              <input
                :value="value"
                :name="index + ''"
                @change="(event:any)=>$emit('onChangeInput',tableName,index,item.id,event.target.value)"
                class="bg-white border h-full border-[#e3e1ea] p-3 flex items-center w-[150px] min-h-[44px] text-base"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="hover:cursor-pointer mt-4"
      @click="$emit('addColumn', tableName)"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10Z"
          fill="white"
        />
        <path
          d="M6 10H10M10 10H14M10 10V14M10 10V6M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19Z"
          stroke="#393840"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>
