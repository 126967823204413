import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/close-icon-2.svg'


const _hoisted_1 = { class: "w-full bg-[#E5E4E2] p-3 rounded-primary-radius shadow-primary-box-shadow" }
const _hoisted_2 = { class: "flex items-center justify-between mb-3" }
const _hoisted_3 = {
  key: 0,
  class: ""
}

import VuePdfEmbed from "vue-pdf-embed";
import "vue-pdf-embed/dist/styles/annotationLayer.css";
import "vue-pdf-embed/dist/styles/textLayer.css";


type Props = {
  phaseDetails: any;
};

type Emits = {
  (event: "toggleProjectMap"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectMap',
  props: {
    phaseDetails: {}
  },
  emits: ["toggleProjectMap"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "text-base text-primary-text-color leading-5 tracking-[0.01rem] font-semibold" }, " Baumappe ", -1)),
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('toggleProjectMap'))),
        class: "cursor-pointer flex items-center justify-center"
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)
      ]))
    ]),
    (props.phaseDetails?.project?.baummape)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_unref(VuePdfEmbed), {
            "annotation-layer": "",
            "text-layer": "",
            source: props.phaseDetails?.project?.baummape
          }, null, 8, ["source"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})