import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-primary-text-color text-xl tracking-[0.01rem] font-semibold pb-2" }
const _hoisted_2 = { class: "max-w-[320px] rounded-primary-radius border border-[#E3E1EA]" }
const _hoisted_3 = { class: "w-1/2 p-3 bg-[#F9F8F6] border-b border-b-[#E3E1EA]" }
const _hoisted_4 = {
  class: "text-primary-text-color break-words text-base tracking-[0.01rem] font-semibold",
  for: "hersteller"
}
const _hoisted_5 = { class: "w-1/2 flex items-center justify-center p-3 border-l border-l-[#E3E1EA] border-b border-b-[#E3E1EA]" }
const _hoisted_6 = ["value", "name", "onChange"]



type Props = {
  title: string;
  data?: any;
};

type Emits = {
  (event: "onChangeInput", name: string, value: string): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InputTable',
  props: {
    title: {},
    data: {}
  },
  emits: ["onChangeInput"],
  setup(__props: any) {


const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.data, (input) => {
        return (_openBlock(), _createElementBlock("div", {
          key: input.name,
          class: "w-full flex"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(input.label), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("input", {
              class: "w-full outline-none h-full focus:bg-transparent bg-transparent text-base font-medium text-[#898790]",
              id: "hersteller",
              type: "text",
              value: input.value,
              name: input.name,
              onChange: (event)=>_ctx.$emit('onChangeInput',input.name,event.target.value)
            }, null, 40, _hoisted_6)
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})