<script setup lang="ts">
import { defineEmits, ref, defineProps } from "vue";
import { textTransform } from "@/utils/parse-data";

type Props = {
  project: any;
  isOpen: boolean;
  isBaumappeOpen: boolean;
};

const props = defineProps<Props>();

type Emits = {
  (event: "closeTerminFilter"): void;
  (event: "onFilterSubmit", selectedTermins: Array<string>): void;
};

const emits = defineEmits<Emits>();

const selectedTermins = ref(props.project.termins.map((item: any) => item.id));
const onTerminSelectChange = (terminId: string) => {
  if (selectedTermins.value.includes(terminId)) {
    selectedTermins.value = selectedTermins.value.filter(
      (item: string) => item !== terminId
    );
  } else {
    selectedTermins.value.push(terminId);
  }
};
</script>

<template>
  <div
    v-if="isOpen"
    class="w-full fixed top-0 right-0 bottom-0 left-0 z-[777] bg-black/25"
  >
    <div
      class="w-[310px] absolute top-[65px] right-[24px] z-[888]"
      :class="isBaumappeOpen ? 'xl:right-[75px]' : 'xl:right-[130px]'"
    >
      <div
        class="p-3 bg-white rounded-primary-radius shadow-primary-box-shadow flex flex-col h-fit"
      >
        <!-- Header -->
        <div class="flex items-start justify-between mb-[18px]">
          <h4
            class="text-lg tracking-[0.01rem] text-primary-text-color font-semibold"
          >
            Termin filter
          </h4>
          <button @click="emits('closeTerminFilter')">
            <img src="@/assets/close-icon-2.svg" alt="" />
          </button>
        </div>
        <!-- Termins -->
        <div v-if="props.project.termins.length !== 0">
          <div class="overflow-y-auto max-h-[260px]">
            <div
              v-for="termin in project.termins"
              :key="termin.id"
              class="w-full flex flex-col gap-3 rounded-primary-radius mb-2"
              :class="
                selectedTermins.includes(termin.id)
                  ? 'bg-[#DFDEDC]'
                  : 'bg-white'
              "
            >
              <!-- Termin -->
              <div class="w-full p-2 rounded-primary-radius">
                <!-- Termin header -->
                <div class="flex items-center">
                  <input
                    :id="'checkbox-' + termin.id"
                    type="checkbox"
                    :class="
                      selectedTermins.includes(termin.id) ? 'hidden' : 'flex'
                    "
                    :checked="selectedTermins.includes(termin.id)"
                    @change="onTerminSelectChange(termin.id)"
                  />
                  <label :for="'checkbox-' + termin.id">
                    <img
                      v-show="selectedTermins.includes(termin.id)"
                      src="@/assets/checkbox-checked.svg"
                      class="cursor-pointer min-w-[18px] h-[18px]"
                    />
                  </label>
                  <h5
                    class="text-base pl-2 leading-[26px] tracking-[0.01rem] text-primary-text-color font-medium"
                  >
                    {{ termin.name }}
                  </h5>
                  <!-- Status -->
                  <span
                    class="text-xs ml-2 tracking-[0.01rem] text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius bg-[#B1E3FF]"
                    >{{ textTransform(termin.status) }}</span
                  >
                </div>
                <!-- Date and epmloyees-->
                <div class="flex flex-col items-start gap-[6px] mt-[10px]">
                  <div class="px-[40px] flex items-center gap-2">
                    <img src="@/assets/calendar-icon.svg" alt="" />
                    <div class="flex items-center gap-1">
                      <p>
                        {{ new Date(termin.start).toLocaleDateString("DE") }}
                      </p>
                    </div>
                  </div>
                  <!-- Employees -->
                  <div class="px-[40px] flex items-start gap-2">
                    <img src="@/assets/users-icon.svg" alt="" />
                    <div class="flex flex-wrap">
                      <p
                        class="text-sm"
                        v-for="(worker, index) in termin.workers"
                        :key="index"
                      >
                        <span v-if="termin.workers.length === 0">-</span>
                        <span v-else>
                          {{
                            index < termin.workers.length - 1
                              ? worker.concat("-") + " "
                              : worker.substring(0, worker.length - 1)
                          }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Controls -->
          <div
            class="flex items-center justify-between pt-3 mt-6 border-t border-gray-200"
          >
            <button
              @click="
                selectedTermins = project.termins.map((item: any) => item.id)
              "
              class="text-base leading-5 tracking-[0.01rem] text-primary-text-color font-semibold p-3 rounded-primary-radius border-2 border-black"
            >
              Reset
            </button>
            <button
              @click="$emit('onFilterSubmit', selectedTermins)"
              class="text-base leading-5 tracking-[0.01rem] text-primary-text-color font-semibold p-3 rounded-primary-radius bg-[#DDDDFB]"
            >
              Save
            </button>
          </div>
        </div>
        <div
          v-else
          class="text-primary-text-color text-[15px] font-medium text-center pb-[18px]"
        >
          No appointments found.
        </div>
      </div>
    </div>
  </div>
</template>
