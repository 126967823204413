<script setup lang="ts">
import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";
import { defineEmits, defineProps, ref } from "vue";

type Props = {
  title: string;
  terminId: string;
  id: string;
  isNote?: boolean;
  isMeisterNote?: boolean;
};

const props = defineProps<Props>();

type Emits = {
  (event: "togglePopup"): void;
  (event: "refresh"): void;
};

const emits = defineEmits<Emits>();
const isLoading = ref(false);

const handleCancelClick = (event: Event) => {
  event.stopPropagation();
  emits("togglePopup");
};

const handleDeleteClick = async (event: Event) => {
  event.stopPropagation();
  isLoading.value = true;

  if (props.terminId && props.id) {
    let resp: any = {};

    if (props.isMeisterNote) {
      resp = await client.delete(
        `/termin/${props.terminId}/meister-note/${props.id}`
      );
    } else {
      resp = await client.delete(
        `/termin/${props.terminId}/${props.isNote ? "note" : "issue"}/${
          props.id
        }`
      );
    }

    if (resp.status === 200) {
      isLoading.value = false;
      emits("refresh");
      successToast("Succesfully deleted " + props.title + ".");
      emits("togglePopup");
      return;
    } else {
      errorToast("Something went wrong.");
    }
  }
  errorToast("Insufficient data passed.");
};
</script>

<template>
  <div
    class="w-[360px] flex flex-col gap-[32px] items-center p-6 mx-3 bg-white rounded-primary-radius"
  >
    <div class="flex flex-col gap-2 items-center">
      <img src="@/assets/delete-modal-icon.svg" alt="Delete" />
      <p
        class="text-[20px] tracking-[0.01rem] text-primary-text-color font-semibold"
      >
        Delete this {{ title }}?
      </p>
    </div>
    <!-- Buttons -->
    <div class="flex items-center gap-4">
      <button
        @click="handleCancelClick"
        class="flex w-[150px] items-center justify-center py-3 px-[48px] text-base text-[#393840] bg-transparent tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#efeeec] duration-200"
      >
        Cancel
      </button>
      <button
        @click="handleDeleteClick"
        class="flex items-center w-[150px] justify-center py-3 px-[48px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#28272c] duration-200"
      >
        <div
          v-if="isLoading"
          class="w-6 h-6 border-4 border-white border-t-transparent rounded-full animate-spin"
        ></div>
        <span v-else>Delete</span>
      </button>
    </div>
  </div>
</template>
