import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/edit-plus-icon.svg'
import _imports_1 from '@/assets/trash-icoon.svg'
import _imports_2 from '@/assets/no-image.svg'
import _imports_3 from '@/assets/calendar-icon.svg'
import _imports_4 from '@/assets/gray-user.svg'


const _hoisted_1 = { class: "w-full bg-[#E5E4E2] p-3 rounded-primary-radius" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex flex-col gap-3" }
const _hoisted_4 = {
  key: 0,
  class: "p-3 bg-white rounded-primary-radius shadow-primary-box-shadow"
}
const _hoisted_5 = { class: "flex items-center justify-between" }
const _hoisted_6 = { class: "w-full text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold pb-3" }
const _hoisted_7 = { class: "flex items-center gap-3" }
const _hoisted_8 = { class: "w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/80 flex items-center justify-center" }
const _hoisted_9 = { class: "w-full" }
const _hoisted_10 = {
  key: 0,
  class: "flex items-center gap-3 mt-5"
}
const _hoisted_11 = { class: "flex gap-2 overflow-x-auto" }
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 1,
  class: "h-[230px] flex flex-col items-center justify-center"
}
const _hoisted_14 = { class: "mt-3 flex items-center gap-4" }
const _hoisted_15 = { class: "flex items-center gap-1" }
const _hoisted_16 = { class: "text-sm text-[#898790] font-medium" }
const _hoisted_17 = {
  key: 0,
  class: "flex items-center gap-1"
}
const _hoisted_18 = { key: 0 }

import MeisterNoteModal from "./Modal/MeisterNoteModal.vue";
import DeletePopup from "./Modal/DeletePopup.vue";
import { ref } from "vue";

type Props = {
  meisterNote: any;
  termin: string;
};

type Emits = {
  (event: "refresh"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MeisterNoteBlock',
  props: {
    meisterNote: {},
    termin: {}
  },
  emits: ["refresh"],
  setup(__props: any) {

const props = __props;



const isModal = ref(false);
const isNotePopup = ref(false);
const selectedNote = ref<string | null>(null);
const deleteButton = ref<HTMLElement | null>();

// Meister Note modal
const toggleMeisterNoteModal = (meisterNote?: any) => {
  selectedNote.value = meisterNote ? meisterNote : null;
  isModal.value = !isModal.value;
};
const openDeletePopup = (event: Event) => {
  event.stopPropagation();
  isNotePopup.value = true;
  selectedNote.value = props.meisterNote.value ? props.meisterNote.value : null;
};

const togglePopup = () => {
  isNotePopup.value = !isNotePopup.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center justify-between", _ctx.meisterNote ? 'pb-3' : 'pb-0'])
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "text-lg sm:text-[20px] leading-[30px] tracking-[0.01rem] text-primary-text-color font-semibold" }, " Meister note ", -1)),
      _createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleMeisterNoteModal(null))),
        class: "cursor-pointer",
        src: _imports_0,
        alt: "Edit"
      }),
      (isModal.value === true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(MeisterNoteModal, {
              "is-open": isModal.value,
              "termin-id": _ctx.termin,
              onToggleModal: toggleMeisterNoteModal,
              onRefresh: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('refresh')))
            }, null, 8, ["is-open", "termin-id"])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.meisterNote)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.meisterNote ? _ctx.meisterNote.title : ""), 1),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  ref_key: "deleteButton",
                  ref: deleteButton,
                  onClick: openDeletePopup,
                  class: "cursor-pointer",
                  src: _imports_1,
                  alt: "Icon"
                }, null, 512),
                _withDirectives(_createElementVNode("div", _hoisted_8, [
                  _createVNode(DeletePopup, {
                    "termin-id": _ctx.termin,
                    id: _ctx.meisterNote ? _ctx.meisterNote.id : null,
                    "is-meister-note": true,
                    onRefresh: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('refresh'))),
                    title: "meister note",
                    onTogglePopup: togglePopup
                  }, null, 8, ["termin-id", "id"])
                ], 512), [
                  [_vShow, isNotePopup.value === true]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("p", null, _toDisplayString(_ctx.meisterNote ? _ctx.meisterNote.description : ""), 1),
              (
              _ctx.meisterNote &&
              _ctx.meisterNote.attachments &&
              _ctx.meisterNote.attachments?.length > 0
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.meisterNote.attachments, (attachemnt, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "flex-shrink-0"
                        }, [
                          _createElementVNode("img", {
                            class: "w-[200px] h-[230px] object-cover rounded-primary-radius",
                            src: attachemnt,
                            alt: "Termin image"
                          }, null, 8, _hoisted_12)
                        ]))
                      }), 128))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[4] || (_cache[4] = [
                    _createElementVNode("img", {
                      src: _imports_2,
                      alt: ""
                    }, null, -1),
                    _createElementVNode("p", { class: "text-sm text-[#898790] font-medium pt-2" }, "No images", -1)
                  ]))),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _cache[5] || (_cache[5] = _createElementVNode("img", {
                    class: "w-3 h-3 cursor-pointer",
                    src: _imports_3,
                    alt: "Calendar"
                  }, null, -1)),
                  _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.meisterNote
                    ? new Date(_ctx.meisterNote.createdAt).toLocaleDateString("DE")
                    : ""), 1)
                ]),
                (_ctx.meisterNote)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _cache[6] || (_cache[6] = _createElementVNode("img", {
                        class: "w-3 h-3",
                        src: _imports_4,
                        alt: "User"
                      }, null, -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.meisterNote.meister, (worker, index) => {
                        return (_openBlock(), _createElementBlock("p", {
                          key: index,
                          class: "text-sm text-[#898790] font-medium"
                        }, [
                          _createTextVNode(_toDisplayString(worker), 1),
                          (index < _ctx.meisterNote.meister.length - 1)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_18, ","))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})