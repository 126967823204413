import router from "@/router";
import { useUserStore } from "@/stores/user";
import { createClient } from "@supabase/supabase-js";
import axios from "axios";

const client = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT + "/api/qc",
  headers: { "Content-Type": "application/json" },
});

// let isRefreshing = false;
// let failedRequests: any = [];

// const processFailedRequests = (error: any, token = null) => {
//   failedRequests.forEach((promise: any) => {
//     if (token) {
//       promise.resolve(token);
//     } else {
//       promise.reject(error);
//     }
//   });
//   failedRequests = [];
// };

// Request interceptor
client.interceptors.request.use(
  async (config: any) => {
    const userStore = useUserStore();
    const decodedToken = JSON.parse(
      atob(userStore.getAccessToken.split(".")[1])
    );

    const currentTime: any = Math.floor(Date.now() / 1000);

    if (decodedToken.exp < currentTime) {
      const supabase = createClient(
        process.env.VUE_APP_SUPABASE_URL || "",
        process.env.VUE_APP_SUPABASE_API_KEY || ""
      );
      const { data, error } = await supabase.auth.refreshSession();
      if (error) {
        console.error(error.message);
      }
      if (data && data.session) {
        userStore.setTokens(
          data.session.access_token,
          data.session.refresh_token
        );
      }
    }

    if (config.headers) {
      config.headers["x-supabase-auth"] = `${userStore.getAccessToken}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// Response interceptor
client.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    const userStore = useUserStore();

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error && error.response && error.response.status === 401) {
      userStore.logout();
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

export default client;
