<script setup lang="ts">
import DeletePopup from "./Modal/DeletePopup.vue";
import { ref } from "vue";
import NoteModal from "./Modal/NoteModal.vue";
import { defineProps, defineEmits } from "vue";

type Props = {
  notes: any;
  termin: string;
};

type Emits = {
  (event: "refresh"): void;
};

const props = defineProps<Props>();

defineEmits<Emits>();

const isModal = ref(false);
const isPopup = ref(false);
const selectedNote = ref<any | null>(null);
const notes = ref(props.notes);

// Note modal
const toggleNoteModal = (note?: any) => {
  selectedNote.value = note ? note : null;
  isModal.value = !isModal.value;
};

const togglePopup = (note: any) => {
  isPopup.value = !isPopup.value;
  selectedNote.value = note ? note : null;
};
</script>

<template>
  <div class="w-full bg-[#E5E4E2] p-3 rounded-primary-radius overflow-x-hidden">
    <div
      class="flex items-center justify-between"
      :class="notes.length > 0 ? 'pb-3' : 'pb-0'"
    >
      <h2
        class="text-lg sm:text-[20px] leading-[30px] tracking-[0.01rem] text-primary-text-color font-semibold"
      >
        Notes ({{ notes ? notes.length : "0" }})
      </h2>
      <img
        @click="toggleNoteModal(null)"
        class="cursor-pointer"
        src="@/assets/edit-plus-icon.svg"
        alt="Edit"
      />
    </div>
    <!-- Note modal -->
    <div v-if="isModal">
      <Transition>
        <NoteModal
          v-if="isModal"
          :is-open="isModal"
          :just-view="false"
          :header-title="selectedNote ? 'Edit Note' : 'New Note'"
          :termin-id="termin"
          @toggle-modal="
            isModal = false;
            selectedNote = null;
          "
          @refresh="$emit('refresh')"
          :note="selectedNote"
        />
      </Transition>
    </div>
    <!-- Note block -->
    <div v-if="notes" class="flex gap-3 overflow-x-auto scrollable-container">
      <div v-for="note in notes" :key="note.id">
        <div
          class="w-[450px] flex flex-col items-start justify-start p-3 bg-white rounded-primary-radius shadow-primary-box-shadow"
        >
          <!-- Header -->
          <div class="w-full flex items-center justify-between mb-4">
            <h3
              class="text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold"
            >
              {{ note.title }}
            </h3>
            <div class="flex items-center gap-3">
              <img
                @click="toggleNoteModal(note)"
                class="cursor-pointer"
                src="@/assets/pencil-icon.svg"
                alt="Pencil"
              />
              <img
                @click="togglePopup(note)"
                class="cursor-pointer"
                src="@/assets/trash-icoon.svg"
                alt="Icon"
              />
              <div
                v-if="isPopup === true"
                class="w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/60 flex items-center justify-center"
              >
                <DeletePopup
                  :termin-id="termin"
                  :id="selectedNote ? selectedNote.id : null"
                  title="note"
                  :is-note="true"
                  @toggle-popup="isPopup = false"
                  @refresh="$emit('refresh')"
                />
              </div>
            </div>
          </div>
          <!-- Content -->
          <div class="w-full flex flex-col gap-3">
            <p>
              {{ note.description }}
            </p>
            <!-- Images -->
            <div
              v-if="note.attachments && note.attachments?.length > 0"
              class="flex items-center gap-3"
            >
              <div class="flex gap-2 overflow-x-auto scrollable-container">
                <div
                  v-for="(attachemnt, index) in note.attachments"
                  :key="index"
                  class="flex-shrink-0"
                >
                  <img
                    class="w-[200px] h-[230px] object-cover rounded-primary-radius"
                    :src="attachemnt"
                    alt="Termin image"
                  />
                </div>
              </div>
            </div>
            <div
              v-else
              class="h-[230px] flex flex-col items-center justify-center"
            >
              <img src="@/assets/no-image.svg" alt="" />
              <p class="text-sm text-[#898790] font-medium pt-2">No images</p>
            </div>
            <!-- Date -->
            <div class="mt-3 flex gap-4">
              <div class="flex items-center gap-1">
                <img
                  class="w-3 h-3 cursor-pointer"
                  src="@/assets/calendar-icon.svg"
                  alt="Calendar"
                />
                <p class="text-sm text-[#898790] foont-medium">
                  {{ new Date(note.createdAt).toLocaleDateString("DE") }}
                </p>
              </div>
              <div class="flex items-center gap-1">
                <img
                  class="w-3 h-3"
                  src="@/assets/image-user-hover.svg"
                  alt="User"
                />

                <p
                  v-for="(worker, index) in note.workers"
                  :key="index"
                  class="text-sm text-[#898790] font-medium"
                >
                  {{ worker }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.v-enter-from {
  transform: translateY(100%);
}

.v-leave-to {
  transform: translateY(-100%);
}
</style>
