import { toast } from "vue3-toastify";

export const successToast = (message: string) => {
  toast.success(
    `<div
        class="flex gap-2 items-center"
    > 
        <div class="w-1/12">
          <svg width="11" height="8"  viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 3.99996L3.82843 6.82839L9.48468 1.17151" stroke="#393840" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      

        <p class="w-11/12">
         ${message}
        </p>
    </div>`,
    {
      icon: false,
      dangerouslyHTMLString: true,
    }
  );
};

export const errorToast = (message: string) => {
  toast.error(
    `<div
        class="flex gap-2 items-center"
    > 
        <div class="w-1/12">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 5V7.66666M1.91927 9.1332C1.31298 10.1833 1.00992 10.7086 1.05521 11.1394C1.09471 11.5152 1.29195 11.8567 1.59766 12.0788C1.94802 12.3333 2.55394 12.3333 3.76569 12.3333H10.2343C11.4461 12.3333 12.0519 12.3333 12.4023 12.0788C12.708 11.8567 12.9053 11.5152 12.9448 11.1394C12.9901 10.7086 12.6871 10.1833 12.0808 9.1332L8.84766 3.5332C8.24137 2.48308 7.93809 1.95811 7.54232 1.7819C7.19711 1.6282 6.80267 1.6282 6.45746 1.7819C6.06185 1.95803 5.75876 2.48301 5.15298 3.53226L1.91927 9.1332ZM7.03385 9.66666V9.73333L6.9668 9.73346V9.66666H7.03385Z" stroke="#393840" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      

        <p class="w-11/12">
         ${message}
        </p>
    </div>`,
    {
      icon: false,
      dangerouslyHTMLString: true,
    }
  );
};
