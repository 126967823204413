<script setup lang="ts">
import { textTransform } from "@/utils/parse-data";
import { getProjectStatusColor } from "@/utils/status-color";
import { defineProps, ref, defineEmits, computed } from "vue";
import { useRoute } from "vue-router";
import SpinnerLoader from "../Loaders/SpinnerLoader.vue";

const route = useRoute();
const projectId = route.params.id;
const terminId = route.params.terminId;

type Props = {
  phases?: any;
  selectedTermin?: any;
  selectedPhase?: number;
  isLoading: boolean;
};
type Emits = {
  (event: "onTerminSelect", phaseId: number): void;
};

const isPhase = ref(true);

const props = defineProps<Props>();

const emits = defineEmits<Emits>();

const isLoading = computed(() => props.isLoading);

const phases = ref(props.phases);

const selectTermin = (terminId: number) => {
  emits("onTerminSelect", terminId);
};
</script>

<template>
  <div
    class="hidden lg:flex flex-col relative min-h-[calc(100vh-28px)] w-[230px] pr-3 sm:min-h-[calc(100vh-48px)] mr-6 sm:mr-0 sm:pl-0"
  >
    <div class="flex items-center gap-2">
      <RouterLink
        to="/"
        class="w-[26px] h-[26px] flex items-center justify-center cursor-pointer"
      >
        <img src="@/assets/smalt-home-logo.svg" alt="Smalt icon" />
      </RouterLink>

      <div class="flex items-center gap">
        <button
          @click="$router.go(-1)"
          class="w-6 h-6 flex items-center justify-center cursor-pointer rounded-sm hover:bg-gray-200 duration-200"
        >
          <img src="@/assets/arrow-left.svg" alt="" />
        </button>
        <button
          @click="$router.go(+1)"
          class="w-6 h-6 flex items-center justify-center cursor-pointer rounded-sm hover:bg-gray-200 duration-300"
        >
          <img src="@/assets/arrow-right.svg" alt="" />
        </button>
      </div>
    </div>
    <!-- Sidemenu links -->
    <div class="max-h-[calc(100vh-100px)] overflow-y-auto">
      <div v-if="isLoading" class="flex items-center justify-center mt-20">
        <SpinnerLoader />
      </div>
      <div v-else>
        <div class="flex flex-col items-start gap-[10px] mt-6">
          <div
            v-for="phase in phases"
            :key="phase.id"
            class="flex flex-col items-start gap-2 cursor-pointer"
          >
            <div
              class="relative w-max flex flex-col items-start sm:flex-row sm:items-center gap-2 p-[6px] rounded-primary-radius bg-transparent cursor-pointer"
              :class="
                phase.phase.id === selectedPhase
                  ? 'bg-white sm:pr-[22px]'
                  : 'bg-transparent pr-0'
              "
            >
              <img
                class="hidden sm:block absolute opacity-0 group-hover:opacity-100 transition-opacity duration-200 group-hover:mr-3"
                :class="
                  phase.phase.id === selectedPhase ? 'opacity-100' : 'opacity-0'
                "
                src="@/assets/angle-arrow.svg"
                alt="Arrow"
              />
              <p
                class="text-sm leading-[17px] tracking-[0.01rem] text-primary-text-color uppercase font-semibold transition-all duration-200 group-hover:sm:translate-x-4"
                :class="
                  phase.phase.id === selectedPhase
                    ? 'sm:translate-x-4'
                    : 'translate-x-0'
                "
              >
                {{ phase.phase.name }}
              </p>
              <span
                class="text-sm text-primary-text-color font-medium tracking-[0.01rem] rounded-primary-radius py-[2px] px-[6px] transition-all duration-200 group-hover:sm:translate-x-4"
                :class="
                  phase.phase.id === selectedPhase
                    ? 'sm:translate-x-4'
                    : 'translate-x-0'
                "
                :style="`background: ${getProjectStatusColor(phase.name)}`"
                >{{ textTransform(phase.status) }}</span
              >
            </div>
            <div v-if="isPhase">
              <ul
                class="max-w-[200px] w-full ml-2 my-1 2xl:ml-8 pl-2 flex flex-col items-start gap-2 border-l border-gray-300"
              >
                <li
                  v-for="termin in phase.termins"
                  :key="termin.id"
                  class="text-sm tracking-[0.01rem] text-primary-text-color font-medium py-1 px-2 rounded-primary-radius cursor-pointer hover:bg-[#E5E4E2] duration-500"
                  :class="
                    termin.id === terminId ? 'bg-[#E5E4E2]' : 'bg-transparent'
                  "
                >
                  <RouterLink
                    :to="`/projects/${projectId}/termin/${termin.id}`"
                    >{{ termin.name }}</RouterLink
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
