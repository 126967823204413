<script setup lang="ts">
import InputChecklist from "@/components/Re-usable/InputChecklist.vue";
import SelectMenu from "@/components/Re-usable/SelectMenu.vue";
import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";
import { getStatusByName } from "@/utils/report-status";
import { defineEmits, defineProps, ref } from "vue";

type Props = {
  termin: any;
  data: any;
};

type Emits = {
  (event: "close"): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();
const state = ref(props.data);
const isLoading = ref(false);
const isReportLoading = ref(false);

const selectedVorbehalt = ref({
  id: state.value.vorbehalt,
  name: state.value.vorbehalt,
});

const onChangeInput = (name: any, value: any, propertyName?: string) => {
  if (propertyName) {
    let inputIndex = state.value[propertyName].findIndex(
      (item: any) => item.id === Number(name)
    );

    let tempList = [...state.value[propertyName]];
    if (inputIndex != -1) {
      tempList[inputIndex].value = value;
      state.value[propertyName] = tempList;
    }
  } else {
    state.value[name] = value;
  }
};

const addNewInputString = (propertyName: string) => {
  let lastInput =
    state.value[propertyName][state.value[propertyName].length - 1];
  state.value[propertyName].push({
    id: lastInput.id + 1,
    value: "",
  });
};

const onSubmit = async () => {
  isLoading.value = true;
  try {
    state.value.vorbehalt = selectedVorbehalt.value
      ? selectedVorbehalt.value.id
      : null;
    let resp = await client.post(
      `/termin/${props.termin.id}/save-abnahmeprotokoll-report`,
      {
        data: state.value,
      }
    );

    state.value = resp.data.report;
    isLoading.value = false;
    successToast("Successfully saved report.");
  } catch (error: any) {
    isLoading.value = false;
    errorToast(error);
  }
};

const generateReport = async () => {
  isReportLoading.value = true;
  successToast("Your report is being generated, please wait.");
  try {
    await client.post(
      `/termin/${props.termin.id}/generate-abnahmeprotokoll-report`,
      {
        data: state.value,
      }
    );
    isReportLoading.value = false;
    successToast("Successfully generated report.");
  } catch (error: any) {
    isReportLoading.value = false;
    errorToast(error);
  }
};
</script>

<template>
  <div
    class="fixed inset-0 bg-black bg-opacity-80 justify-center items-center z-50 transition-opacity duration-300 ease-in-out p-4"
  >
    <div
      class="max-w-[683px] h-[calc(100vh-32px)] overflow-hidden p-6 mx-auto rounded-primary-radius bg-[#FFFFFF]"
    >
      <!-- header -->
      <div
        class="flex items-center justify-between pb-[16px] border-b border-[#E3E1EA]"
      >
        <!-- left side -->
        <div class="flex items-center justify-start gap-2">
          <img src="@/assets/pencil-icon.svg" alt="Icon" />
          <h3
            class="text-primary-text-color text-xl leading-[32px] font-semibold tracking-[0.01rem]"
          >
            Abnahmeprotokoll
          </h3>
          <p
            class="text-[14px] tracking-[0.01rem] text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius bg-[#A7A5AE]"
          >
            {{ getStatusByName(state?.status) }}
          </p>
        </div>
        <!-- close button -->
        <img
          @click="emits('close')"
          class="cursor-pointer"
          src="@/assets/close-icon-2.svg"
          alt="Close icon"
        />
      </div>
      <!-- Content -->
      <div
        class="h-[calc(100vh-202px)] overflow-y-auto flex flex-col justify-between"
      >
        <div>
          <div class="mt-2">
            <InputChecklist
              title="Folgende Leistungen sind Vertragsbestandteil:"
              checklist-name="folgende_leistungen"
              :data="state?.folgende_leistungen"
              @on-change-input="onChangeInput"
            />
          </div>
          <!-- input field -->
          <div>
            <h4
              class="text-primary-text-color text-xl leading-[32px] pb-2 font-semibold tracking-[0.01rem]"
            >
              Stringmessung wurde durchgeführt
            </h4>
            <div class="flex flex-col gap-2">
              <div
                v-for="(string, index) in state.stringmessung"
                :key="string.id"
              >
                <input
                  :name="string.id"
                  class="w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]"
                  type="text"
                  :value="string.value"
                  @change="
                    (event:any) =>
                      onChangeInput(
                        string.id,
                        event.target.value,
                        'stringmessung'
                      )
                  "
                  :placeholder="`String ${index + 1} VDC:`"
                />
              </div>
            </div>

            <div
              class="hover:cursor-pointer mx-auto mt-3 flex justify-center"
              @click="addNewInputString('stringmessung')"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10Z"
                  fill="white"
                />
                <path
                  d="M6 10H10M10 10H14M10 10V14M10 10V6M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19Z"
                  stroke="#393840"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <!-- Select menu -->
          <div class="mt-[40px]">
            <h4
              class="text-primary-text-color text-xl leading-[32px] pb-2 font-semibold tracking-[0.01rem]"
            >
              Für die Installation einer Photovoltaikanlage mit den oben
              genannten ergänzenden Leistungen erfolgt hiermit die Abnahme
            </h4>
            <div class="flex flex-col gap-2">
              <SelectMenu
                v-model="selectedVorbehalt"
                :options="data.vorbehalt_options.map((item:any)=>{
                return {
                  id:item,
                  name:item
                }
              })"
              />
              <div
                v-if="selectedVorbehalt?.id === 'mit Vorbehalt'"
                class="flex flex-col gap-2"
              >
                <p class="text-base text-[#898790]">
                  Sofern vorhanden, Nennung der Vorbehalte:
                </p>
                <div
                  v-for="item in state.mit_vorbehalt"
                  :key="item.id"
                  class="flex"
                >
                  <div
                    class="w-[150px] bg-[#f9f8f6] border border-[#e3e1ea] rounded-l p-3 flex items-center"
                  >
                    {{ item.id }}.
                  </div>
                  <input
                    :name="item.id"
                    class="w-full bg-white text-base font-medium text-[#898790] outline-none p-3 border border-[#E3E1EA] rounded-r"
                    type="text"
                    :value="item.value"
                    @change="
                    (event:any) =>
                      onChangeInput(
                        item.id,
                        event.target.value,
                        'mit_vorbehalt'
                      )
                  "
                  />
                </div>
                <div
                  class="hover:cursor-pointer mx-auto mt-3 flex justify-center"
                  @click="addNewInputString('mit_vorbehalt')"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10Z"
                      fill="white"
                    />
                    <path
                      d="M6 10H10M10 10H14M10 10V14M10 10V6M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19Z"
                      stroke="#393840"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <p class="text-base text-[#898790] mt-3 mb-2">
              Folgende Arbeiten wurden über den Leistungsumfang hinaus
              vereinbart und nachträglich durchgeführt oder vor Ort nach
              Rücksprache mit dem Kunden vereinbart: Die Kosten für diese
              Arbeiten sind nicht im Angebot aufgeführt und werden separat in
              Rechnung gestellt.
            </p>
            <div class="flex flex-col gap-2">
              <div v-for="item in state.table_2" :key="item.id" class="flex">
                <div
                  class="w-[150px] bg-[#f9f8f6] border border-[#e3e1ea] rounded-l p-3 flex items-center"
                >
                  {{ item.id }}.
                </div>
                <input
                  :name="item.id"
                  class="w-full bg-white text-base font-medium text-[#898790] outline-none p-3 border border-[#E3E1EA] rounded-r"
                  type="text"
                  :value="item.value"
                  @change="
                    (event:any) =>
                      onChangeInput(
                        item.id,
                        event.target.value,
                        'table_2'
                      )
                  "
                />
              </div>
              <div
                class="hover:cursor-pointer mx-auto mt-3 flex justify-center"
                @click="addNewInputString('table_2')"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10Z"
                    fill="white"
                  />
                  <path
                    d="M6 10H10M10 10H14M10 10V14M10 10V6M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19Z"
                    stroke="#393840"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <p class="text-base text-[#898790] mt-2">
              Hiermit wird bestätigt, dass die Photovoltaikanlage erst in
              Betrieb gehen darf, nachdem der Wechsel des alten Zählers durch
              einen Zweirichtungszähler durchgeführt wurde bzw. nach Zustimmung
              des Netzbetreibers.
            </p>
          </div>
        </div>
      </div>
      <!-- Foooter -->
      <div
        class="w-full flex items-center justify-end mt-3 pt-3 border-t border-[#E3E1EA]"
      >
        <div class="flex items-center gap-4">
          <button
            :disabled="isReportLoading"
            @click="generateReport"
            class="flex items-center gap-1 py-3 px-[16px] text-base text-primary-text-color bg-transparent tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer duration-200"
          >
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              :class="`${isReportLoading ? 'animate-spin' : ''}`"
            >
              <path
                d="M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179"
                stroke="#393840"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Generate report
          </button>
          <button
            :disabled="isLoading"
            @click="onSubmit"
            class="w-[150px] flex items-center justify-center py-3 px-[56px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#28272c] duration-200"
          >
            <div
              v-if="isLoading"
              class="w-6 h-6 border-4 border-white border-t-transparent rounded-full animate-spin"
            ></div>
            <span v-else>Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
