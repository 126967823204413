import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, unref as _unref, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/smalt-home-logo.svg'
import _imports_1 from '@/assets/arrow-left.svg'
import _imports_2 from '@/assets/arrow-right.svg'
import _imports_3 from '@/assets/angle-arrow.svg'


const _hoisted_1 = { class: "hidden lg:flex flex-col relative min-h-[calc(100vh-28px)] w-[230px] pr-3 sm:min-h-[calc(100vh-48px)] mr-6 sm:mr-0 sm:pl-0" }
const _hoisted_2 = { class: "flex items-center gap-2" }
const _hoisted_3 = { class: "flex items-center gap" }
const _hoisted_4 = { class: "max-h-[calc(100vh-100px)] overflow-y-auto" }
const _hoisted_5 = {
  key: 0,
  class: "flex items-center justify-center mt-20"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "flex flex-col items-start gap-[10px] mt-6" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "max-w-[200px] w-full ml-2 my-1 2xl:ml-8 pl-2 flex flex-col items-start gap-2 border-l border-gray-300" }

import { textTransform } from "@/utils/parse-data";
import { getProjectStatusColor } from "@/utils/status-color";
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import SpinnerLoader from "../Loaders/SpinnerLoader.vue";

type Props = {
  phases?: any;
  selectedTermin?: any;
  selectedPhase?: number;
  isLoading: boolean;
};
type Emits = {
  (event: "onTerminSelect", phaseId: number): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TerminSideMenu',
  props: {
    phases: {},
    selectedTermin: {},
    selectedPhase: {},
    isLoading: { type: Boolean }
  },
  emits: ["onTerminSelect"],
  setup(__props: any, { emit: __emit }) {

const route = useRoute();
const projectId = route.params.id;
const terminId = route.params.terminId;

const isPhase = ref(true);

const props = __props;

const emits = __emit;

const isLoading = computed(() => props.isLoading);

const phases = ref(props.phases);

const selectTermin = (terminId: number) => {
  emits("onTerminSelect", terminId);
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RouterLink, {
        to: "/",
        class: "w-[26px] h-[26px] flex items-center justify-center cursor-pointer"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Smalt icon"
          }, null, -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
          class: "w-6 h-6 flex items-center justify-center cursor-pointer rounded-sm hover:bg-gray-200 duration-200"
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1)
        ])),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.go(+1))),
          class: "w-6 h-6 flex items-center justify-center cursor-pointer rounded-sm hover:bg-gray-200 duration-300"
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("img", {
            src: _imports_2,
            alt: ""
          }, null, -1)
        ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (isLoading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(SpinnerLoader)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(phases.value, (phase) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: phase.id,
                  class: "flex flex-col items-start gap-2 cursor-pointer"
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["relative w-max flex flex-col items-start sm:flex-row sm:items-center gap-2 p-[6px] rounded-primary-radius bg-transparent cursor-pointer", 
                phase.phase.id === _ctx.selectedPhase
                  ? 'bg-white sm:pr-[22px]'
                  : 'bg-transparent pr-0'
              ])
                  }, [
                    _createElementVNode("img", {
                      class: _normalizeClass(["hidden sm:block absolute opacity-0 group-hover:opacity-100 transition-opacity duration-200 group-hover:mr-3", 
                  phase.phase.id === _ctx.selectedPhase ? 'opacity-100' : 'opacity-0'
                ]),
                      src: _imports_3,
                      alt: "Arrow"
                    }, null, 2),
                    _createElementVNode("p", {
                      class: _normalizeClass(["text-sm leading-[17px] tracking-[0.01rem] text-primary-text-color uppercase font-semibold transition-all duration-200 group-hover:sm:translate-x-4", 
                  phase.phase.id === _ctx.selectedPhase
                    ? 'sm:translate-x-4'
                    : 'translate-x-0'
                ])
                    }, _toDisplayString(phase.phase.name), 3),
                    _createElementVNode("span", {
                      class: _normalizeClass(["text-sm text-primary-text-color font-medium tracking-[0.01rem] rounded-primary-radius py-[2px] px-[6px] transition-all duration-200 group-hover:sm:translate-x-4", 
                  phase.phase.id === _ctx.selectedPhase
                    ? 'sm:translate-x-4'
                    : 'translate-x-0'
                ]),
                      style: _normalizeStyle(`background: ${_unref(getProjectStatusColor)(phase.name)}`)
                    }, _toDisplayString(_unref(textTransform)(phase.status)), 7)
                  ], 2),
                  (isPhase.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("ul", _hoisted_9, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(phase.termins, (termin) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: termin.id,
                              class: _normalizeClass(["text-sm tracking-[0.01rem] text-primary-text-color font-medium py-1 px-2 rounded-primary-radius cursor-pointer hover:bg-[#E5E4E2] duration-500", 
                    termin.id === _unref(terminId) ? 'bg-[#E5E4E2]' : 'bg-transparent'
                  ])
                            }, [
                              _createVNode(_component_RouterLink, {
                                to: `/projects/${_unref(projectId)}/termin/${termin.id}`
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(termin.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"])
                            ], 2))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]))
    ])
  ]))
}
}

})