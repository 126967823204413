<script setup lang="ts">
import MainLayout from "@/layouts/MainLayout.vue";
import ProjectOverview from "@/components/Project/ProjectOverview.vue";
import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import { onMounted, ref } from "vue";
import client from "@/utils/axios";
import { useRoute } from "vue-router";
import { Project } from "@/types";

const selectedPhase = ref();
const isLoading = ref(false);

const onPhaseSelect = (phaseId: number) => {
  selectedPhase.value = phaseId;
};

const route = useRoute();
const id = route.params.id;
const projectDetails = ref<Project | null>(null);

// Fetch data
const getProjectDetails = async () => {
  isLoading.value = true;
  try {
    const response = await client.get(`/projects/${id}`);
    projectDetails.value = response.data;
    console.log(response.data);
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};
onMounted(() => {
  getProjectDetails();
});
//
</script>

<template>
  <MainLayout>
    <div class="h-full flex relative">
      <div
        class="desktop-custom:absolute desktop-custom:top-0 desktop-custom:left-0"
      >
        <MainSidebar
          :is-loading="isLoading"
          :phase-progress="projectDetails?.phases"
          :phase-steps="[]"
          :selected-phase="selectedPhase"
          @on-phase-select="onPhaseSelect"
          :is-link="true"
        />
      </div>
      <div class="w-full mx-auto">
        <ProjectOverview
          :project-details="projectDetails"
          :is-loading="isLoading"
        />
      </div>
      <div v-if="projectDetails?.notionId" class="w-fit ml-auto">
        <a
          :href="`https://smalt.lightning.force.com/lightning/r/Project__c/${projectDetails?.notionId}/view`"
          target="_blank"
          class="w-9 h-9"
        >
          <img
            class="w-9 h-9 cursor-pointer"
            src="@/assets/salesforce.svg"
            alt=""
        /></a>
      </div>
    </div>
  </MainLayout>
</template>
