import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/delete-modal-icon.svg'


const _hoisted_1 = { class: "w-[360px] flex flex-col gap-[32px] items-center p-6 mx-3 bg-white rounded-primary-radius" }
const _hoisted_2 = { class: "flex flex-col gap-2 items-center" }
const _hoisted_3 = { class: "text-[20px] tracking-[0.01rem] text-primary-text-color font-semibold" }
const _hoisted_4 = { class: "flex items-center gap-4" }
const _hoisted_5 = {
  key: 0,
  class: "w-6 h-6 border-4 border-white border-t-transparent rounded-full animate-spin"
}
const _hoisted_6 = { key: 1 }

import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";
import { ref } from "vue";

type Props = {
  title: string;
  terminId: string;
  id: string;
  isNote?: boolean;
  isMeisterNote?: boolean;
};

type Emits = {
  (event: "togglePopup"): void;
  (event: "refresh"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'DeletePopup',
  props: {
    title: {},
    terminId: {},
    id: {},
    isNote: { type: Boolean },
    isMeisterNote: { type: Boolean }
  },
  emits: ["togglePopup", "refresh"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;
const isLoading = ref(false);

const handleCancelClick = (event: Event) => {
  event.stopPropagation();
  emits("togglePopup");
};

const handleDeleteClick = async (event: Event) => {
  event.stopPropagation();
  isLoading.value = true;

  if (props.terminId && props.id) {
    let resp: any = {};

    if (props.isMeisterNote) {
      resp = await client.delete(
        `/termin/${props.terminId}/meister-note/${props.id}`
      );
    } else {
      resp = await client.delete(
        `/termin/${props.terminId}/${props.isNote ? "note" : "issue"}/${
          props.id
        }`
      );
    }

    if (resp.status === 200) {
      isLoading.value = false;
      emits("refresh");
      successToast("Succesfully deleted " + props.title + ".");
      emits("togglePopup");
      return;
    } else {
      errorToast("Something went wrong.");
    }
  }
  errorToast("Insufficient data passed.");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        alt: "Delete"
      }, null, -1)),
      _createElementVNode("p", _hoisted_3, " Delete this " + _toDisplayString(_ctx.title) + "? ", 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        onClick: handleCancelClick,
        class: "flex w-[150px] items-center justify-center py-3 px-[48px] text-base text-[#393840] bg-transparent tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#efeeec] duration-200"
      }, " Cancel "),
      _createElementVNode("button", {
        onClick: handleDeleteClick,
        class: "flex items-center w-[150px] justify-center py-3 px-[48px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#28272c] duration-200"
      }, [
        (isLoading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5))
          : (_openBlock(), _createElementBlock("span", _hoisted_6, "Delete"))
      ])
    ])
  ]))
}
}

})