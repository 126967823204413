<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

type Props = {
  step: any;
};

const props = defineProps<Props>();

type Emits = {
  (event: "setSelectedInput", input: any): void;
};

defineEmits<Emits>();
</script>

<template>
  <div
    class="w-full p-3 bg-white rounded-primary-radius shadow-primary-box-shadow"
  >
    <h3
      class="text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color pb-4 font-semibold"
    >
      Images
    </h3>
    <!-- Image inner -->
    <div class="flex flex-col items-start gap-2 -ml-[3px]">
      <!-- Image block row -->
      <div
        v-for="input in props.step.inputs.filter((item:any)=> item.type === 'image' && item.value.length === 0)"
        :key="input.id"
        @click="$emit('setSelectedInput', input)"
        class="group border py-[3px] border-transparent hover:cursor-pointer hover:bg-[#F9F8F6] hover:border-[#E3E1EA] hover:rounded-[5px] w-full flex items-center gap-1"
      >
        <h4
          class="text-base leading-5 font-normal tracking-[0.01rem] text-primary-text-color"
        >
          <span
            v-if="input.parentId !== null"
            class="text-sm font-sans font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]"
          >
            {{ step.inputs.find((i: any) => i.id === input.parentId).name }} /
          </span>
          {{ input.name }}
        </h4>
        <img
          class="w-3 h-3 mr-[5px] invisible group-hover:visible ml-auto"
          src="@/assets/pencil-icon.svg"
          alt="Icon"
        />
      </div>
    </div>
  </div>
</template>
