import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/edit-plus-icon.svg'
import _imports_1 from '@/assets/close-icon-2.svg'


const _hoisted_1 = { class: "absolute overflow-y-auto top-5 w-[630px] p-6 mx-3 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 rounded-lg shadow-lg max-w-md" }
const _hoisted_2 = { class: "flex items-center justify-between border-b border-gray-300 pb-3" }
const _hoisted_3 = { class: "flex items-center gap-[10px]" }
const _hoisted_4 = { class: "text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold" }
const _hoisted_5 = { class: "mt-6" }
const _hoisted_6 = { class: "mt-6" }
const _hoisted_7 = {
  key: 0,
  class: "flex w-full overflow-x-auto gap-1 mt-6 whitespace-nowrap scrollable-container"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "flex items-center gap-2 mt-[36px]" }
const _hoisted_10 = { class: "flex justify-end" }
const _hoisted_11 = {
  key: 0,
  class: "w-6 h-6 border-4 border-white border-t-transparent rounded-full animate-spin"
}
const _hoisted_12 = { key: 1 }

import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";
import { reactive, ref } from "vue";

type Props = {
  isOpen: any;
  terminId: string;
};

type Emits = {
  (event: "toggleModal"): void;
  (event: "refresh"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MeisterNoteModal',
  props: {
    isOpen: {},
    terminId: {}
  },
  emits: ["toggleModal", "refresh"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const files = ref([]);
const isLoading = ref(false);

const handleFileChange = (event: any) => {
  files.value = Array.from(event.target.files);
  imagePreviews.value = [
    ...imagePreviews.value,
    ...files.value.map((file: any) => URL.createObjectURL(file)),
  ];
};

const imagePreviews = ref<string[]>([]);

const state = reactive({
  title: "",
  description: "",
});

const onSubmit = async () => {
  isLoading.value = true;

  const formData = new FormData();
  formData.append("title", state.title);
  formData.append("description", state.description);

  if (files.value) {
    files.value.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
  }

  try {
    await client.post(`/termin/${props.terminId}/meister-note`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    isLoading.value = false;
    successToast("Successfully added meister note.");
    emits("refresh");
    emits("toggleModal");
  } catch (error: any) {
    errorToast(error);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fixed overflow-y-auto inset-0 bg-black bg-opacity-80 justify-center items-center z-50 transition-opacity duration-300 ease-in-out", `${_ctx.isOpen ? 'flex' : 'hidden'}`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("img", {
            src: _imports_0,
            alt: "Icon"
          }, null, -1)),
          _createElementVNode("h4", _hoisted_4, _toDisplayString(state.title ? "Edit Note" : "New Meister Note"), 1)
        ]),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('toggleModal')))
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("img", {
            src: _imports_1,
            alt: "Close icon"
          }, null, -1)
        ]))
      ]),
      _createElementVNode("form", {
        onSubmit: _withModifiers(onSubmit, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.title) = $event)),
            class: "w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] placeholder:text-primary-text-color",
            type: "text",
            placeholder: "Title"
          }, null, 512), [
            [_vModelText, state.title]
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((state.description) = $event)),
            rows: "7",
            class: "w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] resize-none placeholder:text-primary-text-color",
            placeholder: "Description"
          }, null, 512), [
            [_vModelText, state.description]
          ])
        ]),
        (imagePreviews.value.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(imagePreviews.value, (src, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "shrink-0 w-[123px] h-[123px]"
                }, [
                  _createElementVNode("img", {
                    src: src,
                    alt: "Uploaded image",
                    class: "shrink-0 object-cover w-[123px] h-[123px] rounded-md"
                  }, null, 8, _hoisted_8)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _cache[5] || (_cache[5] = _createElementVNode("label", {
            for: "file",
            class: "px-4 py-2 text-[#393840] font-medium rounded-md cursor-pointer transition flex items-center border-2 border-[#393840] w-fit hover:bg-[#F9F8F6]"
          }, [
            _createElementVNode("svg", {
              width: "15",
              height: "16",
              viewBox: "0 0 15 16",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M1.40186 7.59852L6.57257 2.4278C8.11026 0.890114 10.6034 0.890114 12.1411 2.4278C13.6788 3.96549 13.6786 6.45872 12.1409 7.99641L6.17468 13.9626C5.14956 14.9877 3.48778 14.9876 2.46265 13.9625C1.43753 12.9373 1.43728 11.2755 2.4624 10.2503L8.42862 4.28413C8.94118 3.77156 9.77265 3.77156 10.2852 4.28413C10.7978 4.79669 10.7974 5.62752 10.2849 6.14008L5.11414 11.3108",
                stroke: "#393840",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              })
            ]),
            _createTextVNode(" Attach Files ")
          ], -1)),
          _createElementVNode("input", {
            multiple: "",
            class: "hidden",
            type: "file",
            id: "file",
            onChange: handleFileChange
          }, null, 32)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            onClick: onSubmit,
            class: "mt-5 w-[150px] flex items-center justify-center py-3 px-[56px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#28272c] duration-200"
          }, [
            (isLoading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11))
              : (_openBlock(), _createElementBlock("span", _hoisted_12, "Save"))
          ])
        ])
      ], 32)
    ])
  ], 2))
}
}

})