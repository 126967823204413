<script setup lang="ts">
import { Project } from "@/types";
import { computed, defineProps, ref } from "vue";
import CardLoader from "@/components/Loaders/CardLoader.vue";

type Props = {
  isLoading: boolean;
  projectDetails: Project | null;
};
const props = defineProps<Props>();

const selectedPage = ref<number>(1);

let documentPages = computed(() => {
  if (props.projectDetails?.documents) {
    let documentSections = [
      {
        id: 1,
        title: "Baumappe",
        typeIds: [4],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type?.id === 4
        ),
      },
      {
        id: 2,
        title: "Phase reports",
        typeIds: [5, 6, 7],
        documents: props.projectDetails?.documents.filter(
          (item: any) =>
            item.project_document_type?.id === 5 ||
            item.project_document_type?.id === 6 ||
            item.project_document_type?.id === 7
        ),
      },
      {
        id: 3,
        title: "Abnahme protokol",
        typeIds: [2],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type?.id === 2
        ),
      },
      {
        id: 4,
        title: "Prüfbericht",
        typeIds: [3],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type?.id === 3
        ),
      },
      {
        id: 5,
        title: "Other",
        typeIds: [null],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type === null
        ),
      },
    ];

    const filteredSections = documentSections.filter(
      (section: any) => section.documents && section.documents.length > 0
    );

    const pages = [];
    let pageNumber = 1;
    for (let i = 0; i < filteredSections.length; i += 2) {
      pages.push({
        id: pageNumber,
        sections: filteredSections.slice(i, i + 2),
      });
      pageNumber++;
    }

    return pages;
  }

  return [];
});
</script>

<template>
  <div class="h-[356px] w-full p-3 bg-white rounded-primary-radius">
    <div class="h-full overflow-hidden">
      <div class="flex items-center justify-between mb-2">
        <h2
          class="text-xl text-primary-text-color tracking-[0.01rem] font-semibold text-prim"
        >
          Documents
        </h2>
        <div v-if="documentPages.length > 0" class="flex items-center gap-6">
          <button
            :disabled="selectedPage === 1"
            class="cursor-pointer"
            @click="selectedPage = selectedPage - 1"
          >
            <img
              v-if="selectedPage === 1"
              src="@/assets/arrow-left-gray.svg"
              alt=""
            />
            <img
              v-if="selectedPage !== 1"
              src="@/assets/arrow-left.svg"
              alt=""
            />
          </button>
          <button
            :disabled="selectedPage === documentPages.length"
            class="cursor-pointer"
            @click="selectedPage = selectedPage + 1"
          >
            <img
              v-if="selectedPage === documentPages.length"
              src="@/assets/arrow-right-gray.svg"
              alt="Arrow right"
            />
            <img
              v-show="selectedPage !== documentPages.length"
              src="@/assets/arrow-right.svg"
              alt="Arrow right"
            />
          </button>
        </div>
      </div>
      <CardLoader v-if="isLoading" />
      <div
        v-else
        class="w-full h-[290px] overflow-y-auto flex flex-col items-start gap-2"
      >
        <div
          v-for="page in documentPages"
          :key="page.id"
          v-show="page.id === selectedPage"
          class="w-full flex flex-col gap-2"
        >
          <div
            v-for="section in page?.sections"
            :key="section.id"
            class="p-2 rounded-primary-radius border border-gray-300"
          >
            <h5
              class="text-base leading-[20px] text-[#898790] font-semibold tracking-[0.01rem] py-1"
            >
              {{ section.title }}
            </h5>
            <ul class="list-none flex flex-col gap-[2px]">
              <li
                v-for="(document, index) in section?.documents"
                :key="document.id"
                :class="{ 'border-t border-[#E3E1EA]': index !== 0 }"
                class="flex items-center justify-between"
              >
                <span
                  class="flex-[80%] text-base text-primary-text-color font-normal tracking-[0.01rem] py-[6px]"
                >
                  {{ document.name }}
                </span>
                <div class="flex-[20%] flex items-center justify-end">
                  <a
                    class="bg-transparent p-[2px] rounded-primary-radius cursor-pointer hover:bg-[#e5e4e2] duration-300"
                    :href="`${document.url}?download`"
                    target="_blank"
                  >
                    <img
                      class="min-w-[16px] w-[16px] min-h-[16px]"
                      src="@/assets/download-icon.svg"
                      alt=""
                    />
                  </a>
                  <a
                    class="bg-transparent p-[2px] rounded-primary-radius cursor-pointer hover:bg-[#e5e4e2] duration-300"
                    :href="document.url + '?' + new Date().toISOString()"
                    target="_blank"
                  >
                    <img
                      class="min-w-[16px] w-[16px] min-h-[16px]"
                      src="@/assets/arrow-up-right.svg"
                      alt=""
                    />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          v-if="documentPages.length === 0"
          class="w-full mt-[70px] flex items-center justify-center"
        >
          <div>
            <img src="@/assets/no-documents-image.svg" alt="No documents" />
            <p
              class="text-sm mt-2 tracking-[0.01rem] font-medium text-[#898790]"
            >
              No documents
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
