<script setup lang="ts">
import { Project } from "@/types";
import BasicDetails from "./BasicDetails.vue";
import ProjectDocuments from "./ProjectDocuments.vue";
import TerminTimeline from "./TerminTimeline.vue";
import ProjectAppointments from "./ProjectAppointments.vue";
import ThreeBlockRow from "./ThreeBlockRow.vue";
import { defineProps } from "vue";
import { textTransform } from "@/utils/parse-data";
import { ref } from "vue";
import InlineLoader from "@/components/Loaders/InlineLoader.vue";

type Props = {
  projectDetails: Project | null;
  isLoading: boolean;
};

const isTimeline = ref(true);
defineProps<Props>();
</script>

<template>
  <!-- Project overview -->
  <div class="max-w-[934px] mx-auto h-full">
    <!-- Header -->
    <div
      class="flex flex-col items-start sm:flex-row sm:items-center justify-between"
    >
      <!-- Left -->
      <div class="flex items-center gap-2 pb-[9px]">
        <h2
          class="text-xl leading-[26px] font-medium text-primary-text-color tracking-[0.01rem]"
        >
          <InlineLoader v-if="isLoading" />
          <span
            v-else
            class="text-xl leading-[26px] font-medium text-primary-text-color tracking-[0.01rem]"
          >
            {{ projectDetails?.name ? projectDetails?.name : "" }}
          </span>
        </h2>
        <InlineLoader v-if="isLoading" />
        <span
          v-else
          class="text-sm tracking-[0.01rem] text-primary-text-color px-[6px] py-[2px] bg-[#E3E1EA] rounded-primary-radius"
          >{{
            projectDetails ? textTransform(projectDetails?.status) : ""
          }}</span
        >
      </div>
      <!-- Right -->
      <div v-if="projectDetails?.customerLogo">
        <img
          :src="projectDetails?.customerLogo"
          alt="Customer logo"
          width="84"
        />
      </div>
    </div>
    <!-- Project overview -->
    <div class="h-full overflow-y-scroll">
      <div>
        <ThreeBlockRow
          :is-loading="isLoading"
          :customer-name="projectDetails ? projectDetails?.name : ''"
          :cost-carrier-code="
            projectDetails ? projectDetails?.costCarrierCode + '' : ''
          "
          :meister="projectDetails ? projectDetails?.meister : ''"
          :next-termin="projectDetails ? projectDetails.nextTermin : ''"
          :last-termin="projectDetails ? projectDetails.lastTermin : ''"
        />
      </div>
      <div>
        <BasicDetails
          :is-loading="isLoading"
          :vnb="projectDetails?.vnb"
          :wechselrichter="projectDetails?.wechselrichter"
          :speicher="projectDetails?.speicher"
          :masskonzept="projectDetails?.masskonzept"
          :wb="projectDetails?.wb"
          :number-of-modules="projectDetails?.numberOfModules"
          :modul-type="projectDetails?.modulType"
          :ibn-date="projectDetails?.ibnDate"
          :home-owner-name="projectDetails?.homeownerName"
          :home-owner-address="projectDetails?.homeownerAddress"
          :home-owner-phone="projectDetails?.homeownerPhone"
          :email-address="projectDetails?.ownerEmail"
        />
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-3 mb-5">
        <div>
          <div v-if="isTimeline">
            <TerminTimeline
              :is-loading="isLoading"
              :projectDetails="projectDetails"
              @show-appointments="isTimeline = false"
            />
          </div>
          <div v-if="!isTimeline">
            <ProjectAppointments
              :projectDetails="projectDetails"
              @show-timeline="isTimeline = true"
            />
          </div>
        </div>
        <div>
          <ProjectDocuments
            :is-loading="isLoading"
            :projectDetails="projectDetails"
          />
        </div>
      </div>
    </div>
  </div>
</template>
