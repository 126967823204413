<script setup lang="ts">
import HeadBlocks from "@/components/Project/Appointment/HeadBlocks.vue";
import IssuesBlock from "@/components/Project/Appointment/IssuesBlock.vue";
import MeisterNoteBlock from "@/components/Project/Appointment/MeisterNoteBlock.vue";
import PrufBerichtModal from "@/components/Project/Appointment/Modal/PrufBerichtModal.vue";
import AbnameProtocolModal from "@/components/Project/Appointment/Modal/AbnameProtocolModal.vue";
import NoteBlock from "@/components/Project/Appointment/NoteBlock.vue";
import client from "@/utils/axios";
import { errorToast } from "@/utils/custom-toast";
import { getStatusByName } from "@/utils/report-status";
import { defineProps, defineEmits, ref, onMounted } from "vue";
import { textTransform } from "@/utils/parse-data";
import { RouterLink, useRoute } from "vue-router";

type Props = {
  termin: any;
  projectName: string;
};

type Emits = {
  (event: "refresh"): void;
};

const props = defineProps<Props>();
defineEmits<Emits>();

const route = useRoute();
const path = route.path;
const transformedPath = path.split("/")[2];

const prufberichtModalOpen = ref(false);
const abnahmeProtocolModalOpen = ref(false);
const prufberichtReportData = ref(null);
const abnahmeprotokollReportData = ref(null);

const getPrufberichtReportData = async () => {
  try {
    let resp = await client.get(
      `/termin/${props.termin.id}/prufbericht-report`
    );

    prufberichtReportData.value = resp.data;
  } catch (error: any) {
    errorToast(error);
  }
};

const getAbnahmeprotokollReportData = async () => {
  try {
    let resp = await client.get(
      `/termin/${props.termin.id}/abnahmeprotokoll-report`
    );

    abnahmeprotokollReportData.value = resp.data;
  } catch (error: any) {
    errorToast(error);
  }
};

const openPrufberichtModal = async () => {
  await getPrufberichtReportData();
  prufberichtModalOpen.value = true;
};
const openAbnahmeprotokollModal = async () => {
  await getAbnahmeprotokollReportData();
  abnahmeProtocolModalOpen.value = true;
};

onMounted(async () => {
  if (props.termin.phase_progress.phase.id === 2) {
    await getPrufberichtReportData();
    await getAbnahmeprotokollReportData();
  }
});
</script>

<template>
  <div class="w-full h-[calc(100vh-42px)] pl-3 sm:pl-0 overflow-y-auto">
    <div class="max-w-[934px] mx-auto">
      <!-- Header -->
      <div class="flex items-center justify-between pb-[9px]">
        <div class="flex items-center gap-[2px]">
          <div class="relative group">
            <RouterLink
              :to="`/projects/${transformedPath}`"
              class="relative text-primary-text-color text-xl leading-[26px] tracking-[0.01rem] font-medium after:absolute after:bottom-[2px] after:left-0 after:w-full after:h-[1px] after:bg-primary-text-color after:opacity-0 group-hover:after:opacity-100 after:transition-opacity after:duration-200"
            >
              {{ projectName ? projectName : "" }}
            </RouterLink>
          </div>
          <img src="@/assets/chevron-right.svg" alt="Caret right" />
          <p
            class="text-xl leading-[26px] tracking-[0.01rem] text-[#616068] font-medium"
          >
            {{ termin.name }}
          </p>
        </div>
        <span
          class="text-xs text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius bg-[#A7A5AE]"
          >{{ textTransform(termin.termin_status?.name) }}</span
        >
      </div>
      <!-- Head blocks -->
      <div>
        <HeadBlocks :termin="termin" />
      </div>
      <div
        v-if="termin.phase_progress?.phase.id === 2"
        class="flex items-center gap-[12.5px]"
      >
        <!-- Abnahme protocol -->
        <div class="flex-1">
          <div
            @click="openAbnahmeprotokollModal"
            class="flex items-center justify-between p-3 rounded-primary-radius bg-[#E5E4E2] cursor-pointer hover:bg-[#dfdedc] duration-500"
          >
            <h3
              class="text-primary-text-color text-xl leading-[32px] font-semibold tracking-[0.01rem]"
            >
              Abnameprotokoll
            </h3>
            <p
              class="text-[14px] tracking-[0.01rem] text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius bg-[#A7A5AE]"
            >
              {{
                abnahmeprotokollReportData
                  ? getStatusByName(abnahmeprotokollReportData?.status)
                  : ""
              }}
            </p>
          </div>
          <!-- Abnahme protocol modal -->
          <div v-if="abnahmeProtocolModalOpen">
            <AbnameProtocolModal
              :termin="termin"
              :data="abnahmeprotokollReportData"
              @close="abnahmeProtocolModalOpen = false"
            />
          </div>
        </div>
        <!-- Prufbericht -->
        <div class="flex-1">
          <div
            @click="openPrufberichtModal"
            class="flex items-center justify-between p-3 rounded-primary-radius bg-[#E5E4E2] cursor-pointer hover:bg-[#dfdedc] duration-500"
          >
            <h3
              class="text-primary-text-color text-xl leading-[32px] font-semibold tracking-[0.01rem]"
            >
              Prüfbericht
            </h3>
            <p
              class="text-[14px] tracking-[0.01rem] text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius bg-[#A7A5AE]"
            >
              {{
                prufberichtReportData
                  ? getStatusByName(prufberichtReportData?.status)
                  : ""
              }}
            </p>
          </div>
          <!-- Prufbericht modal -->
          <div v-if="prufberichtModalOpen && prufberichtReportData">
            <PrufBerichtModal
              :termin="termin"
              :data="prufberichtReportData"
              @close="prufberichtModalOpen = false"
            />
          </div>
        </div>
      </div>
      <!-- Issues block -->
      <div v-if="termin.issues" class="mt-3">
        <IssuesBlock
          :issue-types="termin.issueTypes?.map((item:any)=> {
            return {
              id: item.id,
              name: item.name
            }
          })"
          :issues="termin.issues"
          :termin="termin.id"
          @refresh="$emit('refresh')"
        />
      </div>
      <!-- Notes block -->
      <div v-if="termin.notes" class="mt-3">
        <NoteBlock
          :termin="termin.id"
          @refresh="$emit('refresh')"
          :notes="termin.notes"
        />
      </div>

      <!-- Note block -->
      <div class="mt-3">
        <MeisterNoteBlock
          :termin="termin.id"
          :meister-note="termin.meisterNote"
          @refresh="$emit('refresh')"
        />
      </div>
    </div>
  </div>
</template>
