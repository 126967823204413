import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/pencil-icon.svg'


const _hoisted_1 = { class: "w-full list-none flex flex-col items-start gap-3" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "relative text-base text-primary-text-color font-normal leading-5 tracking-[0.01rem]" }
const _hoisted_4 = {
  key: 0,
  class: "text-sm font-sans font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]"
}



type Props = {
  step: any;
};
type Emits = {
  (event: "toggleModalHandler", input: any): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InputFieldsBlock',
  props: {
    step: {}
  },
  emits: ["toggleModalHandler"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold" }, " Input fields ", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.step.inputs.filter((input) => input.type !== 'image' &&  input.type !== 'group' && !input.value), (input) => {
      return (_openBlock(), _createElementBlock("div", {
        key: input.id,
        class: "my-3"
      }, [
        _createElementVNode("ul", _hoisted_1, [
          _createElementVNode("li", {
            onClick: ($event: any) => (emit('toggleModalHandler', input)),
            class: "group hover:cursor-pointer hover:bg-[#F9F8F6] hover:outline hover:outline-[#E3E1EA] p-[3px] hover:rounded-[5px] w-full flex gap-1 items-center outline-1 outline-gray-100 pb-1"
          }, [
            _createElementVNode("p", _hoisted_3, [
              (input.parentId !== null)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.step.inputs.find((i) => i.id === input.parentId).name) + " / ", 1))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(input.name), 1)
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("img", {
              class: "w-3 h-3 invisible group-hover:visible ml-auto",
              src: _imports_0,
              alt: "Icon"
            }, null, -1))
          ], 8, _hoisted_2)
        ])
      ]))
    }), 128))
  ]))
}
}

})