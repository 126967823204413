import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/pencil-icon.svg'
import _imports_1 from '@/assets/close-icon-2.svg'


const _hoisted_1 = { class: "fixed inset-0 bg-black bg-opacity-80 justify-center items-center z-50 transition-opacity duration-300 ease-in-out p-4" }
const _hoisted_2 = { class: "w-full h-[calc(100vh-32px)] overflow-y-auto rounded-primary-radius p-5 bg-[#FFFFFF] relative" }
const _hoisted_3 = { class: "w-full flex h-16 bg-white absolute top-0 left-0 items-center justify-between p-5 border-b border-[#E3E1EA]" }
const _hoisted_4 = { class: "flex items-center justify-start gap-2" }
const _hoisted_5 = { class: "text-[14px] tracking-[0.01rem] text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius bg-[#A7A5AE]" }
const _hoisted_6 = { class: "h-full overflow-y-auto py-16" }
const _hoisted_7 = { class: "mt-[6px]" }
const _hoisted_8 = { class: "flex items-start gap-8 my-10" }
const _hoisted_9 = { class: "flex flex-col gap-2" }
const _hoisted_10 = { class: "flex flex-col pt-10 gap-2" }
const _hoisted_11 = {
  key: 0,
  class: "mt-[6px]"
}
const _hoisted_12 = { class: "flex flex-col pt-10 gap-2" }
const _hoisted_13 = { class: "mt-[6px]" }
const _hoisted_14 = { class: "pt-10" }
const _hoisted_15 = { class: "flex flex-col gap-2" }
const _hoisted_16 = { class: "flex flex-col my-4 gap-2" }
const _hoisted_17 = { class: "pt-10" }
const _hoisted_18 = { class: "flex flex-col gap-2" }
const _hoisted_19 = { class: "flex flex-col pt-5 gap-2" }
const _hoisted_20 = { class: "bg-white absolute bottom-0 left-0 flex justify-end gap-4 border-t border-[#e3e1ea] w-full p-5 py-3" }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = ["disabled"]
const _hoisted_23 = {
  key: 0,
  class: "w-6 h-6 border-4 border-white border-t-transparent rounded-full animate-spin"
}
const _hoisted_24 = { key: 1 }

import InputChecklist from "@/components/Re-usable/InputChecklist.vue";
import InputTable from "@/components/Re-usable/InputTable.vue";
import { reactive, ref, onMounted } from "vue";
import HorizontalDynamicTable from "../HorizontalDynamicTable.vue";
import VerticalDynamicTable from "../VerticalDynamicTable.vue";
import SelectMenu from "@/components/Re-usable/SelectMenu.vue";
import { getStatusByName } from "@/utils/report-status";
import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";

type Props = {
  data: any;
  termin: any;
};

type Emits = {
  (event: "close"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'PrufBerichtModal',
  props: {
    data: {},
    termin: {}
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const state = ref(props.data);

const emits = __emit;
const onChangeInput = (name: any, value: any, propertyName?: string) => {
  if (propertyName) {
    let inputIndex = state.value[propertyName].findIndex(
      (item: any) => item.id === Number(name)
    );

    let tempList = [...state.value[propertyName]];
    if (inputIndex != -1) {
      tempList[inputIndex].value = value;
      state.value[propertyName] = tempList;
    }
  } else {
    state.value[name] = value;
  }
};

let pvModuleTable = [
  {
    label: "Hersteller",
    name: "pv_module_hersteller",
    value: state.value?.pv_module_hersteller,
  },
  {
    label: "Modultyp",
    name: "pv_module_modultyp",
    value: state.value?.pv_module_modultyp,
  },
  {
    label: "Modulanzahl",
    name: "pv_module_modulanzahl",
    value: state.value?.pv_module_modulanzahl,
  },
  {
    label: "PV-Modulleistung",
    name: "pv_module_modulleistung",
    value: state.value?.pv_module_modulleistung,
  },
  {
    label: "Kurzschlussstrom Isc (A)",
    name: "pv_module_kurzschlussstrom",
    value: state.value?.pv_module_kurzschlussstrom,
  },
  {
    label: "Leerlaufspannung Uoc (V)",
    name: "pv_module_Leerlaufspannung",
    value: state.value?.pv_module_Leerlaufspannung,
  },
  {
    label: "MPP-Strom (A)",
    name: "pv_module_mpp_strom",
    value: state.value?.pv_module_mpp_strom,
  },
  {
    label: "MPP-Spannung (V)",
    name: "pv_module_mpp_spannung",
    value: state.value?.pv_module_mpp_spannung,
  },
];
let pvWechselrichterTable = [
  {
    label: "Hersteller",
    name: "pv_wechselrichter_hersteller",
    value: state.value?.pv_wechselrichter_hersteller,
  },
  {
    label: "Wechselrichtertyp 1",
    name: "pv_wechselrichter_hersteller_typ_1",
    value: state.value?.pv_wechselrichter_hersteller_typ_1,
  },
  {
    label: "Wechselrichteranzahl",
    name: "pv_wechselrichter_wechselrichteranzahl",
    value: state.value?.pv_wechselrichter_wechselrichteranzahl,
  },
];
let pvBatteriespeicherTable = [
  {
    label: "Hersteller",
    name: "pv_batteriespeicher_hersteller",
    value: state.value?.pv_batteriespeicher_hersteller,
  },
  {
    label: "Batteriespeichertyp",
    name: "pv_batteriespeicher_typ",
    value: state.value?.pv_batteriespeicher_typ,
  },
];

const addColumnHorizontalDynamicTable = (tableName: string) => {
  let tempTable = [...state.value[tableName]];
  tempTable = tempTable.map((item: any) => {
    item.value.push("");
    return item;
  });

  state.value[tableName] = tempTable;
};

const horizontalDynamicTableOnChange = (
  tableName: string,
  valueArrayIndex: number,
  columnIndex: number,
  value: any
) => {
  let inputIndex = state.value[tableName].findIndex(
    (item: any) => item.id === Number(columnIndex)
  );
  let tempTable = state.value[tableName];
  tempTable[inputIndex].value[valueArrayIndex] = value;

  state.value[tableName] = tempTable;
};

const verticalDynamicTableInputOnChange = (
  tableName: string,
  rowId: any,
  inputId: any,
  value: any
) => {
  let tempList = [...state.value[tableName]];

  let inputIndex = tempList[rowId].findIndex(
    (item: any) => item.id === Number(inputId)
  );
  if (inputIndex != -1) {
    tempList[rowId][inputIndex].value = value;
    state.value[tableName] = tempList;
  }
};

const verticalDynamicTableColumnInputOnChange = (
  tableName: string,
  columnId: number,
  value: any
) => {
  let tempList = [...state.value[tableName]];

  tempList = tempList.map((item: any) => {
    let inputIndex = item.findIndex(
      (item: any) => item.id === Number(columnId)
    );
    if (inputIndex != -1) {
      item[inputIndex].text = value;
    }
    return item;
  });
  state.value[tableName] = tempList;
};

const addRowVerticalDynamicTable = (tableName: string) => {
  let table = state.value[tableName];
  let rowCount = table.length;

  let tempRow: any = [];
  table[0].forEach((item: any) => {
    let tempColumn = { ...item };
    if (tempColumn && tempColumn.id === 1) {
      tempColumn.value = rowCount + 1;
    } else {
      tempColumn.value = "";
    }
    tempRow.push(tempColumn);
  });
  table.push(tempRow);
  state.value[tableName] = table;
};

let profungNachSelect = ref({
  id: state.value.prufung_nach,
  name: state.value.prufung_nach,
});

let netzformSelect = ref({
  id: state.value.netzform,
  name: state.value.netzform,
});

let prufergebnisSelect = ref({
  id: state.value.prufergebnis_select,
  name: state.value.prufergebnis_select,
});

let stringPlanSelect = ref({
  id: state.value.string_plan,
  name: state.value.string_plan,
});
const isLoading = ref(false);
const isReportLoading = ref(false);

const onSubmit = async () => {
  isLoading.value = true;

  try {
    state.value.prufung_nach = profungNachSelect.value
      ? profungNachSelect.value.id
      : null;

    state.value.netzform = netzformSelect.value
      ? netzformSelect.value.id
      : null;

    state.value.prufergebnis_select = prufergebnisSelect.value
      ? prufergebnisSelect.value.id
      : null;

    state.value.string_plan = stringPlanSelect.value
      ? stringPlanSelect.value.id
      : null;

    let resp = await client.post(
      `/termin/${props.termin.id}/save-prufbericht-report`,
      {
        data: state.value,
      }
    );

    state.value = resp.data.report;
    isLoading.value = false;
    successToast("Successfully saved report.");
  } catch (error: any) {
    isLoading.value = false;
    errorToast(error);
  }
};

const generateReport = async () => {
  successToast("Your report is being generated, please wait.");
  isReportLoading.value = true;
  try {
    state.value.prufung_nach = profungNachSelect.value
      ? profungNachSelect.value.id
      : null;

    state.value.netzform = netzformSelect.value
      ? netzformSelect.value.id
      : null;

    state.value.prufergebnis_select = prufergebnisSelect.value
      ? prufergebnisSelect.value.id
      : null;

    state.value.string_plan = stringPlanSelect.value
      ? stringPlanSelect.value.id
      : null;
    await client.post(
      `/termin/${props.termin.id}/generate-prufbericht-report`,
      {
        data: state.value,
      }
    );
    isReportLoading.value = false;
    successToast("Successfully generated report.");
  } catch (error: any) {
    isReportLoading.value = false;
    errorToast(error);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[11] || (_cache[11] = _createElementVNode("img", {
            src: _imports_0,
            alt: "Icon"
          }, null, -1)),
          _cache[12] || (_cache[12] = _createElementVNode("h3", { class: "text-primary-text-color text-xl leading-[32px] font-semibold tracking-[0.01rem]" }, " Prüfbericht ", -1)),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(getStatusByName)(state.value?.status)), 1)
        ]),
        _createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => {
            emits('close');
            state.value = null;
          }),
          class: "cursor-pointer",
          src: _imports_1,
          alt: "Close icon"
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.value.reason) = $event)),
            class: "w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]",
            type: "text",
            placeholder: "Grund der Prüfung:"
          }, null, 512), [
            [_vModelText, state.value.reason]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, [
            _createVNode(InputTable, {
              title: "PV-Module",
              data: _unref(pvModuleTable),
              onOnChangeInput: onChangeInput
            }, null, 8, ["data"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(InputTable, {
              title: "PV-Wechselrichter",
              data: _unref(pvWechselrichterTable),
              onOnChangeInput: onChangeInput
            }, null, 8, ["data"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(InputTable, {
              title: "PV-Batteriespeicher",
              data: _unref(pvBatteriespeicherTable),
              onOnChangeInput: onChangeInput
            }, null, 8, ["data"])
          ])
        ]),
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "mb-[40px]" }, [
          _createElementVNode("h3", { class: "text-primary-text-color text-xl leading-[32px] pb-2 font-semibold tracking-[0.01rem]" }, " Konstruktion, Aufbau, Besichtigung sowie Prüfung "),
          _createElementVNode("p", { class: "text-primary-text-color text-lg leading-[24px] tracking-[0.01rem] font-normal" }, " Ich/Wir, die verantwortliche(n) Person(en) für die Konstruktion, Aufbau, Besichtigung sowie Prüfung der elektrischen Anlage (wie nachfolgend durch die Unterschrift(en) angegeben), deren Einzelheiten oben beschrieben sind, haben mit angemessener Fachkenntnis und Sorgfalt die Besichtigung sowie Prüfung der Konstruktion und des Aufbaus vorgenommen und bestätigen hiermit, dass die genannten Arbeiten, für die ich/wir verantwortlich bin (sind), nach besten Kenntnissen und Wissen ausgeführt wurden. ")
        ], -1)),
        _createElementVNode("div", null, [
          _createVNode(InputChecklist, {
            title: "Prüfergebnis",
            "checklist-name": "prüfergebnis",
            data: state.value?.prüfergebnis,
            onOnChangeInput: onChangeInput
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(InputChecklist, {
            title: "Konstruktion und Installation des PV-Generators",
            "checklist-name": "konstruktion_installationPv_generators",
            data: state.value?.konstruktion_installationPv_generators,
            onOnChangeInput: onChangeInput
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(InputChecklist, {
            title: "PV-System/ Schutz gegen Überspannung/ elektrischen Schlag",
            "checklist-name": "pv_system",
            data: state.value?.pv_system,
            onOnChangeInput: onChangeInput
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(InputChecklist, {
            title: "Besondere Faktoren PV-System - Wechselstromkreis",
            "checklist-name": "besondere_faktoren_pv_system",
            data: state.value?.besondere_faktoren_pv_system,
            onOnChangeInput: onChangeInput
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(InputChecklist, {
            title: "Aufschriften und Kennzeichnung des PV-Systems",
            "checklist-name": "aufschriften_kennzeichnung_pv_systems",
            data: state.value?.aufschriften_kennzeichnung_pv_systems,
            onOnChangeInput: onChangeInput
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(InputChecklist, {
            title: "Allgemeine (mechanische) Installation des PV-Systems",
            "checklist-name": "allgemeine_installation",
            data: state.value?.allgemeine_installation,
            onOnChangeInput: onChangeInput
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[13] || (_cache[13] = _createElementVNode("h2", { class: "text-[#c55d57] text-xl font-semibold" }, " Prüfbericht der elektrischen Prüfung des PV-Generators gemäß VDE 0126-23 (DIN EN 62446) ", -1)),
          (state.value?.strang_table)
            ? (_openBlock(), _createBlock(HorizontalDynamicTable, {
                key: 0,
                title: "Strang",
                data: state.value?.strang_table,
                "table-name": "strang_table",
                onOnChangeInput: horizontalDynamicTableOnChange,
                onAddColumn: addColumnHorizontalDynamicTable
              }, null, 8, ["data"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[15] || (_cache[15] = _createElementVNode("h2", { class: "text-[#c55d57] text-xl font-semibold" }, " Prüfbericht der elektrischen Prüfung der AC-Seite der PV-Anlage gemäß ZVEH-Vorlage ", -1)),
          _createElementVNode("div", null, [
            _cache[14] || (_cache[14] = _createElementVNode("label", null, "Prüfung nach", -1)),
            _createVNode(SelectMenu, {
              options: state.value.prufung_nach_options.map((item)=>{ return { id:item,
            name:item } }),
              modelValue: _unref(profungNachSelect),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(profungNachSelect) ? (profungNachSelect).value = $event : profungNachSelect = $event))
            }, null, 8, ["options", "modelValue"])
          ]),
          (_unref(profungNachSelect)?.id === 'DIN VDE 0100-600')
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((state.value.prufung_nach_description) = $event)),
                  class: "w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]",
                  type: "text",
                  placeholder: "Netz..../.....V"
                }, null, 512), [
                  [_vModelText, state.value.prufung_nach_description]
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[16] || (_cache[16] = _createElementVNode("h2", { class: "text-[#393840] text-xl font-semibold" }, "Netzform", -1)),
          _createElementVNode("div", null, [
            _createVNode(SelectMenu, {
              modelValue: _unref(netzformSelect),
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(netzformSelect) ? (netzformSelect).value = $event : netzformSelect = $event)),
              options: state.value.netzform_options.map((item)=>{ return { id:item,
            name:item } })
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((state.value.netzbetreiber) = $event)),
              class: "w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]",
              type: "text",
              placeholder: "Netzbetreiber: …….."
            }, null, 512), [
              [_vModelText, state.value.netzbetreiber]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(InputChecklist, {
            title: "Besichtigen",
            "checklist-name": "besichtigen",
            data: state.value?.besichtigen,
            onOnChangeInput: onChangeInput
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[17] || (_cache[17] = _createElementVNode("h2", { class: "text-[#393840] text-xl font-semibold" }, "Stromkreis", -1)),
          _cache[18] || (_cache[18] = _createElementVNode("p", { class: "text-base text-[#898790]" }, " Nur veränderte und umgebaute Stromkreise ", -1)),
          _createVNode(VerticalDynamicTable, {
            data: state.value?.stromkreis,
            "table-name": "stromkreis",
            onOnChangeInput: verticalDynamicTableInputOnChange,
            onOnChangeColumn: verticalDynamicTableColumnInputOnChange,
            onAddRow: addRowVerticalDynamicTable
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((state.value.durchgangigkeit_schutzleiters) = $event)),
            class: "w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]",
            type: "text",
            placeholder: "Durchgängigkeit des Schutzleiters: …….. <1Ω"
          }, null, 512), [
            [_vModelText, state.value.durchgangigkeit_schutzleiters]
          ]),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((state.value.durchgangigkeit_potentialausgleich) = $event)),
            class: "w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]",
            type: "text",
            placeholder: "Durchgängigkeit Potentialausgleich: …… (< 1 Ω nachgewiesen)"
          }, null, 512), [
            [_vModelText, state.value.durchgangigkeit_potentialausgleich]
          ]),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((state.value.erdungswiderstand) = $event)),
            class: "w-full bg-[#F9F8F6] text-base font-medium text-[#898790] placeholder:text-[#898790] outline-none p-3 rounded-primary-radius border border-[#E3E1EA]",
            type: "text",
            placeholder: "Erdungswiderstand: RE ........ Ω"
          }, null, 512), [
            [_vModelText, state.value.erdungswiderstand]
          ])
        ]),
        _createElementVNode("div", null, [
          _createVNode(VerticalDynamicTable, {
            data: state.value?.fehlerstrom_schutzeinrichtung,
            "table-name": "fehlerstrom_schutzeinrichtung",
            onOnChangeInput: verticalDynamicTableInputOnChange,
            onAddRow: addRowVerticalDynamicTable
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(InputChecklist, {
            title: "",
            "checklist-name": "stromkreis_checklist",
            data: state.value?.stromkreis_checklist,
            onOnChangeInput: onChangeInput
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _cache[19] || (_cache[19] = _createElementVNode("h2", { class: "text-[#393840] text-xl font-semibold" }, "Prüfergebnis", -1)),
          _createElementVNode("div", null, [
            _createVNode(SelectMenu, {
              options: state.value.prufergebnis_options.map((item)=>{ return { id:item,
            name:item } }),
              modelValue: _unref(prufergebnisSelect),
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_isRef(prufergebnisSelect) ? (prufergebnisSelect).value = $event : prufergebnisSelect = $event))
            }, null, 8, ["options", "modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _cache[20] || (_cache[20] = _createElementVNode("h2", { class: "text-[#393840] text-xl font-semibold" }, "String plan", -1)),
          _createElementVNode("div", null, [
            _createVNode(SelectMenu, {
              options: state.value.string_plan_options.map((item)=>{ return { id:item,
            name:item } }),
              modelValue: _unref(stringPlanSelect),
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_isRef(stringPlanSelect) ? (stringPlanSelect).value = $event : stringPlanSelect = $event))
            }, null, 8, ["options", "modelValue"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("button", {
          disabled: isReportLoading.value,
          onClick: generateReport,
          class: "flex items-center gap-1 py-3 px-[16px] text-base text-primary-text-color bg-transparent tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer duration-200"
        }, [
          (_openBlock(), _createElementBlock("svg", {
            width: "14",
            height: "16",
            viewBox: "0 0 14 16",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
            class: _normalizeClass(`${isReportLoading.value ? 'animate-spin' : ''}`)
          }, _cache[22] || (_cache[22] = [
            _createElementVNode("path", {
              d: "M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179",
              stroke: "#393840",
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }, null, -1)
          ]), 2)),
          _cache[23] || (_cache[23] = _createTextVNode(" Generate report "))
        ], 8, _hoisted_21),
        _createElementVNode("button", {
          disabled: isLoading.value,
          onClick: onSubmit,
          class: "w-[150px] flex items-center justify-center py-3 px-[56px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#28272c] duration-200"
        }, [
          (isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23))
            : (_openBlock(), _createElementBlock("span", _hoisted_24, "Save"))
        ], 8, _hoisted_22)
      ])
    ])
  ]))
}
}

})