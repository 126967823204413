<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

type Props = {
  step: any;
};
defineProps<Props>();
type Emits = {
  (event: "toggleModalHandler", input: any): void;
};

const emit = defineEmits<Emits>();
</script>

<template>
  <div>
    <h3
      class="text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold"
    >
      Input fields
    </h3>
    <div
      v-for="input in step.inputs.filter((input: any) => input.type !== 'image' &&  input.type !== 'group' && !input.value)"
      :key="input.id"
      class="my-3"
    >
      <ul class="w-full list-none flex flex-col items-start gap-3">
        <li
          @click="emit('toggleModalHandler', input)"
          class="group hover:cursor-pointer hover:bg-[#F9F8F6] hover:outline hover:outline-[#E3E1EA] p-[3px] hover:rounded-[5px] w-full flex gap-1 items-center outline-1 outline-gray-100 pb-1"
        >
          <p
            class="relative text-base text-primary-text-color font-normal leading-5 tracking-[0.01rem]"
          >
            <span
              v-if="input.parentId !== null"
              class="text-sm font-sans font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]"
            >
              {{ step.inputs.find((i: any) => i.id === input.parentId).name }} /
            </span>
            {{ input.name }}
          </p>
          <img
            class="w-3 h-3 invisible group-hover:visible ml-auto"
            src="@/assets/pencil-icon.svg"
            alt="Icon"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
