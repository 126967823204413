import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/arrow-left-gray.svg'
import _imports_1 from '@/assets/arrow-left.svg'
import _imports_2 from '@/assets/arrow-right.svg'
import _imports_3 from '@/assets/media-icon.svg'


const _hoisted_1 = { class: "p-3 rounded-primary-radius bg-[#E5E4E2] relative h-[356px]" }
const _hoisted_2 = { class: "w-full z-50 flex items-start justify-between absolute top-0 left-0 h-10 bg-[#E5E4E2] p-3 rounded-primary-radius" }
const _hoisted_3 = { class: "flex items-start gap-2" }
const _hoisted_4 = { class: "w-6 h-6 flex items-center justify-center rounded-sm" }
const _hoisted_5 = {
  src: _imports_0,
  alt: ""
}
const _hoisted_6 = {
  src: _imports_1,
  alt: ""
}
const _hoisted_7 = {
  key: 0,
  class: "h-[332px] overflow-y-auto"
}
const _hoisted_8 = { class: "flex flex-col w-full relative overflow-x-auto mt-10" }
const _hoisted_9 = { class: "flex flex-col w-full relative gap-2 py-3" }
const _hoisted_10 = { class: "flex flex-col gap-[6px]" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "lg:w-[156px] flex flex-col py-[6px] px-[8px] bg-white rounded-primary-radius w-[calc(100%-8px)]" }
const _hoisted_13 = { class: "flex flex-col gap-2" }
const _hoisted_14 = { class: "flex flex-col items-start sm:flex-row sm:items-center gap-1 pb-[2.5px] w-full border-b border-gray-200" }
const _hoisted_15 = {
  key: 0,
  src: _imports_3,
  alt: ""
}
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "text-xs text-[#393840] break-words" }
const _hoisted_18 = {
  key: 0,
  class: "text-xs text-[#393840] border-b border-gray-200 pb-[6px]"
}
const _hoisted_19 = {
  key: 0,
  class: "text-[#898790] text-xs pt-[2.5px]"
}
const _hoisted_20 = {
  key: 1,
  class: "text-[#898790] text-xs pt-[2.5px]"
}
const _hoisted_21 = {
  key: 0,
  class: "h-full flex pt-6"
}
const _hoisted_22 = { class: "w-1/5 flex flex-col relative mx-2" }
const _hoisted_23 = { class: "bg-[#393840] text-white py-[4px] px-1 sm:py-[6px] sm:px-2 rounded-primary-radius text-xs text-center z-10 relative mt-4" }
const _hoisted_24 = { class: "flex flex-col gap-[6px] justify-end" }
const _hoisted_25 = ["href"]
const _hoisted_26 = {
  key: 0,
  class: "h-full flex pt-6"
}
const _hoisted_27 = { class: "w-[calc(100%-8px)] lg:w-[156px] flex flex-col py-[6px] px-[8px] bg-white rounded-primary-radius" }
const _hoisted_28 = { class: "flex flex-col gap-2" }
const _hoisted_29 = { class: "flex items-center gap-1" }
const _hoisted_30 = {
  key: 0,
  src: _imports_3,
  alt: ""
}
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { class: "text-sm text-[#393840] break-words w-full pr-1" }
const _hoisted_33 = {
  key: 0,
  class: "flex text-xs text-[#393840] gap-[2px]"
}
const _hoisted_34 = { class: "flex gap flex-col" }
const _hoisted_35 = { class: "flex justify-between items-center flex-wrap pt-[6px] gap-1" }
const _hoisted_36 = {
  key: 0,
  class: "text-[#898790] text-xs"
}
const _hoisted_37 = {
  key: 1,
  class: "text-[#898790] text-xs"
}
const _hoisted_38 = {
  key: 1,
  class: "h-full flex justify-center items-center pt-10 relative"
}

import { Project, ProjectTermin, TerminIssue } from "@/types";
import { formatStatusName, getTerminStatusColor } from "@/utils/status-color";
import { computed, ref } from "vue";

type Props = {
  isLoading: boolean;
  projectDetails: any;
};

type Emits = {
  (event: "showAppointments"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TerminTimeline',
  props: {
    isLoading: { type: Boolean },
    projectDetails: {}
  },
  emits: ["showAppointments"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const isDisabled = ref(true);

const timelineItems = computed(() => {
  let items: any = [];
  if (props.projectDetails) {
    props.projectDetails.termins.map((item: ProjectTermin) => {
      if (item.start) {
        items.push({
          title: item.name,
          text: "",
          teams: item.teams,
          phase: item.phase,
          date: new Date(item.start),
          side: "left",
          terminId: item.id,
          projectId: props.projectDetails?.id,
        });
      }
      if (item.end) {
        items.push({
          title: item.name,
          text: "",
          teams: item.teams,
          phase: item.phase,
          date: new Date(item.end),
          status: item.status?.name || "",
          side: "right",
          type: "termin",
          terminId: item.id,
          projectId: props.projectDetails?.id,
        });
      }
    });

    props.projectDetails.issues.map((item: TerminIssue) =>
      items.push({
        title: item.terminName,
        text: item.title,
        teams: [],
        phaseId: item.phaseId,
        phaseName: item.phaseName,
        date: new Date(item.createdAt),
        side: "left",
        type: "issue",
        terminId: item.terminId,
        projectId: props.projectDetails?.id,
      })
    );

    items.sort((a: any, b: any) => b.date - a.date);
    items = items.map((item: any) => {
      return {
        ...item,
        date: new Date(
          item.date.getFullYear(),
          item.date.getMonth(),
          item.date.getDate()
        ),
      };
    });
  }

  return items;
});

const dates = computed(() => {
  let dates = [];

  dates = [
    ...new Set(
      timelineItems.value.map((item: any) => {
        const day = String(item.date.getDate()).padStart(2, "0");
        const month = String(item.date.getMonth() + 1).padStart(2, "0");
        const year = item.date.getFullYear();
        return `${day}.${month}.${year}.`;
      })
    ),
  ];
  return dates;
});

const getDateFromString = (dateString: any) => {
  const [day, month, year] = dateString.split(".").map(Number); // Split and convert to numbers
  return new Date(year, month - 1, day);
};

const areDatesSame = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-xl pb-2 text-primary-text-color tracking-[0.01rem] font-semibold" }, " Timeline ", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", _hoisted_4, [
          _withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [
            [_vShow, isDisabled.value]
          ]),
          _withDirectives(_createElementVNode("img", _hoisted_6, null, 512), [
            [_vShow, !isDisabled.value]
          ])
        ]),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('showAppointments'))),
          class: "w-6 h-6 flex items-center justify-center cursor-pointer rounded-sm"
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            src: _imports_2,
            alt: ""
          }, null, -1)
        ]))
      ])
    ]),
    (timelineItems.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "bg-[#898790] mx-auto w-3 h-3 rounded-full" }, null, -1)),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "w-[2px] h-full bg-[#898790] mx-auto absolute z-10 top-3 left-[50%] ml-[-1px]" }, null, -1)),
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dates.value, (date, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "flex"
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["w-2/5 flex flex-col gap-[6px] h-fit", `
          ${timelineItems.value.filter((item)=> areDatesSame(getDateFromString(date), item.date) && item.side === 'left').length > 0 ? 'border-l-2 border-[#85838c] rounded-md' : 'pl-[2px]'}
        `])
                  }, [
                    _createElementVNode("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(timelineItems.value.filter((item)=> areDatesSame(getDateFromString(date), item.date) && item.side === 'left'), (card, index) => {
                        return (_openBlock(), _createElementBlock("a", {
                          href: `/projects/${card.projectId}/termin/${card.terminId}`,
                          key: index,
                          class: "flex"
                        }, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", _hoisted_14, [
                                (!card.text)
                                  ? (_openBlock(), _createElementBlock("img", _hoisted_15))
                                  : _createCommentVNode("", true),
                                (card.text)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[3] || (_cache[3] = [
                                      _createElementVNode("svg", {
                                        width: "14",
                                        height: "13",
                                        viewBox: "0 0 14 13",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }, [
                                        _createElementVNode("path", {
                                          d: "M7 4.429V6.76233M4.27684 1.79687L2.29687 3.77684L2.29508 3.77853C2.0945 3.97911 1.99397 4.07964 1.92204 4.19702C1.85807 4.30141 1.81081 4.41534 1.78224 4.53438C1.75 4.66865 1.75 4.81128 1.75 5.09664V7.90336C1.75 8.18872 1.75 8.33139 1.78224 8.46566C1.81081 8.5847 1.85807 8.69847 1.92204 8.80286C1.99416 8.92055 2.09523 9.02162 2.29687 9.22327L4.27684 11.2032C4.47862 11.405 4.57929 11.5057 4.69702 11.5778C4.80141 11.6418 4.91506 11.6892 5.0341 11.7178C5.16816 11.75 5.31056 11.75 5.59505 11.75H8.40438C8.68887 11.75 8.83187 11.75 8.96594 11.7178C9.08498 11.6892 9.19876 11.6418 9.30314 11.5778C9.42088 11.5057 9.52178 11.405 9.72355 11.2032L11.7035 9.22327C11.9053 9.02149 12.006 8.92059 12.0781 8.80286C12.1421 8.69847 12.1889 8.5847 12.2175 8.46566C12.2498 8.33139 12.25 8.18872 12.25 7.90337V5.09664C12.25 4.81128 12.2498 4.66865 12.2175 4.53438C12.1889 4.41534 12.1421 4.30141 12.0781 4.19703C12.006 4.07929 11.9053 3.97861 11.7035 3.77684L9.72355 1.79687C9.5219 1.59522 9.42084 1.49416 9.30314 1.42204C9.19876 1.35807 9.08498 1.31081 8.96594 1.28224C8.83167 1.25 8.68844 1.25 8.40308 1.25H5.59635C5.311 1.25 5.16837 1.25 5.0341 1.28224C4.91506 1.31081 4.80141 1.35807 4.69702 1.42204C4.58036 1.49353 4.48062 1.59327 4.28248 1.79141L4.27684 1.79687ZM7.02905 8.51233V8.57066L6.97095 8.57078V8.51233H7.02905Z",
                                          stroke: "#ED857F",
                                          "stroke-width": "2",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        })
                                      ], -1)
                                    ])))
                                  : _createCommentVNode("", true),
                                _createElementVNode("h3", _hoisted_17, _toDisplayString(card.title), 1)
                              ]),
                              (card.text)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(card.text && card.text.length > 50
                            ? card.text.slice(0, 50) + "..."
                            : card.text), 1))
                                : _createCommentVNode("", true)
                            ]),
                            (card.phase)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(card.phase), 1))
                              : _createCommentVNode("", true),
                            (card.phaseName)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(card.phaseName), 1))
                              : _createCommentVNode("", true)
                          ]),
                          (index === 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[4] || (_cache[4] = [
                                _createElementVNode("svg", {
                                  width: "8",
                                  height: "16",
                                  viewBox: "0 0 8 16",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, [
                                  _createElementVNode("path", {
                                    d: "M7.32785 6.50518C8.22405 7.30076 8.22405 8.69924 7.32785 9.49482L0 16L-8.9018e-07 4.4509e-07L7.32785 6.50518Z",
                                    fill: "white"
                                  })
                                ], -1)
                              ])))
                            : _createCommentVNode("", true)
                        ], 8, _hoisted_11))
                      }), 128))
                    ])
                  ], 2),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, _toDisplayString(date), 1)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["w-2/5 flex flex-col gap-[6px] h-fit", `
          ${timelineItems.value.filter((item)=> areDatesSame(getDateFromString(date), item.date) && item.side === 'right').length > 0 ? 'border-r-2 border-[#85838c] rounded-md' : 'pr-[2px]'}
        `])
                  }, [
                    _createElementVNode("div", _hoisted_24, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(timelineItems.value.filter((item)=> areDatesSame(getDateFromString(date), item.date) && item.side === 'right'), (card, index) => {
                        return (_openBlock(), _createElementBlock("a", {
                          href: `/projects/${card.projectId}/termin/${card.terminId}`,
                          key: index,
                          class: "flex justify-end"
                        }, [
                          (index === 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_26, _cache[5] || (_cache[5] = [
                                _createElementVNode("svg", {
                                  width: "8",
                                  height: "16",
                                  viewBox: "0 0 8 16",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, [
                                  _createElementVNode("path", {
                                    d: "M0.672147 9.49482C-0.224048 8.69924 -0.224048 7.30076 0.672146 6.50518L8 -4.4509e-07L8 16L0.672147 9.49482Z",
                                    fill: "white"
                                  })
                                ], -1)
                              ])))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _createElementVNode("div", _hoisted_29, [
                                (!card.text)
                                  ? (_openBlock(), _createElementBlock("img", _hoisted_30))
                                  : _createCommentVNode("", true),
                                (card.text)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_31, _cache[6] || (_cache[6] = [
                                      _createElementVNode("svg", {
                                        width: "14",
                                        height: "13",
                                        viewBox: "0 0 14 13",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }, [
                                        _createElementVNode("path", {
                                          d: "M7 4.429V6.76233M4.27684 1.79687L2.29687 3.77684L2.29508 3.77853C2.0945 3.97911 1.99397 4.07964 1.92204 4.19702C1.85807 4.30141 1.81081 4.41534 1.78224 4.53438C1.75 4.66865 1.75 4.81128 1.75 5.09664V7.90336C1.75 8.18872 1.75 8.33139 1.78224 8.46566C1.81081 8.5847 1.85807 8.69847 1.92204 8.80286C1.99416 8.92055 2.09523 9.02162 2.29687 9.22327L4.27684 11.2032C4.47862 11.405 4.57929 11.5057 4.69702 11.5778C4.80141 11.6418 4.91506 11.6892 5.0341 11.7178C5.16816 11.75 5.31056 11.75 5.59505 11.75H8.40438C8.68887 11.75 8.83187 11.75 8.96594 11.7178C9.08498 11.6892 9.19876 11.6418 9.30314 11.5778C9.42088 11.5057 9.52178 11.405 9.72355 11.2032L11.7035 9.22327C11.9053 9.02149 12.006 8.92059 12.0781 8.80286C12.1421 8.69847 12.1889 8.5847 12.2175 8.46566C12.2498 8.33139 12.25 8.18872 12.25 7.90337V5.09664C12.25 4.81128 12.2498 4.66865 12.2175 4.53438C12.1889 4.41534 12.1421 4.30141 12.0781 4.19703C12.006 4.07929 11.9053 3.97861 11.7035 3.77684L9.72355 1.79687C9.5219 1.59522 9.42084 1.49416 9.30314 1.42204C9.19876 1.35807 9.08498 1.31081 8.96594 1.28224C8.83167 1.25 8.68844 1.25 8.40308 1.25H5.59635C5.311 1.25 5.16837 1.25 5.0341 1.28224C4.91506 1.31081 4.80141 1.35807 4.69702 1.42204C4.58036 1.49353 4.48062 1.59327 4.28248 1.79141L4.27684 1.79687ZM7.02905 8.51233V8.57066L6.97095 8.57078V8.51233H7.02905Z",
                                          stroke: "#ED857F",
                                          "stroke-width": "2",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        })
                                      ], -1)
                                    ])))
                                  : _createCommentVNode("", true),
                                _createElementVNode("h3", _hoisted_32, _toDisplayString(card.title), 1)
                              ]),
                              (card.teams)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                    _cache[7] || (_cache[7] = _createElementVNode("svg", {
                                      width: "16",
                                      height: "16",
                                      viewBox: "0 0 16 16",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }, [
                                      _createElementVNode("path", {
                                        d: "M5.25 6.41675C5.25 7.38325 6.0335 8.16675 7 8.16675C7.9665 8.16675 8.75 7.38325 8.75 6.41675C8.75 5.45025 7.9665 4.66675 7 4.66675C6.0335 4.66675 5.25 5.45025 5.25 6.41675Z",
                                        fill: "#D9D9D9"
                                      }),
                                      _createElementVNode("path", {
                                        d: "M9.91667 11.6667C9.91667 10.7002 8.61083 9.91675 7 9.91675C5.38917 9.91675 4.08333 10.7002 4.08333 11.6667M12.25 9.91697C12.25 9.19935 11.5301 8.58263 10.5 8.31258M1.75 9.91697C1.75 9.19935 2.46989 8.58263 3.5 8.31258M10.5 5.97114C10.858 5.65071 11.0833 5.18504 11.0833 4.66675C11.0833 3.70025 10.2998 2.91675 9.33333 2.91675C8.88513 2.91675 8.47627 3.08525 8.16667 3.36235M3.5 5.97114C3.14198 5.65071 2.91667 5.18504 2.91667 4.66675C2.91667 3.70025 3.70017 2.91675 4.66667 2.91675C5.11487 2.91675 5.52373 3.08525 5.83333 3.36235M7 8.16675C6.0335 8.16675 5.25 7.38325 5.25 6.41675C5.25 5.45025 6.0335 4.66675 7 4.66675C7.9665 4.66675 8.75 5.45025 8.75 6.41675C8.75 7.38325 7.9665 8.16675 7 8.16675Z",
                                        stroke: "#898790",
                                        "stroke-width": "2",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round"
                                      })
                                    ], -1)),
                                    _createElementVNode("div", _hoisted_34, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(card.teams, (team) => {
                                        return (_openBlock(), _createElementBlock("span", { key: team }, _toDisplayString(team), 1))
                                      }), 128))
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_35, [
                              _createElementVNode("span", {
                                style: _normalizeStyle(`background:${_unref(getTerminStatusColor)(
                          card.status
                        )}`),
                                class: "text-xs font-medium p-1 text-[#393840] rounded-lg px-[6px] py-[2px]"
                              }, _toDisplayString(_unref(formatStatusName)(card.status)), 5),
                              (card.phase)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(card.phase), 1))
                                : _createCommentVNode("", true),
                              (card.phaseName)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(card.phaseName), 1))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ], 8, _hoisted_25))
                      }), 128))
                    ])
                  ], 2)
                ]))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (timelineItems.value.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_38, _cache[10] || (_cache[10] = [
          _createStaticVNode("<svg width=\"12\" height=\"256\" viewBox=\"0 0 12 256\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666666 6 0.666667C3.05448 0.666667 0.666666 3.05448 0.666667 6ZM5 6L5.00004 931L7.00004 931L7 6L5 6Z\" fill=\"#898790\"></path></svg><div class=\"absolute flex right-[29px] items-baseline\"><div class=\"bg-[#393840] text-white text-xs py-[6px] px-2 rounded-lg mr-1 h-fit\"> Woops </div><div class=\"flex items-center relative top-[-8px] left-5\"><svg class=\"relative top-3\" width=\"8\" height=\"16\" viewBox=\"0 0 8 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M0.672147 9.49482C-0.224048 8.69924 -0.224048 7.30076 0.672146 6.50518L8 -4.4509e-07L8 16L0.672147 9.49482Z\" fill=\"white\"></path></svg><div class=\"bg-white rounded-lg px-2 py-[6px] text-sm w-[150px]\"> No appointments here </div></div></div>", 2)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})