import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/smalt-home-logo.svg'
import _imports_1 from '@/assets/arrow-left.svg'
import _imports_2 from '@/assets/arrow-right.svg'
import _imports_3 from '@/assets/angle-arrow.svg'
import _imports_4 from '@/assets/user-icon.svg'
import _imports_5 from '@/assets/logout-icon.svg'


const _hoisted_1 = { class: "flex items-center gap-2" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center gap"
}
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col justify-center mt-6 gap-2 w-full items-start"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "flex flex-col items-start mt-6 gap-2" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "max-w-[200px] w-full ml-2 2xl:ml-4 pl-2 my-3 flex flex-col items-start gap-1 border-l-2 border-[#E5E4E2]"
}
const _hoisted_8 = { class: "mt-auto flex flex-col items-start gap-2" }
const _hoisted_9 = { class: "text-sm font-normal text-primary-text-color" }

import router from "@/router";
import { useUserStore } from "@/stores/user";
import { textTransform } from "@/utils/parse-data";
import { getProjectStatusColor } from "@/utils/status-color";
import { computed, ref } from "vue";
import { useRoute, RouterLink } from "vue-router";
import InlineLoader from "../Loaders/InlineLoader.vue";

type Props = {
  phaseProgress?: any;
  phaseSteps?: any;
  selectedPhase?: number | null;
  isLoading?: boolean;
  isLink?: boolean;
};

type Emit = {
  (event: "onPhaseSelect", phaseId: number): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MainSidebar',
  props: {
    phaseProgress: {},
    phaseSteps: {},
    selectedPhase: {},
    isLoading: { type: Boolean },
    isLink: { type: Boolean }
  },
  emits: ["onPhaseSelect"],
  setup(__props: any, { emit: __emit }) {

const route = useRoute();
const projectId = route.params.id;
const userStore = useUserStore();

const isLoading = computed(() => props.isLoading);
const isLink = computed(() => props.isLink);

const props = __props;

const emit = __emit;

const selectPhase = (phaseId: number) => {
  emit("onPhaseSelect", phaseId);
};

const logout = () => {
  const userStore = useUserStore();
  userStore.logout();
  router.push("/login");
};

const isPhase = ref(true);

const goBack = () => {
  if (route.path.includes("phase")) {
    const parts = route.path.split("/phase/");
    const updatedUrl = parts[0];
    router.push(updatedUrl);
  } else if (route.path.includes("termin")) {
    const parts = route.path.split("/termin/");
    const updatedUrl = parts[0];
    router.push(updatedUrl);
  } else {
    router.go(-1);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["hidden md:flex flex-col relative w-[160px] h-[calc(100vh-55px)] my-auto mr-6 sm:mr-0 sm:pl-0", isLink.value ? 'w-[160px] sm:w-[220px]' : ''])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(RouterLink), {
        to: "/",
        class: "w-[26px] h-[26px] flex items-center justify-center cursor-pointer"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Smalt icon"
          }, null, -1)
        ])),
        _: 1
      }),
      (_unref(route).path !== '/')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("button", {
              onClick: goBack,
              class: "w-6 h-6 flex items-center justify-center cursor-pointer rounded-sm hover:bg-gray-200 duration-200"
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("img", {
                src: _imports_1,
                alt: ""
              }, null, -1)
            ])),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(+1))),
              class: _normalizeClass(["w-6 h-6 flex items-center justify-center cursor-pointer rounded-sm hover:bg-gray-200 duration-300", _ctx.selectedPhase ? 'bg-gray-200' : 'bg-transparent'])
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("img", {
                src: _imports_2,
                alt: ""
              }, null, -1)
            ]), 2)
          ]))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("div", null, [
      (isLoading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
              return _createVNode(InlineLoader, { key: i })
            }), 64))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.phaseProgress, (phase) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: phase.phaseId
                }, [
                  _createVNode(_unref(RouterLink), {
                    onClick: ($event: any) => (selectPhase(phase.phaseId)),
                    to: `/projects/${_unref(projectId)}/phase/${phase.phaseId}`,
                    class: _normalizeClass(["w-max flex flex-col items-start sm:flex-row sm:items-center gap-2 p-[6px] rounded-primary-radius bg-transparent cursor-pointer group hover:bg-white hover:pr-[22px] transition-all duration-200", 
                phase.phaseId === _ctx.selectedPhase
                  ? 'bg-white sm:pr-[22px]'
                  : 'bg-transparent pr-0'
              ])
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: _normalizeClass(["hidden sm:block absolute opacity-0 group-hover:opacity-100 transition-opacity duration-200 group-hover:mr-3", 
                  phase.phaseId === _ctx.selectedPhase ? 'opacity-100' : 'opacity-0'
                ]),
                        src: _imports_3,
                        alt: "Arrow"
                      }, null, 2),
                      _createElementVNode("h5", {
                        class: _normalizeClass(["text-sm leading-[17px] tracking-[0.01rem] text-primary-text-color uppercase font-semibold transition-all duration-200 group-hover:sm:translate-x-4", 
                  phase.phaseId === _ctx.selectedPhase
                    ? 'sm:translate-x-4'
                    : 'translate-x-0'
                ])
                      }, _toDisplayString(phase.phase), 3),
                      _createElementVNode("span", {
                        class: _normalizeClass(["text-sm text-primary-text-color font-medium tracking-[0.01rem] rounded-primary-radius py-[2px] px-[6px] transition-all duration-200 group-hover:sm:translate-x-4", 
                  phase.phaseId === _ctx.selectedPhase
                    ? 'sm:translate-x-4'
                    : 'translate-x-0'
                ]),
                        style: _normalizeStyle({
                  backgroundColor: `${_unref(getProjectStatusColor)(
                    phase.phaseStatus?.name
                  )}`,
                })
                      }, _toDisplayString(_unref(textTransform)(phase.phaseStatus?.name)), 7)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "to", "class"]),
                  (isPhase.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        (phase.phaseId === _ctx.selectedPhase)
                          ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.phaseSteps, (step) => {
                                return (_openBlock(), _createElementBlock("li", {
                                  key: step.id,
                                  class: _normalizeClass(["text-sm tracking-[0.01rem] text-primary-text-color font-medium py-1 px-2 rounded-primary-radius cursor-pointer hover:bg-white duration-500", `${
                    _unref(route).hash &&
                    (_unref(route).hash.replace('#step-', '') == step.id ||
                      _unref(route).hash.replace('#scroll-step-', '') == step.id)
                      ? 'bg-white'
                      : 'bg-transparent'
                  }`])
                                }, [
                                  _createVNode(_unref(RouterLink), {
                                    to: `/projects/${_unref(projectId)}/phase/${phase.phaseId}/#step-${step.id}`
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(step.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["to"])
                                ], 2))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]))
    ], 512), [
      [_vShow, isLink.value]
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_unref(RouterLink), {
        to: "/",
        class: "hidden sm:flex h-6 items-center gap-1 p-2 cursor-pointer"
      }, {
        default: _withCtx(() => [
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            src: _imports_4,
            alt: "User icon"
          }, null, -1)),
          _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(userStore).getUser.fullName), 1)
        ]),
        _: 1
      }),
      _createElementVNode("button", {
        onClick: logout,
        class: "flex items-center gap-1 p-2 cursor-pointer hover:bg-gray-200 duration-300"
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("img", {
          src: _imports_5,
          alt: "Logout icon"
        }, null, -1),
        _createElementVNode("p", { class: "text-sm font-normal text-primary-text-color" }, "Logout", -1)
      ]))
    ])
  ], 2))
}
}

})